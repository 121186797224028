import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ArrayHelpers } from '@inigo/helpers/collections';
import { APagePkgStoreService } from '@inigo/my-store';
import { EMPTY, catchError, distinct, filter, map, shareReplay, switchMap, tap } from 'rxjs';
import { PROPERTY_NONE } from '@inigo/conecto/wb/core/config';
import { AppRoutes } from '../../../../app-routes';
import { Brand } from '../../../dtos/brand';
import { VariantColor } from '../../../dtos/color';
import { DimensionUsageInfo } from '../../../dtos/dimension-usage-info';
import { Product, getProductDocuments, getProductGallery } from '../../../dtos/product';
import { Style } from '../../../dtos/style';
import { ProductVariant } from '../../../dtos/variant';
import { VariantByProductPagePackageCustomer } from '../../../dtos/vars-by-prod-page-package-customer';
import { CustomerVariantHttpService } from '../../http/vars/vars-customer.service';

@Injectable({
  providedIn: 'root',
})
export class VariantStoreCustomerService extends APagePkgStoreService<ProductVariant, ProductVariant, VariantByProductPagePackageCustomer> {

  private _actRoute = inject(ActivatedRoute)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  prodId$ = this._actRoute.params.pipe(
    map(params => params[AppRoutes.PublicArea.PRODUCT_DETAIL_PARAM]),
    filter(id => !!id),
    distinct()
  )

  pkg$ = this.prodId$.pipe(
    switchMap(id => this.refreshPackage(id).pipe(catchError(() => EMPTY))),
    tap(t => console.log('pkg$', t)),
    shareReplay(1)
  )

  underlyingProduct$ = this.pkg$.pipe(
    map(pkg => pkg.product))

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  pkg = toSignal(this.pkg$)

  underlyingProduct = computed(() => this.pkg()?.product)

  brands = computed(() => this.getBrands(this.pkg()))
  colors = computed(() => this.getColors(this.pkg()))
  styles = computed(() => this.getStyles(this.pkg()))
  packSizes = computed(() => this.getPackSizes(this.pkg()))  
  dimensionUsageInfo = computed(() => this.getDimensionUsageInfo(this.pkg()))

  varyByBrand = computed(() => !!this.pkg()?.product?.varyByBrand ?? false)
  varyByColor = computed(() => !!this.pkg()?.product?.varyByColor ?? false)
  varyByStyle = computed(() => !!this.pkg()?.product?.varyByStyle ?? false)
  
  gallery = computed(() => getProductGallery(this.underlyingProduct()))
  docs = computed(() => getProductDocuments(this.underlyingProduct()))
    
  styleName = computed(() => this.underlyingProduct()?.styleName ?? 'Style')
  
  canDisplayDimensions = computed(() => this.underlyingProduct()?.dimensionDef.measurementDef1?.name !== PROPERTY_NONE)
  canDisplayBrands = computed(() => this.varyByBrand() && !!this.brands().length)
  canDisplayColors = computed(() => this.varyByColor() && !!this.colors().length)
  canDisplayStyles = computed(() => this.varyByStyle() && !!this.styles().length)

  //-------------------------------------------------------//

  constructor(httpService: CustomerVariantHttpService) {
    super(httpService)

    this.setItemTypeName('Variant')
    this.setItemNameLamda((p) => p.name ?? `Variant ${p.id}`)

  } //ctor

  //-------------------------------------------------------//

  private getBrands(pkg?: VariantByProductPagePackageCustomer): Brand[] {

    const brands = pkg?.brands
    if (!brands?.length || !pkg?.product?.varyByBrand)
      return []

    const uniqueBrands: Brand[] = []

    brands.forEach((brand) => {
      ArrayHelpers.pushIfUnique(
        uniqueBrands,
        brand,
        (u1, u2) => (u1.id == u2.id))
    })

    return uniqueBrands

  }//getBrands

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private getColors(pkg?: VariantByProductPagePackageCustomer): VariantColor[] {

    const clrs = pkg?.product?.colors
    if (!clrs?.length || !pkg?.product?.varyByColor)
      return []

    const uniqueColors: VariantColor[] = []

    clrs.forEach((clr) => {
      ArrayHelpers.pushIfUnique(
        uniqueColors,
        clr,
        (c1, c2) => (c1.name == c2.name) && (c1.code == c2.code))
    })
    return uniqueColors

  }//getColors

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private getPackSizes(pkg?: VariantByProductPagePackageCustomer): number[] {

    const packSizes = pkg?.packSizes

    if (!packSizes?.length)
      return []

    const uniquePackSizes: number[] = []

    packSizes.forEach((packSize) => {
      ArrayHelpers.pushIfUnique(
        uniquePackSizes,
        packSize,
        (u1, u2) => (u1 == u2))
    })

    return uniquePackSizes

  }//getPackSizes

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private getStyles(pkg?: VariantByProductPagePackageCustomer) {

    const stls = pkg?.product.styles

    if (!stls?.length || !pkg?.product?.varyByStyle)
      return []

    const uniqueStyles: Style[] = []

    stls.forEach((stl) => {
      ArrayHelpers.pushIfUnique(
        uniqueStyles,
        stl,
        (s1, s2) => (s1.name == s2.name) && (s1.description == s2.description))
    })

    return uniqueStyles

  }//getStyles

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private getDimensionUsageInfo(pkg?: VariantByProductPagePackageCustomer): DimensionUsageInfo | undefined {

    if (this.setCanSearchDimensions(pkg?.product) && pkg?.dimensionUsageInfo)
      return pkg.dimensionUsageInfo
    else
      return undefined

  }//getCanSearchDimensions

  //-------------------------------------------------------//

  private setCanSearchDimensions(product?: Product): boolean {

    if (!product?.dimensionDef?.measurementDef1)
      return false
    else
      return product?.dimensionDef?.measurementDef1.name !== 'None'

  } //includeDmns

  //-------------------------------------------------------//

  // private getGallery(prod?: Product): string[] {


  //   let gallery: string[] = []

  //   if (!prod)
  //     return gallery

  //   if (prod.imgUrl)
  //     gallery.push(prod.imgUrl)

  //   if (prod.img2Url)
  //     gallery.push(prod.img2Url)

  //   if (prod.img3Url)
  //     gallery.push(prod.img3Url)

  //   if (prod.galleryUrls?.length)
  //     gallery = gallery.concat(prod.galleryUrls)


  //   return gallery

  // }//setGallery

  // //-------------------------------------------------------//

  // private getDocuments(prod?: Product): ShopDocument[] {

  //   let docs: ShopDocument[] = []
  //   if (!prod)
  //     return docs

  //   if (prod.documents)
  //     return prod.documents


  //   return prod.documentUrls.map(
  //     url => new ShopDocument(0, url)
  //   )

  // }//setDocuments

  // //-------------------------------------------------------//

} //Cls
