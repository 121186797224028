import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { HttpServicesConfig, HttpServicesConfigService } from '@inigo/http-services/config';
import { of } from 'rxjs';
import { DataService } from './data.service';
// import { HttpServicesConfigService, HttpServicesConfig, } from './http-services-config';

@Injectable({
  providedIn: 'root',
})
export class ErrorService extends DataService {

  private _config: HttpServicesConfig = inject(HttpServicesConfigService)
  private _platformId = inject(PLATFORM_ID)

  //---------------------------------------------------------------------//

  private _errorsAction: string;

  //---------------------------------------------------------------------//

  constructor() {
    super()

    this._url = this._config.errorsUrl
    this._errorsAction = this._config.errorsAction

  } //ctor

  //---------------------------------------------------------------------//

  public websiteError(error: any) {
    
    if (isPlatformServer(this._platformId))
      return of('server - ssr')
    
      console.log('_errorsAction', this._errorsAction);

    return this.postAction(this._errorsAction, error);
  } //websiteError

  //---------------------------------------------------------------------//
} //Cls
