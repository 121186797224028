import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { throwError, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';

//--------------------------------------------------------//

export const getBlobInterceptorFn = (): HttpInterceptorFn =>
  (req, next) => {
    return next(req).pipe(
      catchError((err) => {
        // https://github.com/angular/angular/issues/19888
        // When request of type Blob, the error is also in Blob instead of object of the json data
        // console.log('blob', err, isBlobResponse(err),  err.error instanceof Blob);

        if (isBlobResponse(err))
          return getPromise(err);

        return throwError(() => err);
      })
    )
  }

//--------------------------------------------------------//

function getPromise(err: any) {
  return new Promise<any>((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (e: Event) => {
      try {
        const errmsg = JSON.parse((<any>e.target).result);
        reject(
          new HttpErrorResponse({
            error: errmsg,
            headers: err.headers,
            status: err.status,
            statusText: err.statusText,
            url: err.url,
          })
        );
      } catch (e) {
        reject(err);
      }
    };
    reader.onerror = (e) => {
      reject(err);
    };
    reader.readAsText(err.error);
  });
}

//--------------------------------------------------------//

function isBlobResponse(err: any) {
  return err instanceof HttpErrorResponse &&
    err.error instanceof Blob &&
    err.error.type === 'application/json'
}
//--------------------------------------------------------//