import { Injectable, inject } from '@angular/core';
import { AuthRolesService } from '@inigo/authentication/roles';
import { BaseAuthGuard } from '../../../a-base.guard';

/**
 * Only members of Maintenance team or higher can access.
 *
 * Includes Super Team
 */
@Injectable({
  providedIn: 'root',
})
export class MaintenanceMinimumGuard extends BaseAuthGuard {
  

  private _authService = inject(AuthRolesService)

  isValid = (): boolean => this._authService.isMntcMinimum$()

} //Cls
