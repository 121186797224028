import { ClaimNames } from "@inigo/acc-n-auth-data/claims";

/**
 * Names or Keys of claims in JWT
 */
export class TeamClaimNames extends ClaimNames{
  
  //TEAM
  public static TEAM_POSITION = 'shanie.moonlight.myidentity.claim.team_position';
  public static TEAM_TYPE = 'shanie.moonlight.myidentity.claim.team_type'

  //2FA
  public static TWO_FACTOR_VERIFIED = 'shanie.moonlight.myidentity.claim.two_factor_verified';
  public static TWO_FACTOR_REQUIRED = 'shanie.moonlight.myidentity.claim.two_factor_required';

  //Devices
  public static CURRENT_DEVICE = 'shanie.moonlight.myidentity.claim.devices.CurrentDeviceId';
  public static DEVICES = 'shanie.moonlight.myidentity.claim.devices.DeviceList';
  public static DEVICE_LIMIT = 'shanie.moonlight.myidentity.claim.devices.devicelimit';

  // public static ROLES_MS = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
  public static ROLE = 'shanie.moonlight.myidentity.claim.Role';

} //Cls
