import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SwUpdatesService {

  //--------------------------------//

  constructor(private _swUpdate: SwUpdate, private snackbar: MatSnackBar) { 
    this.listenForUnrecoverableErrors()
  }

  //--------------------------------//

  listenForNewVersions() {

    this._swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => this.toNewVersionObject(evt))
      )
      .subscribe((versionInfo) => this.openNewVersionSnackbar(versionInfo));

    this._swUpdate.versionUpdates.subscribe((x) =>
      console.log('ALL update', x)
    )

  } 

  //--------------------------------//

  listenForUnrecoverableErrors() {

    this._swUpdate.unrecoverable
      .subscribe((evt) =>
        this.openNewVersionSnackbar(`An error occurred that we cannot recover from:\n${evt.reason}\n\nPlease reload the page.`));

  } 

  //--------------------------------//

  private openNewVersionSnackbar(versionInfo: any) {

    console.log('update', versionInfo);
    const snackRef = this.snackbar.open('Update Available', 'Reload', {
      duration: 30000,
      panelClass: 'primary-snackbar',
    })

    snackRef.onAction().subscribe(() => {
      window.location.reload()
    })

  } //openNewVersionSnackbar

  //--------------------------------//

  private toNewVersionObject(evt: VersionReadyEvent) {

    return {
      type: 'UPDATE_AVAILABLE',
      current: evt.currentVersion,
      available: evt.latestVersion,
    };
  } 

  //--------------------------------//

} //Cls
