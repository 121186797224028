import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { REDIRECT_URL_KEY } from '@inigo/data/misc';
import { Observable } from 'rxjs';

//==================================================================================//


// @Injectable()
export abstract class CannotActivateHandler {

  abstract handle(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree

}

//==================================================================================//

@Injectable({ providedIn: 'root' })
export class DefaultCannotActivateHandler extends CannotActivateHandler {



  private _router = inject(Router)

  override handle(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    console.log('DefaultCannotActivateHandlerService');
    const queryParams: any = {}
    queryParams[REDIRECT_URL_KEY] = state.url
    return this._router.createUrlTree([this.getFirstSegment(state.url), 'accounts', 'login'], {queryParams});
  }

  //-------------------------------------------------//

  getFirstSegment(url: string): string {

    if (!url?.length)
      return ''

    return this.getSegments(url)[0]

  }

  //-------------------------------------------------//

  getLastSegment(url: string): string {

    if (!url?.length)
      return ''

    const segments = this.getSegments(url)
    return segments[segments.length - 1]

  }

  //-------------------------------------------------//

  private getSegments = (url: string) =>
    this._router.parseUrl(url).root.children['primary'].segments
      .map(it => it.path)

  //-------------------------------------------------//

}


//==================================================================================//