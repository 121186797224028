import { NativeDateAdapter } from '@angular/material/core';
import { DateHelpers } from './date-helpers';

export class DateAdapter_DD_MMM_YYYY extends NativeDateAdapter {

  override format(date: Date, displayFormat: any): string {

    return DateHelpers.To_dd_MMM_YYYY( date)

  } //format

} //Cls

//------------------------------------------------------------------//

export class DateAdapter_DD_MMM_YYYY_HH_mm extends NativeDateAdapter {

  override format(date: Date, displayFormat: any): string {

    return date.dd_MMM_YYYY_HH_mm()

  } //format

} //Cls

//------------------------------------------------------------------//

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const DATE_FORMAT_DD_MMM_YYYY = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD-MMMM-YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


//------------------------------------------------------------------//

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const DATE_FORMAT_DD_MMM_YYYY_HH_mm = {
  parse: {
    dateInput: 'DD-MMM-YYYY HH:mm',
  },
  display: {
    dateInput: 'DD-MMM-YYYY HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD-MMMM-YYYY HH:mm',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


//------------------------------------------------------------------//
