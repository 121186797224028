import { Provider } from "@angular/core";
import { HttpServicesConfig, HttpServicesConfigService } from "./http-services-config";

export class HttpServicesSetup {

    static getProviders(config: HttpServicesConfig = HttpServicesConfig.Create()): Provider[] {
        return [
            {
                provide: HttpServicesConfigService,
                useValue: config,
            },
        ]
    } 


} //Cls