import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgToIconService } from '@inigo/my-mat/svg-icon';
import { SwHelpersModule } from '@inigo/sw-helpers';
import { AppStoreService } from './shared/app-store/store/app/app-store.service';
import { LazyLoadingIndicatorComponent } from '@inigo/ui/functional/lazy-loading';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    LazyLoadingIndicatorComponent,
    SwHelpersModule
  ],
  selector: 'con-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  //import appstore to trigger SW stuff
  _appStore = inject(AppStoreService)
  _svgToIcon = inject(SvgToIconService)

  //- - - - - - - - - - - - - - - - - - - - - - - -//

  title = 'Conecto';
  _colorSplashBg = '#302a2a';

  //-----------------------------------------------//

  constructor() {

    this._svgToIcon.registerAllIcons()
    console.log('v3',0 )

  } //Ctor

  //-----------------------------------------------//

} //Cls
