import { provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from "@angular/common/http";
import { EnvironmentProviders, ErrorHandler, Provider } from "@angular/core";
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, UrlSerializer } from "@angular/router";
import { getServiceUnavailableInterceptorFn } from "@inigo/errors/interceptors";
import { HttpServicesSetup } from "@inigo/http-services/config";
import { jwtInterceptorFn } from "@inigo/jwt";
import { DelayPreloadingStrategy } from '@inigo/lazy-load-helpers/preload';
import { CoreConfig, CoreConfigService } from "./core-config";
import { AppErrorHandler } from "./errors/app-error-handler";

import { DataRouteReuseStrategy } from "./routing/data-routereuse-strategy";
import { LowerCaseUrlSerializer } from "./routing/lower-case-url-serializer";
import { getBlobInterceptorFn, getIconRetryInterceptorFn, notFoundFnInterceptor } from "@inigo/core/http-interceptors";

export class CoreSetup {

  static getProviders(config: CoreConfig): (Provider | EnvironmentProviders)[] {

    return [
      {
        provide: ErrorHandler,
        useClass: AppErrorHandler,
      },
      {
        provide: CoreConfigService,
        useValue: config,
      },
      {
        provide: UrlSerializer,
        useClass: LowerCaseUrlSerializer,
      },
      {
        provide: RouteReuseStrategy,
        useClass: DataRouteReuseStrategy,
      },
      provideHttpClient(
        withFetch(),
        withInterceptorsFromDi(),
        withInterceptors([
          jwtInterceptorFn,
          getBlobInterceptorFn(),
          notFoundFnInterceptor,
          getServiceUnavailableInterceptorFn(),
          getIconRetryInterceptorFn(),
          ...config.httpInterceptorFns]),
        withInterceptorsFromDi(),
      ),
      provideAnimations(),
      DelayPreloadingStrategy,
      HttpServicesSetup.getProviders(config.httpServicesConfig),
    ]


  }


}//Cls







// import { HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
// import { CoreHammerConfig } from "./utility/hammer-config";

// {
//   provide: HTTP_INTERCEPTORS,
//   useClass: JwtInterceptor,
//   multi: true,
// },
// {
//   provide: HTTP_INTERCEPTORS,
//   useClass: ServiceUnavailableRetryInterceptor,
//   multi: true,
// },
// {
//   provide: HTTP_INTERCEPTORS,
//   useClass: IconRetryInterceptor,
//   multi: true,
// },
// {
//   provide: HTTP_INTERCEPTORS,
//   useClass: NotFoundInterceptor,
//   multi: true,
// },
// {
//   provide: HTTP_INTERCEPTORS,
//   useClass: BlobErrorHttpInterceptor,
//   multi: true,
// },