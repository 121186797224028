import { AccRoutes } from "@inigo/acc-n-auth-data/routes"


export class MntcAreaRoutes {


    public static readonly BASE = 'admin'

    public static readonly HOME ='home'
    public static readonly BRANDS = 'brands'
    public static readonly UPLOAD = 'upload'
    public static readonly DOWNLOADS = 'downloads'
    public static readonly TEST = 'testse'
    public static readonly INDUSTRIES = 'industries'
    public static readonly CUSTOMERS = 'customers'
    public static readonly PRODUCTS = 'products'
    public static readonly NOT_FOUND = 'notfound'
    public static readonly PAYMENTS = 'payments'
    public static readonly DETAIL = 'detail'
    public static readonly PRODUCT_DETAIL_PARAM = 'prodId'
    public static readonly PRODUCTS_DETAIL = `${this.DETAIL}/:${this.PRODUCT_DETAIL_PARAM}`
    public static readonly PRODUCT_VARIANTS = 'prod-vars'
    public static readonly PRODUCT_GALLERY = 'gallery'
    public static readonly COLORS = 'prod-colors'
    public static readonly ORDERS = 'orders'
    public static readonly DIMENS = 'prod-dimens'
    public static readonly STYLES = 'prod-styles'
    public static readonly UNITS = 'prod-units'
    public static readonly MEASUREMENTS = 'prod-msrs'

    

    static Accounts = class {

        static readonly BASE = `${AccRoutes.ACCOUNT_FEATURES}`
        static readonly APPS = `${this.BASE}/${AccRoutes.APPS}`
        static readonly ACCESS_POINTS = `${this.BASE}/${AccRoutes.ACCESS_POINTS}`
        static readonly AUTH_TEST = `${this.BASE}/${AccRoutes.AUTH_TEST}`
        static readonly CHANGE_PWD = `${this.BASE}/${AccRoutes.CHANGE_PWD}`
        static readonly CONFIRM_EMAIL_CUSTOMER = `${this.BASE}/${AccRoutes.CONFIRM_EMAIL}`
        static readonly CONFIRM_EMAIL_MNTC = `${this.BASE}/${AccRoutes.CONFIRM_EMAIL_MNTC}`
        static readonly CUSTOMERS_ID_PARAM = `${this.BASE}/${AccRoutes.CUSTOMERS_ID_PARAM}`
        static readonly CUSTOMERS = `${this.BASE}/${AccRoutes.CUSTOMERS}`
        static readonly DEVICES = `${this.BASE}/${AccRoutes.DEVICES}`
        static readonly EMAIL_CONFIRMED_CUSTOMER = `${this.BASE}/${AccRoutes.EMAIL_CONFIRMED}`
        static readonly EMAIL_CONFIRMED_MNTC = `${this.BASE}/${AccRoutes.EMAIL_CONFIRMED_MNTC}`
        static readonly EMAIL_NOT_CONFIRMED_CUSTOMER = `${this.BASE}/${AccRoutes.EMAIL_NOT_CONFIRMED_CUSTOMER}`
        static readonly EMAIL_NOT_CONFIRMED_MNTC = `${this.BASE}/${AccRoutes.EMAIL_NOT_CONFIRMED_MNTC}`
        static readonly HOME = `${this.BASE}/${AccRoutes.HOME}`
        static readonly LOGIN = `${this.BASE}/${AccRoutes.LOGIN}`
        static readonly LIST = `${this.BASE}/${AccRoutes.LIST}`
        static readonly MY_INFO = `${this.BASE}/${AccRoutes.MY_INFO}`
        static readonly RESET_PWD = `${this.BASE}/${AccRoutes.APPS}`
        static readonly TWO_FACTOR_VERIFICATION = `${this.BASE}/${AccRoutes.TWO_FACTOR_VERIFICATION}`
        static readonly USERS = `${this.BASE}/${AccRoutes.USERS}`
        static readonly MNTC_USERS = `${this.BASE}/${AccRoutes.MNTC_USERS}`
        static readonly SUPER_USERS = `${this.BASE}/${AccRoutes.SUPER_USERS}`

        public static readonly AllowAnonymousRoutes = [
            this.LOGIN,
            this.CONFIRM_EMAIL_MNTC,
            this.EMAIL_CONFIRMED_CUSTOMER,
            this.EMAIL_CONFIRMED_MNTC,
            this.EMAIL_NOT_CONFIRMED_CUSTOMER,
            this.EMAIL_NOT_CONFIRMED_MNTC,
            this.TWO_FACTOR_VERIFICATION,
        ]

    }//Cls


}//Cls

//---------------------------------------------------------//

export class MntcAreaRoutesFullPath {

    public static readonly HOME = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.HOME}`
    public static readonly BRANDS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.BRANDS}`
    public static readonly UPLOAD = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.UPLOAD}`
    public static readonly DOWNLOADS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.DOWNLOADS}`
    public static readonly TEST = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.TEST}`
    public static readonly INDUSTRIES = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.INDUSTRIES}`
    public static readonly CUSTOMERS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.CUSTOMERS}`
    public static readonly PRODUCTS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.PRODUCTS}`
    public static readonly NOT_FOUND = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.NOT_FOUND}`
    public static readonly PAYMENTS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.PAYMENTS}`
    public static readonly DETAIL = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.DETAIL}`
    public static readonly PRODUCT_DETAIL_PARAM = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.PRODUCT_DETAIL_PARAM}`
    public static readonly PRODUCTS_DETAIL = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.PRODUCTS_DETAIL}`
    public static readonly PRODUCT_VARIANTS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.PRODUCT_VARIANTS}`
    public static readonly PRODUCT_GALLERY = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.PRODUCT_GALLERY}`
    public static readonly ORDERS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.ORDERS}`
    public static readonly COLORS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.COLORS}`
    public static readonly DIMENS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.DIMENS}`
    public static readonly STYLES = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.STYLES}`
    public static readonly UNITS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.UNITS}`
    public static readonly MEASUREMENTS = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.MEASUREMENTS}`


    static Accounts = class {

        public static readonly BASE = `${MntcAreaRoutes.BASE}/${MntcAreaRoutes.Accounts.BASE}`
        static readonly APPS = `${this.BASE}/${MntcAreaRoutes.Accounts.APPS}`
        static readonly ACCESS_POINTS = `${this.BASE}/${MntcAreaRoutes.Accounts.ACCESS_POINTS}`
        static readonly AUTH_TEST = `${this.BASE}/${MntcAreaRoutes.Accounts.AUTH_TEST}`
        static readonly CHANGE_PWD = `${this.BASE}/${MntcAreaRoutes.Accounts.CHANGE_PWD}`
        static readonly CONFIRM_EMAIL_CUSTOMER = `${this.BASE}/${MntcAreaRoutes.Accounts.CONFIRM_EMAIL_CUSTOMER}`
        static readonly CONFIRM_EMAIL_MNTC = `${this.BASE}/${MntcAreaRoutes.Accounts.CONFIRM_EMAIL_MNTC}`
        static readonly CUSTOMERS_ID_PARAM = `${this.BASE}/${MntcAreaRoutes.Accounts.CUSTOMERS_ID_PARAM}`
        static readonly CUSTOMERS = `${this.BASE}/${MntcAreaRoutes.Accounts.CUSTOMERS}`
        static readonly DEVICES = `${this.BASE}/${MntcAreaRoutes.Accounts.DEVICES}`
        static readonly EMAIL_CONFIRMED_CUSTOMER = `${this.BASE}/${MntcAreaRoutes.Accounts.EMAIL_CONFIRMED_CUSTOMER}`
        static readonly EMAIL_CONFIRMED_MNTC = `${this.BASE}/${MntcAreaRoutes.Accounts.EMAIL_CONFIRMED_MNTC}`
        static readonly EMAIL_NOT_CONFIRMED_CUSTOMER = `${this.BASE}/${MntcAreaRoutes.Accounts.EMAIL_NOT_CONFIRMED_CUSTOMER}`
        static readonly EMAIL_NOT_CONFIRMED_MNTC = `${this.BASE}/${MntcAreaRoutes.Accounts.EMAIL_NOT_CONFIRMED_MNTC}`
        static readonly HOME = `${this.BASE}/${MntcAreaRoutes.Accounts.HOME}`
        static readonly LOGIN = `${this.BASE}/${MntcAreaRoutes.Accounts.LOGIN}`
        static readonly LIST = `${this.BASE}/${MntcAreaRoutes.Accounts.LIST}`
        static readonly MY_INFO = `${this.BASE}/${MntcAreaRoutes.Accounts.MY_INFO}`
        static readonly RESET_PWD = `${this.BASE}/${MntcAreaRoutes.Accounts.APPS}`
        static readonly TWO_FACTOR_VERIFICATION = `${this.BASE}/${MntcAreaRoutes.Accounts.TWO_FACTOR_VERIFICATION}`
        static readonly USERS = `${this.BASE}/${MntcAreaRoutes.Accounts.USERS}`
        static readonly MNTC_USERS = `${this.BASE}/${MntcAreaRoutes.Accounts.MNTC_USERS}`
        static readonly SUPER_USERS = `${this.BASE}/${MntcAreaRoutes.Accounts.SUPER_USERS}`

        public static readonly AllowAnonymousRoutes = [
            this.LOGIN,
            this.CONFIRM_EMAIL_MNTC,
            this.EMAIL_CONFIRMED_CUSTOMER,
            this.EMAIL_CONFIRMED_MNTC,
            this.EMAIL_NOT_CONFIRMED_CUSTOMER,
            this.EMAIL_NOT_CONFIRMED_MNTC,
            this.TWO_FACTOR_VERIFICATION,
        ]

    }//Cls

}//Cls

//===============================================================//