import { EnvironmentProviders, Provider } from "@angular/core"
import { PopUpConfig, PopUpConfigService } from "./pop-up-config"

export class PopUpSetup {

    static getProviders(config: PopUpConfig = PopUpConfig.Create()): (Provider | EnvironmentProviders)[] {
        
        return [
            {
                provide: PopUpConfigService,
                useValue: config,
            }
        ]

    } //getProviders

} //Cls