import { Injectable, Inject } from '@angular/core';
import { RolesAuthConfig, RolesAuthConfigService } from '@inigo/authentication/config';
import { RoleNames } from '@inigo/acc-n-auth-data/roles';
import { StringHelpers } from '@inigo/helpers/text';

/**
 * A Service for getting the rolenames/values.
 *
 * We use a service instead of just constant fields because the prefix may change depending on the server.
 *
 * This will be set in configuration
 */
@Injectable({
  providedIn: 'root',
})
export class RoleNamesService {
  //---------------------------------------------------------------------------------------------//

  constructor(@Inject(RolesAuthConfigService) config: RolesAuthConfig) {
    this.superLeaderFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.SUPER_LEADER_DISPLAY);
    this.superAdminFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.SUPER_ADMIN_DISPLAY);
    this.superMgrFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.SUPER_MGR_DISPLAY);
    this.superUserFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.SUPER_USER_DISPLAY);

    this.maintenanceLeaderFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.MAINTENANCE_LEADER_DISPLAY);
    this.maintenanceAdminFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.MAINTENANCE_ADMIN_DISPLAY);
    this.maintenanceMgrFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.MAINTENANCE_MGR_DISPLAY);
    this.maintenanceUserFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.MAINTENANCE_USER_DISPLAY);

    this.customerLeaderFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.CUSTOMER_LEADER_DISPLAY);
    this.customerAdminFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.CUSTOMER_ADMIN_DISPLAY);
    this.customerMgrFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.CUSTOMER_MGR_DISPLAY);
    this.customerUserFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.CUSTOMER_USER_DISPLAY);
    this.customerGuestFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.CUSTOMER_GUEST_DISPLAY);

    this.leaderFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.LEADER_DISPLAY);
    this.adminFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.ADMIN_DISPLAY);
    this.mgrFull =
      config.roleFullNamePrefix + this.sanitizeFullName(RoleNames.MGR_DISPLAY);
    this.userFull =
      config.roleFullNamePrefix + this.sanitizeFullName(RoleNames.USER_DISPLAY);
    this.guestFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.GUEST_DISPLAY);

    this.superFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.SUPER_DISPLAY);
    this.maintenanceFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.MAINTENANCE_DISPLAY);
    this.customerFull =
      config.roleFullNamePrefix +
      this.sanitizeFullName(RoleNames.CUSTOMER_DISPLAY);
  } //ctor

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= SUPER TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
  //---------------------------------------------------------------------------------------------//

  /**  The God Role. The first user, app creator.
   * Part of the Super Team */
  superLeaderDisplay: string = RoleNames.SUPER_LEADER_DISPLAY;
  superLeaderFull: string; /** User with access to everything    */

  /** SuperLeader's assistant. above everyone else
   * Part of the Super Team  */
  superAdminDisplay: string = RoleNames.SUPER_ADMIN_DISPLAY;
  superAdminFull: string;

  /** Just below SUPER_ADMIN. Above everyone else.
   * Part of the Super Team  */
  superMgrDisplay: string = RoleNames.SUPER_MGR_DISPLAY;
  superMgrFull: string;

  /** Just below SUPER_MGR. Above everyone else.
   * Part of the Super Team  */
  superUserDisplay: string = RoleNames.SUPER_USER_DISPLAY;
  superUserFull: string;

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= MAINTENANCE TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
  //---------------------------------------------------------------------------------------------//

  /**  Highest level access on Maintenance team. Person in charge. */
  maintenanceLeaderDisplay: string = RoleNames.MAINTENANCE_LEADER_DISPLAY;
  maintenanceLeaderFull: string;

  /** Just below Leader Role. Above everyone else.
   * Part of the Maintenance Team  */
  maintenanceAdminDisplay: string = RoleNames.MAINTENANCE_ADMIN_DISPLAY;
  maintenanceAdminFull: string;

  /** Second highest level access on Maintenance Team.  */
  maintenanceMgrDisplay: string = RoleNames.MAINTENANCE_MGR_DISPLAY;
  maintenanceMgrFull: string;

  /** Basic user in Maintenance Team. Limited access.  */
  maintenanceUserDisplay: string = RoleNames.MAINTENANCE_USER_DISPLAY;
  maintenanceUserFull: string;

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= CUSTOMER TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  //---------------------------------------------------------------------------------------------//

  /**  Highest level access on Customer team. Person in charge. */
  customerLeaderDisplay: string = RoleNames.CUSTOMER_LEADER_DISPLAY;
  customerLeaderFull: string;

  /** Just below Leader Role. Above everyone else.
   * Part of the Customer Team  */
  customerAdminDisplay: string = RoleNames.CUSTOMER_ADMIN_DISPLAY;
  customerAdminFull: string;

  /** Second highest level access on Customer Team.  */
  customerMgrDisplay: string = RoleNames.CUSTOMER_MGR_DISPLAY;
  customerMgrFull: string;

  /** Basic user in Customer Team. Limited access.  */
  customerUserDisplay: string = RoleNames.CUSTOMER_USER_DISPLAY;
  customerUserFull: string;

  /** Basic user in Customer Team. Limited access.  */
  customerGuestDisplay: string = RoleNames.CUSTOMER_USER_DISPLAY;
  customerGuestFull: string;

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- POSITION IN TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  //---------------------------------------------------------------------------------------------//

  /**  Team leader, Highest access level. Creator of team. */
  leaderDisplay: string = RoleNames.LEADER_DISPLAY;
  leaderFull: string;

  /**  Administrator of users of service. Creates new users/managers, changes roles etc.    */
  adminDisplay: string = RoleNames.ADMIN_DISPLAY;
  adminFull: string;

  /** Manager of basic users. Creates new users, changes roles etc. */
  mgrDisplay: string = RoleNames.MGR_DISPLAY;
  mgrFull: string;

  /** Regular user    */
  userDisplay: string = RoleNames.USER_DISPLAY;
  userFull: string;

  /** Temporary limited access. */
  guestDisplay: string = RoleNames.GUEST_DISPLAY;
  guestFull: string;

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- TEAMS =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
  //---------------------------------------------------------------------------------------------//

  /**  Users with Highest level of access on App. App creator team. */
  superDisplay: string = RoleNames.SUPER_DISPLAY;
  superFull: string;

  /**  Administrator/Maintainer of app. Above Customers if there are any*/
  maintenanceDisplay: string = RoleNames.MAINTENANCE_DISPLAY;
  maintenanceFull: string;

  /** Temporary limited access. */
  customerDisplay: string = RoleNames.CUSTOMER_DISPLAY;
  customerFull: string;

  //---------------------------------------------------------------------------------------------//

  /** Used to locate Roles in JWT */
  // key: string = RoleNames.KEY;

  //---------------------------------------------------------------------------------------------//

  private sanitizeFullName = (fullName: string): string =>
    StringHelpers.RemoveWhitespace(fullName).toLocaleLowerCase();

  //---------------------------------------------------------------------------------------------//
} //Cls
