import { FilterRequest } from './filter-request';
import { SortRequest } from './sort-request';


export type pgDirection = 'next' | 'prev'| 'first' | 'last'
// export type pgPosition = 'first' | 'last'

/**
 * Class for encapsulating a request for a page of data */
export class PagedRequest {
  /**
   * Generates new PagedRequest with:
   * pageNumber = 1,
   * pageSize = 25,
   * filterList & sortList  = Empty Arrays
   */
  constructor(pgSize = 25) {
    this.pageNumber = 1
    this.pageSize = pgSize
    this.filterList = []
    this.sortList = []
  } 

  //--------------------------------------------------//

  /**What page to skip to on this request */
  pageNumber: number

  /**How many items per request */
  pageSize: number

  /**List containing the details on how to filter each field/property */
  filterList: Array<FilterRequest>

  /**List containing the details on how to sort each field/property */
  sortList: Array<SortRequest>

  direction?: pgDirection

  //--------------------------------------------------//

  /**
   * Updates the pagedRequest with all the new data.
   * Only one sort column in this request
   * Then returns it.
   * @param pgNumber What page number are we looking for
   * @param pgSize How many items per page
   * @param sortField What filed are we going to sort by
   * @param sortDescending set to true if sorting from high to low
   */
  updatedPagedRequestSingleSort(
    pgNumber: number,
    pgSize: number,
    sortField?: string | null,
    sortDescending: boolean = false
  ): PagedRequest {
    this.pageNumber = pgNumber;
    this.pageSize = pgSize;

    return this.updateSingleSort(sortField, sortDescending)

  }

  //--------------------------------------------------//

  /**
   * Updates the pagedRequest with all the new sort data.
   * Only one sort column in this request
   * Then returns it.
   * @param sortField What filed are we going to sort by
   * @param sortDescending set to true if sorting from high to low
   */
  updateSingleSort(sortField?: string | null, sortDescending: boolean = false): PagedRequest {

    this.sortList = []
    if (sortField)
      this.sortList.push(new SortRequest(sortField, sortDescending))

    return this

  } 

  //--------------------------------------------------//

  /**
   * Create new PagedRequest
   * @param pgNumber What page number are we looking for
   * @param pgSize How many items per page
   * @param direction Should the next page be 'prev' or 'next'
   */
  public static FromPageData(
    pgNumber: number,
    pgSize: number,
    direction?: pgDirection
  ): PagedRequest {

    let pagedRequest = new PagedRequest(pgSize)
    pagedRequest.pageNumber = pgNumber
    pagedRequest.sortList = []
    pagedRequest.direction = direction

    return pagedRequest

  } 

  //--------------------------------------------------//

  /**
   * Create new PagedRequest
   * @param filterList List containing the details on how to filter each field/property
   * @param pgNumber What page number are we looking for
   * @param pgSize How many items per page
   * @param sortField What filed are we going to sort by
   * @param sortDescending set to true if sorting from high to low
   */
  public static Create(
    filterList: Array<FilterRequest> | null,
    pgNumber: number,
    pgSize: number,
    sortField: string | undefined = undefined,
    sortDescending: boolean = false,

  ): PagedRequest {

    let pagedRequest = new PagedRequest(pgSize)
    pagedRequest.filterList = filterList ?? []
    pagedRequest.pageNumber = pgNumber
    pagedRequest.sortList = []

    if (sortField) {
      pagedRequest.sortList.push(new SortRequest(sortField, sortDescending)); //push
    } //if

    return pagedRequest

  } 

  //--------------------------------------------------//

  /**
   * Create new Empty PagedRequest
   */
  public static Empty = (pgNumber: number = 1, pgSize: number = 15): PagedRequest =>
    PagedRequest.Create([], pgNumber, pgSize)


  //--------------------------------------------------//

  setDirection(direction?: pgDirection) {
    this.direction = direction
    return this
  }

  //--------------------------------------------------//

  setFilterList(filterList: FilterRequest[]) {
    this.filterList = filterList
    return this
  }

  //--------------------------------------------------//

  setSortList(sortList: SortRequest[]) {
    this.sortList = sortList
    return this
  }

  //--------------------------------------------------//

  setPageSize(pgSize: number) {
    this.pageSize = pgSize
    return this
  }

  //--------------------------------------------------//

  /**
   * Create deep copy
   */
  public clone(): PagedRequest {

    const pr = PagedRequest.Create(this.filterList, this.pageNumber, this.pageSize)
    pr.sortList = this.sortList
    pr.direction = this.direction
  

    return pr
  }

  //--------------------------------------------------//

} //Cls
