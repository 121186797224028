/// <summary>
/// Names for Role Claims
/// </summary>
export class RoleNames {

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- SUPER TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  //---------------------------------------------------------------------------------------------//

  /**  The God Role. The first user, app creator.
   * Part of the Super Team */
  public static SUPER_LEADER_DISPLAY = 'Super Leader';

  /** Just below God Role. Above everyone else.
   * Part of the Super Team  */
  public static SUPER_ADMIN_DISPLAY = 'Super Admin';

  /** Just below SUPER_ADMIN. Above everyone else.
   * Part of the Super Team  */
  public static SUPER_MGR_DISPLAY = 'Super Mgr';

  /** Just below SUPER_MGR. Above everyone else.
   * Part of the Super Team  */
  public static SUPER_USER_DISPLAY = 'Super User';

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= MAINTENANCE TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
  //---------------------------------------------------------------------------------------------//

  /**  Highest level access on Maintenance team. Person in charge. */
  public static MAINTENANCE_LEADER_DISPLAY = 'Maintenance Leader';

  /** Just below Leader Role. Above everyone else.
   * Part of the Maintenance Team  */
  public static MAINTENANCE_ADMIN_DISPLAY = 'Maintenance Admin';

  /** Second highest level access on Maintenance team.  */
  public static MAINTENANCE_MGR_DISPLAY = 'Maintenance Mgr';

  /** Basic user in maintenance team. Limited access.  */
  public static MAINTENANCE_USER_DISPLAY = 'Maintenance User';

  /** Guest user in maintenance team. Limited access.  */
  public static MAINTENANCE_GUEST_DISPLAY = 'Maintenance Guest';
  
  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= CUSTOMER TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  //---------------------------------------------------------------------------------------------//

  /**  Highest level access on Customer team. Person in charge. */
  public static CUSTOMER_LEADER_DISPLAY = 'Customer Leader';

  /** Just below Leader Role. Above everyone else.
   * Part of the Customer Team  */
  public static CUSTOMER_ADMIN_DISPLAY = 'Customer Admin';

  /** Second highest level access on Customer team.  */
  public static CUSTOMER_MGR_DISPLAY = 'Customer Mgr';

  /** Basic user in maintenance team. Limited access.  */
  public static CUSTOMER_USER_DISPLAY = 'Customer User';

  /** Temporary user in maintenance team. Very limited access.  */
  public static CUSTOMER_GUEST_DISPLAY = 'Customer Guest';

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- POSITION IN TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  //---------------------------------------------------------------------------------------------//

  /**  Team leader, Highest access level. Creator of team. */
  public static LEADER_DISPLAY = 'Team Leader';

  /**  Administrator of users of service. Creates new users/managers, changes roles etc.    */
  public static ADMIN_DISPLAY = 'Admin';

  /** Manager of basic users. Creates new users, changes roles etc. */
  public static MGR_DISPLAY = 'Manager';

  /** Regular user    */
  public static USER_DISPLAY = 'User';

  /** Temporary limited access. */
  public static GUEST_DISPLAY = 'Guest';

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= TEAMS =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  //---------------------------------------------------------------------------------------------//

  /**  Users with Highest level of access on App. App creator team. */
  public static SUPER_DISPLAY = 'Super';

  /**  Administrator/Maintainer of app. Above Customers if there are any*/
  public static MAINTENANCE_DISPLAY = 'Maintenance';

  /** Customer of app */
  public static CUSTOMER_DISPLAY = 'Customer';

  //-----------------------------------------//

  /** Used to locate Roles in JWT (ClaimType) */
  public static KEY = 'shanie.moonlight.myidentity.claim.Role';

  //-----------------------------------------//

  /** Prefix for role Claims originating form Inigo-MyIdentity */
  public static INIGO_PREFIX = 'shanie.moonlight.myidentity.claims.roles.';

  //-----------------------------------------//
  
} //Cls
