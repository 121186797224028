import { Injectable } from "@angular/core"


//https://github.com/danro/jquery-easing/blob/master/jquery.easing.js


@Injectable({
    providedIn: 'root'
})
export class EasingFunctions {

    private PI = 3.14159265359

    //----------------------------------//

    getFunction(name: EasingFunctionNames = EasingFunctionNames.linearTween) {

        switch (name) {

            case EasingFunctionNames.linearTween:
                return this.linearTween
            case EasingFunctionNames.easeInQuad:
                return this.easeInQuad
            case EasingFunctionNames.easeOutQuad:
                return this.easeOutQuad
            case EasingFunctionNames.easeInOutQuad:
                return this.easeInOutQuad
            case EasingFunctionNames.easeInCubic:
                return this.easeInCubic
            case EasingFunctionNames.easeOutCubic:
                return this.easeOutCubic
            case EasingFunctionNames.easeInOutCubic:
                return this.easeInOutCubic
            case EasingFunctionNames.easeInQuart:
                return this.easeInQuart
            case EasingFunctionNames.easeOutQuart:
                return this.easeOutQuart
            case EasingFunctionNames.easeInOutQuart:
                return this.easeInOutQuart
            case EasingFunctionNames.easeInQuint:
                return this.easeInQuint
            case EasingFunctionNames.easeOutQuint:
                return this.easeOutQuint
            case EasingFunctionNames.easeInOutQuint:
                return this.easeInOutQuint
            case EasingFunctionNames.easeInSine:
                return this.easeInSine
            case EasingFunctionNames.easeInOutSine:
                return this.easeInOutSine
            case EasingFunctionNames.easeInExpo:
                return this.easeInExpo
            case EasingFunctionNames.easeOutExpo:
                return this.easeOutExpo
            case EasingFunctionNames.easeInOutExpo:
                return this.easeInOutExpo
            case EasingFunctionNames.easeInCirc:
                return this.easeInCirc
            case EasingFunctionNames.easeOutCirc:
                return this.easeOutCirc
            case EasingFunctionNames.easeInOutCirc:
                return this.easeInOutCirc
            default:
                return this.linearTween
        }

    }//getFunction

    //----------------------------------//

    /**
     * simple linear tweening - no easing, no acceleration
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private linearTween = (t: number, b: number, c: number, d: number) =>
        c * t / d + b

    //----------------------------------//

    /**   quadratic easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInQuad(t: number, b: number, c: number, d: number) {
        t /= d;
        return c * t * t + b;
    }

    //----------------------------------//

    /**   
     * quadratic easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutQuad(t: number, b: number, c: number, d: number) {
        t /= d;
        return -c * t * (t - 2) + b;
    }

    //----------------------------------//

    /**  
     *  quadratic easing in/out - acceleration until halfway, then deceleration
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutQuad(t: number, b: number, c: number, d: number) {
        t /= d / 2
        if (t < 1) return c / 2 * t * t + b
        t--
        return -c / 2 * (t * (t - 2) - 1) + b
    }

    //----------------------------------//

    /**   cubic easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */

    private easeInCubic(t: number, b: number, c: number, d: number) {
        t /= d;
        return c * t * t * t + b;
    }

    //----------------------------------//

    /**   cubic easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutCubic(t: number, b: number, c: number, d: number) {
        t /= d;
        t--;
        return c * (t * t * t + 1) + b;
    }

    //----------------------------------//

    /**   cubic easing in/out - acceleration until halfway, then deceleration
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutCubic(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t * t + b;
        t -= 2;
        return c / 2 * (t * t * t + 2) + b;
    }

    //----------------------------------//

    /**   quartic easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInQuart(t: number, b: number, c: number, d: number) {
        t /= d;
        return c * t * t * t * t + b;
    }

    //----------------------------------//

    /**   quartic easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutQuart(t: number, b: number, c: number, d: number) {
        t /= d;
        t--;
        return -c * (t * t * t * t - 1) + b;
    }

    //----------------------------------//

    /**   quartic easing in/out - acceleration until halfway, then deceleration
    * @param t timeElapsed
    * @param b startPosition
    * @param c distance
    * @param d duration
    */
    private easeInOutQuart(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t * t * t + b;
        t -= 2;
        return -c / 2 * (t * t * t * t - 2) + b;
    }

    //----------------------------------//

    /**   quintic easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInQuint(t: number, b: number, c: number, d: number) {
        t /= d;
        return c * t * t * t * t * t + b;
    }

    //----------------------------------//

    /**   quintic easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutQuint(t: number, b: number, c: number, d: number) {
        t /= d;
        t--;
        return c * (t * t * t * t * t + 1) + b;
    }

    //----------------------------------//

    /**   quintic easing in/out - acceleration until halfway, then deceleration
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutQuint(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t * t * t * t + b;
        t -= 2;
        return c / 2 * (t * t * t * t * t + 2) + b;
    }

    //----------------------------------//

    /**   sinusoidal easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */

    private easeInSine = (t: number, b: any, c: number, d: number) =>
        -c * Math.cos(t / d * (this.PI / 2)) + c + b;

    //----------------------------------//

    /**   sinusoidal easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutSine = (t: number, b: number, c: number, d: number) =>
        c * Math.sin(t / d * (this.PI / 2)) + b;

    //----------------------------------//

    /**   sinusoidal easing in/out - accelerating until halfway, then decelerating
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutSine = (t: number, b: number, c: number, d: number) =>
        -c / 2 * (Math.cos(this.PI * t / d) - 1) + b;

    //----------------------------------//

    /**   exponential easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInExpo = (t: number, b: number, c: number, d: number) =>
        c * Math.pow(2, 10 * (t / d - 1)) + b;


    //----------------------------------//

    /**   exponential easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutExpo = (t: number, b: number, c: number, d: number) =>
        c * (-Math.pow(2, -10 * t / d) + 1) + b;

    //----------------------------------//

    /**   exponential easing in/out - accelerating until halfway, then decelerating
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutExpo(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
        t--;
        return c / 2 * (-Math.pow(2, -10 * t) + 2) + b;
    }

    //----------------------------------//

    /**   circular easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInCirc(t: number, b: number, c: number, d: number) {
        t /= d;
        return -c * (Math.sqrt(1 - t * t) - 1) + b;
    }

    //----------------------------------//

    /**   circular easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutCirc(t: number, b: number, c: number, d: number) {
        t /= d;
        t--;
        return c * Math.sqrt(1 - t * t) + b;
    }

    //----------------------------------//

    /**   circular easing in/out - acceleration until halfway, then deceleration
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutCirc(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
        t -= 2;
        return c / 2 * (Math.sqrt(1 - t * t) + 1) + b;
    }

}//Cls


//####################################################################################################//
//####################################################################################################//


export enum EasingFunctionNames {
    /** simple linear tweening - no easing, no acceleration */
    linearTween,
    /**  quadratic easing in - accelerating from zero velocity */
    easeInQuad,
    /** quadratic easing out - decelerating to zero velocity */
    easeOutQuad,
    /** quadratic easing in/out - acceleration until halfway, then deceleration */
    easeInOutQuad,
    /** cubic easing in - accelerating from zero velocity */
    easeInCubic,
    /** cubic easing out - decelerating to zero velocity */
    easeOutCubic,
    /** cubic easing in/out - acceleration until halfway, then deceleration */
    easeInOutCubic,
    /**  quartic easing in - accelerating from zero velocity */
    easeInQuart,
    /** quartic easing out - decelerating to zero velocity */
    easeOutQuart,
    /**  quartic easing in/out - acceleration until halfway, then deceleration */
    easeInOutQuart,
    /** quintic easing in - accelerating from zero velocity */
    easeInQuint,
    /** quintic easing out - decelerating to zero velocity */
    easeOutQuint,
    /** quintic easing in/out - acceleration until halfway, then deceleration */
    easeInOutQuint,
    /**  sinusoidal easing in - accelerating from zero velocity */
    easeInSine,
    /**  sinusoidal easing out - decelerating to zero velocity */
    easeOutSine,
    /** sinusoidal easing in/out - accelerating until halfway, then decelerating */
    easeInOutSine,
    /** exponential easing in - accelerating from zero velocity */
    easeInExpo,
    /**  exponential easing out - decelerating to zero velocity*/
    easeOutExpo,
    /**  exponential easing in/out - accelerating until halfway, then decelerating  */
    easeInOutExpo,
    /**  circular easing in - accelerating from zero velocity */
    easeInCirc,
    /**  circular easing out - decelerating to zero velocity */
    easeOutCirc,
    /**  circular easing in/out - acceleration until halfway, then deceleration */
    easeInOutCirc
}//Enum

//####################################################################################################//
//####################################################################################################//