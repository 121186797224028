import { FilterDataType } from './filter-data-types';
import { FilterType, GetFilterTypeSymbol } from './filter-types';

/**Info on how to filter a query */
export class FilterRequest {

  /**
   * What field/column to sort by
   **/
  public field: string

  /**
   * What type of filter to use (EQUALS, START_WITH, etc.)
   *
   * Defaults to EQUALS
   */
  public filterType: FilterType = 'equals'

  /**
   * What to compare/filter with
   */
  public filterValue: any

  /**
   * Use when you need to check if multiple values match
   */
  public filterValues: string[]

  /**
   * What type of data is it. Needed on the server side. Default = 'string'
   */
  public filterDataType: FilterDataType = 'string'

  //------------------------------------------------------//    

  /**
   * Genereates new filter request
   * @param field What field/column to sort by
   * @param filterValue What to compare/filter with
   * @param filterType What type of filter to use (EQUALS, START_WITH, etc.) Default =  'equals'
   * @param filterDataType What type of data is it. Needed on the server side. Default = 'string'
   * @param filterValues What type of data is it. Needed on the server side. Default = 'string'
   */
  constructor(
    field: string,
    filterValue?: any,
    filterType: FilterType | null = 'equals',
    filterDataType: FilterDataType = 'string',
    filterValues: string[] = []
  ) {

    this.field = field
    this.filterValue = filterValue ?? ''
    this.filterType = filterType ?? 'equals'
    this.filterDataType = filterDataType
    this.filterValues = filterValues

    //Try and get the values from filterValue if the array is empty
    if (filterType === 'in' && !this.filterValues?.length)
      this.filterValues = this.filterValue.split(',')

  } //ctor

  //------------------------------------------------------//  

  public isEmpty(): boolean {

    //Should work for arrays and strings
    if (this.filterDataType === 'boolean' && this.filterValue === 'all')
      return true;

    return !this.filterValue?.length

  } 

  //------------------------------------------------------//

  description = (): string =>
    `${this.camelCaseToTitleCase(this.field)} ${GetFilterTypeSymbol(this.filterType as FilterType)} ${this.filterValue}`

  //------------------------------------------------------//

  isEqual(that: FilterRequest): boolean {

    if (this.field !== that.field) return false;
    if (this.filterValue !== that.filterValue) return false;
    if (this.filterType !== that.filterType) return false;

    return true

  }

  //------------------------------------------------------//


  private camelCaseToTitleCase(value?: string): string {
    if (!value) return '';

    if (typeof value !== 'string') return value;

    value = value.split(/(?=[A-Z])/).join(' ');
    value = value[0].toUpperCase() + value.slice(1);

    return value;
  }


  //------------------------------------------------------//

} //Cls
