import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation, withPreloading, } from '@angular/router';
import { provideFromAppHeaderInterceptor } from '@inigo/authentication/interceptors';
import { AuthSetup } from '@inigo/authentication/setup';
import { ContactSetup } from '@inigo/contact/config';
import { InigoCoreModule } from '@inigo/core';
import { HangfireHelpersSetup } from '@inigo/hangfire-helpers/config';
import { DelayPreloadingStrategy } from '@inigo/lazy-load-helpers/preload';
import { MyMatSetup } from '@inigo/my-mat/config';
import { PopUpSetup } from '@inigo/pop-up/config';
import { StripeHelpersSetup } from '@inigo/stripe-helpers/config';
import { SwHelpersModule } from '@inigo/sw-helpers';
import { ThemeAndModeSetup } from '@inigo/theme-and-mode/config';
import { ToastSetup } from '@inigo/toast/setup';
import { provideNgxStripe } from 'ngx-stripe';  
import { AUTH_CONFIG, CONTACT_CONFIG, CORE_CONFIG, HF_CONFIG, POP_UP_CONFIG, THEME_CONFIG, TOAST_CONFIG } from '@inigo/conecto/wb/core/config';
import { Secrets } from './app-secrets';
import { appRoutes } from './app.routes';
import { HttpInterceptorSetup } from './shared/http-interceptors/interceptor-provider';
import { provideClientHydration } from '@angular/platform-browser';
// import { ContactSetup } from '@inigo/contact/config';
// import { InigoCoreModule } from '@inigo/core';
// import { HangfireHelpersSetup } from '@inigo/hangfire-helpers/config';
// import { PopUpModule } from '@inigo/pop-up';
// import { StripeHelpersSetup } from '@inigo/stripe-helpers/config';
// import { SwHelpersModule } from '@inigo/sw-helpers';
// import { ThemeAndModeSetup } from '@inigo/theme-and-mode/config';
// import { ToastSetup } from '@inigo/toast/setup';
// import { provideNgxStripe } from 'ngx-stripe';
// import { AUTH_CONFIG, CONTACT_CONFIG, CORE_CONFIG, HF_CONFIG, POP_UP_CONFIG, THEME_CONFIG, TOAST_CONFIG, TROUNCER_CONFIG } from '@inigo/conecto/wb/core/config';
// import { Secrets } from './app-secrets';
// import { HttpInterceptorSetup } from './shared/http-interceptors/interceptor-provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withPreloading(DelayPreloadingStrategy)
    ),
    StripeHelpersSetup.getProvidersBasic(Secrets.stripeKey),
    provideNgxStripe(Secrets.stripeKey),
    provideFromAppHeaderInterceptor(Secrets.fromAppHeaderValue),
    importProvidersFrom(InigoCoreModule.configure(CORE_CONFIG)),

    ...PopUpSetup.getProviders(POP_UP_CONFIG),
    ...ToastSetup.getProviders(TOAST_CONFIG),
    ...ContactSetup.getAllProviders(CONTACT_CONFIG),
    ...MyMatSetup.getProviders_DD_MMM_YYYY(),
    ...ThemeAndModeSetup.getThemeProviders(THEME_CONFIG),
    ...HangfireHelpersSetup.getProviders(HF_CONFIG),

    ...AuthSetup.Create(AUTH_CONFIG)
      .getProviders(),


    importProvidersFrom(SwHelpersModule.configure()),
    ...HttpInterceptorSetup.getProviders(),
  ],
};
