
export class ArrayHelpers {

    //------------------------------------------------//

    public static mergeUnique<U, V>(arr1: U[], arr2: V[], comparer: (u: U, v: V) => boolean): any[] {

        let arr2Filtered = arr2.filter(f2 =>
            !arr1.some(f1 => comparer(f1, f2))
        )

        return [...arr1, ...arr2Filtered]

    }

    //------------------------------------------------//

    public static mergeUnion = <U, V>(arr1: U[], arr2: V[]): any[] =>
        [...new Set([...arr1, ...arr2])]

    //------------------------------------------------//

    public static flatten<T>(array: T[][]) {

        if (!array?.length)
            return []

        let retArray: T[] = []

        array.forEach(a => retArray = retArray.concat(a))

        return retArray

    }

    //------------------------------------------------//

    public static shuffle<T>(array: T[]) {

        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }

        return array

    }

    //------------------------------------------------//s

    public static tail = <T>(a: Array<T>): T => a[a.length - 1];

    //------------------------------------------------//

    public static toChunks<T>(arr: T[], chunkSize = 5): T[][] {

        const res: T[][] = []

        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize)
            res.push(chunk)
        }

        return res

    }//toChunks

    //------------------------------------------------//

    static filterDistinct = <T>(array: T[]) =>
        array.filter(this.onlyUnique.bind(this))

    //- - - - - - - - - - - - - - - - - - - - - - - - //

    private static onlyUnique = <T>(value: T, idx: number, array: T[]): boolean =>
        array.indexOf(value) === idx;


    //------------------------------------------------//

    static pushIfUnique<T>(
        array: T[],
        item: T,
        predicate: (item1: T, item2: T) => boolean = (i1, i2) => i1 == i2
    ) {


        const idx = array.findIndex(
            c => predicate(c, item)
        )


        if (idx < 0)
            array.push(item)

    }//pushIfUnique

    //------------------------------------------------//

    /**
     * Inserts value in the correct position of a sorted array
     * @param array sorted array
     * @param value item to insert
     * @param greaterThan Predicate to return true if t1 is greater than t2
     * @returns 
     */
    static insertInOrder<T>(
        array?: T[],
        value?: T,
        greaterThan: (t1: T, t2: T) => boolean = (t1, t2) => t1 < t2)
        : T[] {

        if (!value) return array ?? []
        if (!array?.length) return [value]

        const idx = ArrayHelpers.sortedIndex(array, value, greaterThan)

        array.splice(idx, 0, value)

        return array

    }

    //------------------------------------------------//

    /**
     * Get the potential index of value if it was to be inserted (in order) into array
     * @param array sorted array
     * @param value item to insert
     * @param greaterThan Predicate to return true if t1 is greater than t2
     * @returns 
     */
    static sortedIndex<T>(
        array?: T[],
        value?: T,
        greaterThan: (t1: T, t2: T) => boolean = (t1, t2) => t1 < t2)
        : number {

        if (!array?.length || !value)
            return 0

        let low: number = 0
        let high: number = array.length;


        while (low < high) {

            var mid = (low + high) >>> 1  //(Divide by 2)      
            if (greaterThan(value, array[mid]))
                low = mid + 1
            else
                high = mid

        }//while

        return low

    }

    //------------------------------------------------//

    static groupBy<T>(list: T[], keyGetter: (t: T) => any) {

        const map = new Map();
        list.forEach((item) => {
          const key = keyGetter(item);
          console.log(key);
    
          const collection = map.get(key);
          if (!collection)
            map.set(key, [item])
          else
            collection.push(item);
    
        });
        return Array.from(map)

      }

    //------------------------------------------------//

}//Cls


// export function groupBy<T>(list: T[], keyGetter: (t: T) => any) {

//     const map = new Map<any, T[]>();
//     list.forEach((item) => {
//       const key = keyGetter(item);
//       console.log(key);

//       const collection = map.get(key);
//       if (!collection)
//         map.set(key, [item])
//       else
//         collection.push(item);

//     })
    
//     return Array.from(map.values())

//   }//groupBy

  export function groupBy<K, V>(list: V[], keyGetter: (t: V) => K) {

    const map = new Map<any, V[]>();
    list.forEach((item) => {
      const key = keyGetter(item);
      console.log(key);

      const collection = map.get(key);
      if (!collection)
        map.set(key, [item])
      else
        collection.push(item)
    })
    return map

  }//groupBy