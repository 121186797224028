import { AccRoutes } from "@inigo/acc-n-auth-data/routes"


export class PublicAreaRoutes {


    public static readonly BASE = ''

    public static readonly HOME ='home'
    public static readonly DOWNLOADS = 'downloads'
    public static readonly HF_DB_BACKUP = 'dbbackup'
    public static readonly HF_HELPERS = 'hf-helpers'
    public static readonly HF_TASKS = 'tasks'
    public static readonly NOT_FOUND =  'notfound'
    public static readonly OPTIONS_SETTINGS ="options-settings"
    public static readonly PAYMENTS = 'payments'
    public static readonly CUSTOMERS = 'customers'
    public static readonly TEST = 'testse'
    public static readonly TEAM = 'users'
    public static readonly USERS = 'users'
    public static readonly SETTINGS = 'settings'
    public static readonly ABOUT = 'about'
    public static readonly BRAND = 'brand'
    public static readonly CONTACT = 'contact'
    public static readonly CHECKOUT = 'checkout'
    public static readonly INDUSTRIES = 'industries'
    public static readonly DETAIL = 'detail'
    public static readonly MY_ORDERS_CUSTOMER_PARAM = 'customerId'
    public static readonly MY_ORDERS = 'my-orders'
    public static readonly MY_ACCOUNT = 'my-account'
    public static readonly MY_ORDERS_CUSTOMER_ID = `${this.MY_ORDERS}/:${this.MY_ORDERS_CUSTOMER_PARAM}`
    public static readonly MY_INFO_CUSTOMER = `${this.MY_ACCOUNT}/:${this.MY_ORDERS_CUSTOMER_PARAM}`
    public static readonly PAYMENT_RESULT_PARAM = 'orderId'
    public static readonly PAYMENT_SESSION_PARAM = 'session_id'
    public static readonly PAYMENT_COMPLETE = `payment-complete`
    public static readonly PAYMENT_ERROR = `payment-error`
    public static readonly PAYMENT_SUCCESS = `payment-success`
    public static readonly PAYMENT_ERROR_ORDER_ID = `${this.PAYMENT_ERROR}/:${this.PAYMENT_RESULT_PARAM}`
    public static readonly PAYMENT_COMPLETE_ORDER_ID = `${this.PAYMENT_COMPLETE}/:${this.PAYMENT_RESULT_PARAM}`
    public static readonly PRODUCTS = 'products'
    public static readonly PRODUCT = 'product'
    public static readonly PRODUCTS_PARAM = 'dept'
    public static readonly PRODUCT_DETAIL_PARAM = 'prodId'
    public static readonly PRODUCT_VARIANTS = 'vars'
    public static readonly PRODUCTS_DEPT = `${this.PRODUCTS}/:${this.PRODUCTS_PARAM}`
    public static readonly PRODUCTS_ALL = `${this.PRODUCTS}/all`
    public static readonly PRODUCTS_DETAIL = `${this.PRODUCT}/:${this.PRODUCT_DETAIL_PARAM}`
    public static readonly ORDER_SUMMARY = 'order'
    public static readonly REGISTER = 'register'
    public static readonly UPLOAD = 'upload'

    

    static Accounts = class {

        static readonly BASE = `${AccRoutes.ACCOUNT_FEATURES}`
        static readonly APPS = `${this.BASE}/${AccRoutes.APPS}`
        static readonly ACCESS_POINTS = `${this.BASE}/${AccRoutes.ACCESS_POINTS}`
        static readonly AUTH_TEST = `${this.BASE}/${AccRoutes.AUTH_TEST}`
        static readonly CHANGE_PWD = `${this.BASE}/${AccRoutes.CHANGE_PWD}`
        static readonly CONFIRM_EMAIL_CUSTOMER = `${this.BASE}/${AccRoutes.CONFIRM_EMAIL}`
        static readonly CONFIRM_EMAIL_MNTC = `${this.BASE}/${AccRoutes.CONFIRM_EMAIL_MNTC}`
        static readonly CUSTOMERS_ID_PARAM = `${this.BASE}/${AccRoutes.CUSTOMERS_ID_PARAM}`
        static readonly CUSTOMERS = `${this.BASE}/${AccRoutes.CUSTOMERS}`
        static readonly DEVICES = `${this.BASE}/${AccRoutes.DEVICES}`
        static readonly EMAIL_CONFIRMED_CUSTOMER = `${this.BASE}/${AccRoutes.EMAIL_CONFIRMED}`
        static readonly EMAIL_CONFIRMED_MNTC = `${this.BASE}/${AccRoutes.EMAIL_CONFIRMED_MNTC}`
        static readonly EMAIL_NOT_CONFIRMED_CUSTOMER = `${this.BASE}/${AccRoutes.EMAIL_NOT_CONFIRMED_CUSTOMER}`
        static readonly EMAIL_NOT_CONFIRMED_MNTC = `${this.BASE}/${AccRoutes.EMAIL_NOT_CONFIRMED_MNTC}`
        static readonly HOME = `${this.BASE}/${AccRoutes.HOME}`
        static readonly LOGIN = `${this.BASE}/${AccRoutes.LOGIN}`
        static readonly LIST = `${this.BASE}/${AccRoutes.LIST}`
        static readonly MY_INFO = `${this.BASE}/${AccRoutes.MY_INFO}`
        static readonly RESET_PWD = `${this.BASE}/${AccRoutes.APPS}`
        static readonly TWO_FACTOR_VERIFICATION = `${this.BASE}/${AccRoutes.TWO_FACTOR_VERIFICATION}`
        static readonly USERS = `${this.BASE}/${AccRoutes.USERS}`
        static readonly MNTC_USERS = `${this.BASE}/${AccRoutes.MNTC_USERS}`
        static readonly SUPER_USERS = `${this.BASE}/${AccRoutes.SUPER_USERS}`

        public static readonly AllowAnonymousRoutes = [
            this.LOGIN,
            this.CONFIRM_EMAIL_MNTC,
            this.EMAIL_CONFIRMED_CUSTOMER,
            this.EMAIL_CONFIRMED_MNTC,
            this.EMAIL_NOT_CONFIRMED_CUSTOMER,
            this.EMAIL_NOT_CONFIRMED_MNTC,
            this.TWO_FACTOR_VERIFICATION,
        ]

    }//Cls


}//Cls

//---------------------------------------------------------//

export class PublicAreaRoutesFullPath {

    public static readonly ABOUT = `${PublicAreaRoutes}/${PublicAreaRoutes.ABOUT}`
    public static readonly BRAND = `${PublicAreaRoutes}/${PublicAreaRoutes.BRAND}`
    public static readonly CHECKOUT = `${PublicAreaRoutes}/${PublicAreaRoutes.CHECKOUT}`
    public static readonly CONTACT = `${PublicAreaRoutes}/${PublicAreaRoutes.CONTACT}`
    public static readonly CUSTOMERS = `${PublicAreaRoutes}/${PublicAreaRoutes.CUSTOMERS}`
    public static readonly HOME = `${PublicAreaRoutes}/${PublicAreaRoutes.HOME}`
    public static readonly INDUSTRIES = `${PublicAreaRoutes}/${PublicAreaRoutes.INDUSTRIES}`
    public static readonly NOT_FOUND = `${PublicAreaRoutes}/${PublicAreaRoutes.NOT_FOUND}`
    public static readonly PRODUCTS = `${PublicAreaRoutes}/${PublicAreaRoutes.PRODUCTS}`
    public static readonly DETAIL = `${PublicAreaRoutes}/${PublicAreaRoutes.DETAIL}`
    public static readonly MY_INFO_CUSTOMER = `${PublicAreaRoutes}/${PublicAreaRoutes.MY_INFO_CUSTOMER}`
    public static readonly MY_ORDERS = `${PublicAreaRoutes}/${PublicAreaRoutes.MY_ORDERS}`
    public static readonly PRODUCT = `${PublicAreaRoutes}/${PublicAreaRoutes.PRODUCT}`
    public static readonly PAYMENT_ERROR = `${PublicAreaRoutes}/${PublicAreaRoutes.PAYMENT_ERROR_ORDER_ID}`
    public static readonly PAYMENT_SUCCESS = `${PublicAreaRoutes}/${PublicAreaRoutes.PAYMENT_COMPLETE_ORDER_ID}`
    public static readonly PRODUCTS_PARAM = `${PublicAreaRoutes}/${PublicAreaRoutes.PRODUCTS_PARAM}`
    public static readonly PRODUCT_DETAIL_PARAM = `${PublicAreaRoutes}/${PublicAreaRoutes.PRODUCT_DETAIL_PARAM}`
    public static readonly PRODUCT_VARIANTS = `${PublicAreaRoutes}/${PublicAreaRoutes.PRODUCT_VARIANTS}`
    public static readonly PRODUCTS_DEPT = `${PublicAreaRoutes}/${PublicAreaRoutes.PRODUCTS_DEPT}`
    public static readonly PRODUCTS_ALL = `${PublicAreaRoutes}/${PublicAreaRoutes.PRODUCTS_ALL}`
    public static readonly PRODUCTS_DETAIL = `${PublicAreaRoutes}/${PublicAreaRoutes.PRODUCTS_DETAIL}`
    public static readonly USERS = `${PublicAreaRoutes}/${PublicAreaRoutes.USERS}`
    public static readonly UPLOAD = `${PublicAreaRoutes}/${PublicAreaRoutes.UPLOAD}`


    static Accounts = class {

        public static readonly BASE = `${PublicAreaRoutes.BASE}/${PublicAreaRoutes.Accounts.BASE}`
        static readonly APPS = `${this.BASE}/${PublicAreaRoutes.Accounts.APPS}`
        static readonly ACCESS_POINTS = `${this.BASE}/${PublicAreaRoutes.Accounts.ACCESS_POINTS}`
        static readonly AUTH_TEST = `${this.BASE}/${PublicAreaRoutes.Accounts.AUTH_TEST}`
        static readonly CHANGE_PWD = `${this.BASE}/${PublicAreaRoutes.Accounts.CHANGE_PWD}`
        static readonly CONFIRM_EMAIL_CUSTOMER = `${this.BASE}/${PublicAreaRoutes.Accounts.CONFIRM_EMAIL_CUSTOMER}`
        static readonly CONFIRM_EMAIL_MNTC = `${this.BASE}/${PublicAreaRoutes.Accounts.CONFIRM_EMAIL_MNTC}`
        static readonly CUSTOMERS_ID_PARAM = `${this.BASE}/${PublicAreaRoutes.Accounts.CUSTOMERS_ID_PARAM}`
        static readonly CUSTOMERS = `${this.BASE}/${PublicAreaRoutes.Accounts.CUSTOMERS}`
        static readonly DEVICES = `${this.BASE}/${PublicAreaRoutes.Accounts.DEVICES}`
        static readonly EMAIL_CONFIRMED_CUSTOMER = `${this.BASE}/${PublicAreaRoutes.Accounts.EMAIL_CONFIRMED_CUSTOMER}`
        static readonly EMAIL_CONFIRMED_MNTC = `${this.BASE}/${PublicAreaRoutes.Accounts.EMAIL_CONFIRMED_MNTC}`
        static readonly EMAIL_NOT_CONFIRMED_CUSTOMER = `${this.BASE}/${PublicAreaRoutes.Accounts.EMAIL_NOT_CONFIRMED_CUSTOMER}`
        static readonly EMAIL_NOT_CONFIRMED_MNTC = `${this.BASE}/${PublicAreaRoutes.Accounts.EMAIL_NOT_CONFIRMED_MNTC}`
        static readonly HOME = `${this.BASE}/${PublicAreaRoutes.Accounts.HOME}`
        static readonly LOGIN = `${this.BASE}/${PublicAreaRoutes.Accounts.LOGIN}`
        static readonly LIST = `${this.BASE}/${PublicAreaRoutes.Accounts.LIST}`
        static readonly MY_INFO = `${this.BASE}/${PublicAreaRoutes.Accounts.MY_INFO}`
        static readonly RESET_PWD = `${this.BASE}/${PublicAreaRoutes.Accounts.APPS}`
        static readonly TWO_FACTOR_VERIFICATION = `${this.BASE}/${PublicAreaRoutes.Accounts.TWO_FACTOR_VERIFICATION}`
        static readonly USERS = `${this.BASE}/${PublicAreaRoutes.Accounts.USERS}`
        static readonly MNTC_USERS = `${this.BASE}/${PublicAreaRoutes.Accounts.MNTC_USERS}`
        static readonly SUPER_USERS = `${this.BASE}/${PublicAreaRoutes.Accounts.SUPER_USERS}`

        public static readonly AllowAnonymousRoutes = [
            this.LOGIN,
            this.CONFIRM_EMAIL_MNTC,
            this.EMAIL_CONFIRMED_CUSTOMER,
            this.EMAIL_CONFIRMED_MNTC,
            this.EMAIL_NOT_CONFIRMED_CUSTOMER,
            this.EMAIL_NOT_CONFIRMED_MNTC,
            this.TWO_FACTOR_VERIFICATION,
        ]

    }//Cls

}//Cls

//===============================================================//