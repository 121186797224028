import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Observable, combineLatest, map, of, tap } from 'rxjs';
import { FilterRequest } from '../models/filter-request';
import { ColumnFilter } from './column-filter';

@Injectable({
  providedIn: 'root'
})
export class ColumnFilterService {

  private _platformId = inject(PLATFORM_ID)

  //------------------------------------------------//

  toFilterObservableArray(columnFilters: Array<ColumnFilter>): Observable<FilterRequest[]> {

    if (isPlatformServer(this._platformId))
      return of([])

    const filterInputs$: Array<Observable<FilterRequest>> = []

    columnFilters.forEach((cf) => filterInputs$.push(cf.asObservable()))

    //Combine them
    return combineLatest([...filterInputs$])
      .pipe(
        // tap(l => { console.log('_colFilters', l) }),
        map(fliters => fliters.filter(cf => cf.filterValue !== '' || !!cf.filterValues?.length))
      )

  } 

  //------------------------------------------------//

}//Cls
