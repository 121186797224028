export type FilterDataType = 'string' | 'number' | 'date' | 'boolean'      //    | 'image' | 'color' | 'action'

/** Use when the user might be using custom filterTypes */
export const isFilterDataType = (filterType: any) => 
    filterType == 'string' 
    || filterType == 'number' 
    || filterType == 'date' 
    || filterType == 'boolean' 
    || filterType == undefined

