import { Injectable, inject } from '@angular/core';
import { Role } from '@inigo/acc-n-auth-data/roles';
import { RolesService } from './roles/roles.service';
import { AuthRolesService } from './auth.roles.service';

/**
 * Service to show the roles that this user can administer/create/edit etc.
 */
@Injectable({
  providedIn: 'root',
})
export class AvailableRolesService {


  private _authService = inject(AuthRolesService)
  private _roles = inject(RolesService)

  //-------------------------------------------------------------------------------//

  private customerUserRoles: Array<Role> = []
  private customerMgrRoles: Array<Role> = [...this.customerUserRoles, this._roles.CUSTOMER_USER, this._roles.CUSTOMER_MGR,]
  private customerAdminRoles: Array<Role> = [...this.customerMgrRoles, this._roles.CUSTOMER_ADMIN,]
  private customerLeaderRoles: Array<Role> = [...this.customerAdminRoles, this._roles.CUSTOMER_LEADER,]

  private maintenanceUserRoles: Array<Role> = [...this.customerLeaderRoles, this._roles.MAINTENANCE_USER,]
  private maintenanceMgrRoles: Array<Role> = [...this.maintenanceUserRoles, this._roles.MAINTENANCE_MGR,]
  private maintenanceAdminRoles: Array<Role> = [...this.maintenanceMgrRoles, this._roles.MAINTENANCE_ADMIN,]
  private maintenanceLeaderRoles: Array<Role> = [...this.maintenanceAdminRoles, this._roles.MAINTENANCE_LEADER,]

  private superUserRoles: Array<Role> = [...this.maintenanceLeaderRoles, this._roles.SUPER_USER,]
  private superMgrRoles: Array<Role> = [...this.superUserRoles, this._roles.SUPER_MGR,]
  private superAdminRoles: Array<Role> = [...this.superMgrRoles, this._roles.SUPER_ADMIN,]
  private superLeaderRoles: Array<Role> = [...this.superAdminRoles, this._roles.SUPER_LEADER,]

  private mgrRoles: Array<Role> = [this._roles.MGR, this._roles.USER]
  private adminRoles: Array<Role> = [...this.mgrRoles, this._roles.ADMIN]
  private leaderRoles: Array<Role> = [...this.adminRoles, this._roles.LEADER]

  //-------------------------------------------------------------------------------//

  getRoles(): Array<Role> {
    //Start from highest level access

    if (this._authService.isSprLdr$()) return this.superLeaderRoles

    if (this._authService.isSprAdmin$()) return this.superAdminRoles

    if (this._authService.isSprMgr$()) return this.superMgrRoles

    if (this._authService.isSprUser$()) return this.superUserRoles

    if (this._authService.isSpr$()) return this.superUserRoles

    //- - - - - - - - - - - - - - - -//

    if (this._authService.isMntcLdr$()) return this.maintenanceLeaderRoles

    if (this._authService.isMntcAdmin$()) return this.maintenanceAdminRoles

    if (this._authService.isMntcMgr$()) return this.maintenanceMgrRoles

    if (this._authService.isMntcUser$()) return this.maintenanceUserRoles

    if (this._authService.isMntc$()) return this.maintenanceUserRoles

    //- - - - - - - - - - - - - - - -//

    if (this._authService.isCusLdr$()) return this.customerLeaderRoles

    if (this._authService.isCusAdmin$()) return this.customerAdminRoles

    if (this._authService.isCusMgr$()) return this.customerMgrRoles

    if (this._authService.isCusUser$()) return this.customerUserRoles

    if (this._authService.isCus$()) return this.customerUserRoles

    //- - - - - - - - - - - - - - - -//

    //if we got this far just sen out customer roles

    if (this._authService.isLdr$()) return this.customerUserRoles

    if (this._authService.isAdmin$()) return this.customerAdminRoles

    if (this._authService.isMgr$()) return this.customerMgrRoles

    if (this._authService.isUser$()) return this.customerUserRoles

    return []
  } //getRoles

  //-------------------------------------------------------------------------------//

  /**
   * Get roles of the team that the current user can administer
   * @returns Array of roles
   */
  getTeamRoles(): Array<Role> {
    //Start from highest level access
    if (this._authService.isSprLdr$()) return this.getSuperRoles()

    if (this._authService.isMntc$()) return this.getMaintenanceRoles()

    if (this._authService.isCus$()) return this.getCustomerRoles()

    return []
  }

  //-------------------------------------------------------------------------------//

  /**
   * Get roles for Super admin that the current user can administer
   * @returns Array of roles
   */
  getSuperRoles(): Array<Role> {
    const superUserRoles: Array<Role> = []
    const superMgrRoles: Array<Role> = [...superUserRoles, this._roles.SUPER_USER, this._roles.SUPER_MGR,]
    const superAdminRoles: Array<Role> = [...superMgrRoles, this._roles.SUPER_ADMIN,]
    const superLeaderRoles: Array<Role> = [...superAdminRoles, this._roles.SUPER_LEADER,]

    if (!this._authService.isSprMinimum$()) return []

    //Start from highest level access
    if (this._authService.isSprLdr$()) return superLeaderRoles

    if (this._authService.isSprAdmin$()) return superAdminRoles

    if (this._authService.isSprMgr$()) return superMgrRoles

    if (this._authService.isSprUser$()) return superUserRoles

    if (this._authService.isSpr$()) return superUserRoles

    return []
  } //getSuperRoles

  //-------------------------------------------------------------------------------//

  /**
   * Get roles for Maintenance admin that the current user can administer
   * @returns Array of roles
   */
  getMaintenanceRoles(): Array<Role> {

    const maintenanceUserRoles: Array<Role> = []
    const maintenanceMgrRoles: Array<Role> = [...maintenanceUserRoles, this._roles.MAINTENANCE_USER, this._roles.MAINTENANCE_MGR,]
    const maintenanceAdminRoles: Array<Role> = [...maintenanceMgrRoles, this._roles.MAINTENANCE_ADMIN,]
    const maintenanceLeaderRoles: Array<Role> = [...maintenanceAdminRoles, this._roles.MAINTENANCE_LEADER,]

    if (!this._authService.isMntcMinimum$()) return []

    //Start from highest level access
    console.log(this._authService.isSprMinimum$());


    if (this._authService.isSprMinimum$()) return maintenanceLeaderRoles

    if (this._authService.isMntcLdr$()) return maintenanceLeaderRoles

    if (this._authService.isMntcAdmin$()) return maintenanceAdminRoles

    if (this._authService.isMntcMgr$()) return maintenanceMgrRoles

    if (this._authService.isMntcUser$()) return maintenanceUserRoles

    if (this._authService.isMntc$()) return maintenanceUserRoles

    if (this._authService.isMntcMinimum$()) return maintenanceLeaderRoles

    return []

  } //getMaintenanceRoles

  //-------------------------------------------------------------------------------//

  /**
   * Get roles for Customer admin that the current user can administer
   * @returns Array of roles
   */
  getCustomerRoles(noGuest = true): Array<Role> {

    if (!this._authService.isCusMinimum$()) return []

    const customerUserRoles: Array<Role> = noGuest ?
      [this._roles.CUSTOMER_USER] : [this._roles.CUSTOMER_USER, this._roles.CUSTOMER_GUEST]

    const customerMgrRoles: Array<Role> = [
      ...customerUserRoles,
      this._roles.CUSTOMER_MGR,
    ]

    const customerAdminRoles: Array<Role> = [
      ...customerMgrRoles,
      this._roles.CUSTOMER_ADMIN,
    ]

    const customerLeaderRoles: Array<Role> = [
      ...customerAdminRoles,
      this._roles.CUSTOMER_LEADER,
    ]

    if (this._authService.isMntcMinimum$()) return customerLeaderRoles

    //Start from highest level access
    if (this._authService.isCusLdr$()) return customerLeaderRoles

    if (this._authService.isCusAdmin$()) return customerAdminRoles

    if (this._authService.isCusMgr$()) return customerMgrRoles

    if (this._authService.isCusUser$()) return customerUserRoles

    if (this._authService.isCus$()) return customerUserRoles

    if (this._authService.isCusMinimum$()) return customerLeaderRoles

    return []
  } //getCustomerRoles

  //-------------------------------------------------------------------------------//

} //Cls
