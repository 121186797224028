import { IsNumber } from '@inigo/helpers/numbers';
import { TimeInMillis } from './time-in';
import { StringHelpers } from '@inigo/helpers/text';

//#########################################################//

const daysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const daysShort =['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export type DaysFull = typeof daysFull[number]
export type DaysShort = typeof daysShort[number]

//#########################################################//

export class DateHelpers {

  static FromMilliseconds(millis: number): Date {
    try {
      if (!IsNumber(millis) || millis < 0) return new Date();

      return new Date(millis);
    } catch (error) {
      return new Date();
    }
  } //FromMilliseconds

  //--------------------------------------------------//

  static FromSeconds = (secs?: number): Date =>
    DateHelpers.FromMilliseconds((secs ?? 0) * TimeInMillis.Second);

  //--------------------------------------------------//

  static FormatYearMonthDay(date: Date = new Date(), separator = '-') {

    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;

    if (day.length < 2) day = '0' + day;

    return [year, month, day].join(separator);
  } //FormatDateYearMonthDay

  //--------------------------------------------------//


  static To_dd_MMM_YYYY(date?: Date | string | null): string {

    if (!date)
      return ''

    const dt = new Date(date)
    const day = dt.toLocaleString('default', { day: '2-digit' });
    const month = dt.toLocaleString('default', { month: 'short' });
    const year = dt.toLocaleString('default', { year: 'numeric' });

    return day + '-' + month + '-' + year;

  }

  //--------------------------------------------------//


  static dd_MMM_YYYY_HH_mm(date?: Date | string | null): string {

    if (!date)
      return ''

    const dt = new Date(date)
    const day = dt.toLocaleString('default', { day: '2-digit' });
    const month = dt.toLocaleString('default', { month: 'short' });
    const year = dt.toLocaleString('default', { year: 'numeric' });
    const hours = StringHelpers.Pad(dt.getHours(), 2)
    const mins = StringHelpers.Pad(dt.getMinutes(), 2)

    return `${day}-${month}-${year} ${hours}:${mins}`

  }

  //--------------------------------------------------//


  static To_dd_MMM_YYYY_Or_Undefined(date?: Date | string | null): string | undefined {

    if (!date)
      return undefined

    const dt = new Date(date)
    const day = dt.toLocaleString('default', { day: '2-digit' });
    const month = dt.toLocaleString('default', { month: 'short' });
    const year = dt.toLocaleString('default', { year: 'numeric' });

    return day + '-' + month + '-' + year;

  }

  //--------------------------------------------------//

  static ToSafeDate(date?: Date | string | null | undefined): Date {

    if (!date)
      return new Date()
    try {
      return new Date(date)
    } catch (error) {
      return new Date()
    }
  }

  //--------------------------------------------------//

  /**
   * Formats date fore io-datetime 
   * YYYY-MM-DDTHH:mm	(1994-12-15T13:47)
   */
  static ToIonicDate(date?: Date | string): string {

    console.log(date);

    date = new Date(date ??= new Date())

    const day = date.toLocaleString('default', { day: '2-digit' })
    const mnt = date.toLocaleString('default', { month: '2-digit' })
    console.log(mnt);

    const year = date.toLocaleString('default', { year: 'numeric' })
    const hours = StringHelpers.Pad(date.getHours(), 2)
    const mins = StringHelpers.Pad(date.getMinutes(), 2)


    return `${year}-${mnt}-${day}T${hours}:${mins}`

  }//toIonicDate


  //--------------------------------------------------//


  static WithoutTime(date?: Date): Date {

    //Make sure it's a date object
    const d = date ? new Date(date) : new Date()
    d.setHours(0, 0, 0, 0)
    return d

  }//withoutTime

  //--------------------------------------------------//


  static IsToday(date?: Date): boolean {

    if (!date)
      return false

    const today = new Date()

    return this.WithoutTime(today).getTime() === this.WithoutTime(date).getTime()

  }//isToday

  //--------------------------------------------------//


  static IsTomorrow(date?: Date): boolean {

    if (!date)
      return false

    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)

    return this.WithoutTime(tomorrow).getTime() === this.WithoutTime(date).getTime()

  }//IsTomorrow

  //--------------------------------------------------//

  static DaysAgo(date: Date | string) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    const safeDate = new Date(date)

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(new Date().getMilliseconds() - safeDate.getMilliseconds());

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  }

  //--------------------------------------------------//

  static IsWeekend(date: Date | string) {
    if (!date)
      return false
    const safeDate = this.ToSafeDate(date)
    const day = safeDate.getDay()
    return day === 0 || day === 6
  }

  //--------------------------------------------------//

  static WeekdayNameFull(date: Date | string) {
    const safeDate = new Date(date)
    return daysFull[safeDate.getDay()];
  }

  //--------------------------------------------------//

  static WeekdayNameShort(date: Date | string) {
    const safeDate = new Date(date)
    return daysShort[safeDate.getDay()];
  }

  //--------------------------------------------------//

} //Cls
