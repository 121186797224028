import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { timer } from 'rxjs';
import { retry } from 'rxjs/operators';

//--------------------------------------------------------//

const failedIconRegex = /(.*)http failure(.*).svg(.*)/

//--------------------------------------------------------//

export const getIconRetryInterceptorFn = (retryCount = 3, retryWaitMilliSeconds = 3000): HttpInterceptorFn =>
  (req, next) => {
    return next(req).pipe(
      retry({
        count: retryCount,
        delay: (error) => shouldRetry(error, retryWaitMilliSeconds)
      })
    )
  }

//--------------------------------------------------------//

function shouldRetry(error: HttpErrorResponse, delayMs: number) {

  const isValid = failedIconRegex.test(error.message.toLowerCase())

  if (isValid)
    return timer(delayMs)
  else
    throw error

}

//--------------------------------------------------------//