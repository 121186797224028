import { isPlatformBrowser } from '@angular/common';
import { Directive, PLATFORM_ID, Signal, WritableSignal, computed, inject, isDevMode, signal } from '@angular/core';
import { Claim, ClaimNames } from '@inigo/acc-n-auth-data/claims';
import { IBaseAuthSignalService } from '@inigo/auth-base/interface';
import { DateHelpers } from '@inigo/helpers/date-time';
import { StringHelpers } from '@inigo/helpers/text';
import { JwtHelper } from '@inigo/jwt';

@Directive({})
export abstract class BaseAuthSignalService implements IBaseAuthSignalService {

  protected _platformId = inject(PLATFORM_ID)

  //- - - - - - - - - - - - - - - - - - - - - //

  protected _accessToken: WritableSignal<string | null> = signal(null)
  accessToken$ = computed(() => this._accessToken())

  decodedToken$ = computed(() => this.decodeToken(this._accessToken()))

  allClaimsRecord$ = computed(() => this.extractAllClaimsRecord(this.decodedToken$()))
  allClaims$ = computed(() => Object.values(this.allClaimsRecord$()))

  //- - - - - - - - - - - - - - - - - - - - - //
  abstract isLoggedIn$: Signal<boolean>
  abstract isNotLoggedIn$: Signal<boolean>
  //- - - - - - - - - - - - - - - - - - - - - //

  userName$ = computed(() => this.getClaimValue(ClaimNames.NAME))
  userId$ = computed(() => this.getClaimValue(ClaimNames.USER_ID))
  email$ = computed(() => this.getClaimValue(ClaimNames.EMAIL_CLAIM) ?? this.getClaimValue(ClaimNames.EMAIL))

  companyId$ = computed(() => this.getClaimValue(ClaimNames.CLAIM_TYPE_COMPANY))
  applicationId$ = computed(() => this.getClaimValue(ClaimNames.CLAIM_TYPE_APPLICATION))

  issuer$ = computed(() => this.getClaimValue(ClaimNames.ISSUER))
  audience$ = computed(() => this.getClaimValue(ClaimNames.AUDIENCE))
  emailVerified$ = computed(() => this.getClaimValue(ClaimNames.EMAIL_VERIFIED))

  issuedAt$ = computed(() => DateHelpers.FromSeconds(this.getClaimValue(ClaimNames.ISSUED_AT)))
  expiry$ = computed(() => DateHelpers.FromSeconds(this.getClaimValue(ClaimNames.EXPIRY)))
  notBefore$ = computed(() => DateHelpers.FromSeconds(this.getClaimValue(ClaimNames.NOT_VALID_BEFORE)))
  authTime$ = computed(() => DateHelpers.FromSeconds(this.getClaimValue(ClaimNames.AUTH_TIME)))

  //- - - - - - - - - - - - - - - - - - - - - //

  isInDevMode$ = signal(isDevMode())

  //------------------------------------------//

  protected abstract storeJwt(accessToken: string): void
  protected abstract logError(logData: any): void

  //------------------------------------------//

  /**
   * @param storedToken Used to populate the Signals
   */
  constructor(storedToken?: (string | null | undefined) | Promise<string | null | undefined>) {

// console.log('AuthRolesService', storedToken);


    if (StringHelpers.IsString(storedToken))
      this._accessToken.set(storedToken ?? null)
    else
      storedToken?.then(tkn => this._accessToken.set(tkn ?? null))
    
  }

  //------------------------------------------//

  /**Read in jwt info*/
  logIn(accessToken: string): void {
    this.storeJwt(accessToken)
    this._accessToken.set(accessToken ?? null)
  }

  //------------------------------------------//

  /**Log out current user - delete jwt and reset all fields*/
  logOut = (): void => this._accessToken.set(null)

  //------------------------------------------//

  /**
   * Cheks if there is an in-date toke stored
   */
  protected isTokenStillValid = (): boolean =>
    new Date() < this.expiry$()

  //------------------------------------------//

  hasClaimType = (claimName: string): boolean =>
    !!claimName && !!this.allClaimsRecord$()[claimName]

  //- - - - - - - - - - - - - - - - - - - - - //

  hasClaim = (claim: Claim): boolean =>
    !!claim && this.allClaimsRecord$()?.[claim.type]?.value == claim.value

  //- - - - - - - - - - - - - - - - - - - - - //

  getClaim(claimName: string): Claim | null {

    if (!claimName)
      return null

    return this.allClaimsRecord$()[claimName]

  }

  //- - - - - - - - - - - - - - - - - - - - - //

  getClaimValue = (claimName: string): any =>
    this.decodedToken$()?.[claimName]

  //------------------------------------------//

  /**Read the stored JWT */
  private decodeToken(accessToken?: string | null): any | null {

    try {
      if (!accessToken)
        return null

      return JwtHelper.decodeToken(accessToken)

    } catch (error) {
      console.log('decodeTokenError', error)
      this.logError(error);
      return null
    }

  }

  //------------------------------------------//

  /**
   * Collect all claims and put them in an array
   * @param parsedToken parsed JWT access token
   */
  private extractAllClaimsRecord(parsedToken: any): Record<string, Claim> {

    var claimsRecord: Record<string, Claim> = {}

    if (!parsedToken)
      return claimsRecord

    const issuer = parsedToken[ClaimNames.ISSUER]
    const userId = parsedToken[ClaimNames.USER_ID]

    for (let key in parsedToken)
      claimsRecord[key] = Claim.CreateWithIssuer(issuer, userId, key, parsedToken[key])

    return claimsRecord

  }

  //------------------------------------------//

  protected isPlatformBrowser = () =>
    isPlatformBrowser(this._platformId)

  //------------------------------------------//

} //Cls
