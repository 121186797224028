import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { JwtStorageService } from './jwt-storage.service';
import { inject } from '@angular/core';
import { AUTH_HEADER, TOKEN_PREFIX } from '@inigo/jwt/config';

export const jwtInterceptorFn: HttpInterceptorFn = (req, next) => {
  
  const jwtStore = inject(JwtStorageService)
  const reqWithAuth = addAuthHeader(req, jwtStore)
  
  return next(reqWithAuth)
};


  //-------------------------------------------//

  /**Adds the JWT (if there is one) to the Auth Header */
  function addAuthHeader(request: HttpRequest<any>, jwtStore: JwtStorageService) {

    const accessToken = jwtStore.getStoredToken()

    if (!accessToken)
      return request;

    return request.clone({
      headers: request.headers.set(
        AUTH_HEADER,
        TOKEN_PREFIX + accessToken
      )
    })

  } 

  //-------------------------------------------//