import { FileHelpers } from '@inigo/helpers/files';
import { ShopFile } from './shop-file';

export class ShopDocument extends ShopFile {

  constructor(id: number, url: string) {
    super()
    this.id = id
    this.url = url
  }

  /**File name without the path stuff */
  public get fileName(): string | null {
    return FileHelpers.GetFilenameFromPath(this.url) ?? null
  }

} //Cls
