import { HttpErrorResponse, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { ReponseMsgs } from '@inigo/data/misc';
import { StatusCodes } from '@inigo/gen-helpers/classes';
import { StringHelpers } from '@inigo/helpers/text';
import { tap } from 'rxjs';

//=======================================//`

const UNEXPECTED_TOKEN = "Unexpected token '<'"

//=======================================//

export const notFoundFnInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    tap({
      next: (n) => { },
      error: (err: any) => {        
        if (err instanceof HttpErrorResponse) {
          if (err.status !== StatusCodes.NOT_FOUND)
            return
          handleNotFound(err, req);
        } //if
      }
    }) //tap
  )
};


//=======================================//

/**
 * Make sure there's a human readable message
 */
function handleNotFound(err: any, req: HttpRequest<any>) {

  console.log('handleNotFound');

  //Intentional error messages will be in err.error.message
  if (!err) return;

  //if err.error is a String take it to be the message
  if (StringHelpers.IsString(err.error) && !isHtml(err.error)) {
    const errMsg = err.error;
    err.error = {};
    err.error.message = errMsg;
    return;
  } //if

  if (!err.error || !err.error?.message) err.error = {};

  if (!err.error?.message || isHtml(err.error.message))
    err.error.message = `${ReponseMsgs.NotFoundErrorMessage}\r\n\r\nRequest: ${req.url}`

}

//---------------------------------------//

function isHtml(message: string): boolean {

  if (!message || !StringHelpers.IsString(message))
    return false;

  const trimmedMessage = message.trim()

  return (trimmedMessage.startsWith('<') && trimmedMessage.endsWith('>'))
    ||
    trimmedMessage.startsWith(UNEXPECTED_TOKEN)

}

//---------------------------------------//

