import { Injectable } from '@angular/core';
import { MyBSArray } from '@inigo/gen-helpers/classes';
import { APageStoreService } from '@inigo/my-store';
import { PagedRequest } from '@inigo/pagination';
import { Observable, Subscription } from 'rxjs';
import { MeasurementDef } from '../../../dtos/measurementDef';
import { MeasurementDefsHttpService } from '../../http/msr-def/msr-defs.service';

@Injectable({
  providedIn: 'root',
})
export class MsrDefsStoreService extends APageStoreService<MeasurementDef, MeasurementDef> {
  
  protected _presetsBs: MyBSArray<MeasurementDef> = new MyBSArray<MeasurementDef>()
  _presets$: Observable<MeasurementDef[]> = this._presetsBs.obs$
  protected _presetsSub?: Subscription

  //-------------------------------------------------------//

  constructor(_httpService: MeasurementDefsHttpService) {
    super(_httpService)

    this.setItemTypeName('Measurement')
    this.setItemNameLamda((p) => p.name ?? `Measurement ${p.id}`);

  } //ctor

  //-------------------------------------------------------//


  refreshPresets(): void {

    // this._isLoadingBs.next(true)

    this._presetsSub?.unsubscribe()
    this._presetsSub = (this.httpService as MeasurementDefsHttpService)
      .getAllPresets()
      // .pipe(finalize(() => this._isLoadingBs.next(false)))
      .subscribe({
        next: (data: MeasurementDef[]) => {
          this._presetsBs.next(data)
        },
        error: (error) => this._errorSb.next(error),
      }) //subscribe

  } //filter

  //-------------------------------------------------------//

  override refreshDataIfNecessary(): void {

    if (!this.totalItems())
      this.getPage(PagedRequest.Empty())

  }

  //-------------------------------------------------------//

} //Cls
