import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Provider } from "@angular/core";
import { CwUnauthorizedInterceptor } from "../auth/cw-auth-interceptor";
// import { CwActiveRequestAlertInterceptor } from "./active-request-alert-interceptor";


export class HttpInterceptorSetup {

  static getProviders = (): (Provider)[] => [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CwUnauthorizedInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CwActiveRequestAlertInterceptor,
    //   multi: true,
    // },
  ]

}//Cls


