import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SsrLocalStorage implements Storage {

  private _platformId = inject(PLATFORM_ID)

  // ---------------------------------------------//

  private isBrowser = () => isPlatformBrowser(this._platformId)
  length: number = this.isBrowser() ? localStorage.length : 0

  // ---------------------------------------------//

  clear(): void {
    if (this.isBrowser())
      localStorage.clear()
  }

  // ---------------------------------------------//

  getItem = (key: string): string | null =>
    this.isBrowser() ? localStorage.getItem(key) : null

  // ---------------------------------------------//

  getItemObject = (key: string): string | null => {

    // console.log('itemisBrowser', this.isBrowser());
    if (!this.isBrowser())
      return null
    
    const item = localStorage.getItem(key)
    // console.log('item', key, item);
    
    return !item ? null : JSON.parse(item)
  }

  // ---------------------------------------------//

  key = (index: number): string | null =>
    this.isBrowser() ? localStorage.key(index) : null

  // ---------------------------------------------//

  removeItem(key: string): void {
    if (this.isBrowser())
      localStorage.removeItem(key)
  }

  // ---------------------------------------------//

  setItem(key: string, value: string): void {
    if (this.isBrowser())
      localStorage.setItem(key, value)
  }

  // ---------------------------------------------//

  setItemObject(key: string, value: any): void {
    if (this.isBrowser())
      localStorage.setItem(key, JSON.stringify(value))
  }

  // ---------------------------------------------//

}//Cls
