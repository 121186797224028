import { Injectable, inject } from '@angular/core';
import { JWT_AUTH_KEY } from '@inigo/jwt/config';
import { SsrLocalStorage } from '@inigo/ssr/storage';

@Injectable({
  providedIn: 'root'
})
export class JwtStorageService {

  protected _localStorage = inject(SsrLocalStorage)

  //--------------------------------------------//

  /**
   * 
   * @param accessToken Store jwt. Will removeJwt if !accessToken
   * @returns 
   */
  storeJwt =(accessToken?: string): void =>
    !accessToken
      ? this.removeJwt()
      : this._localStorage.setItem(JWT_AUTH_KEY, accessToken)  

  //--------------------------------------------//

  removeJwt = (): void =>
    this._localStorage.removeItem(JWT_AUTH_KEY)

  //--------------------------------------------//

  /**Get jwt from storage*/
  getStoredToken = (): string | null =>
    this._localStorage.getItem(JWT_AUTH_KEY)

  //--------------------------------------------//

}
