import { PagedRequest } from './paged-request';

/** Encapsulates the Response from a page request */
export class PagedResponse<T> {

  /**The payload */
  public data: Array<T> = [];

  /**What page this is */
  public pageNumber = 0;

  /**How many items in this page */
  public pageSize = 0;

  /**How many pages are available */
  public totalPages = 0;

  /**How many itemss are available (This is necessary because last page might not be full)*/
  public totalItems = 0;

  /**Pre-prepared request for the next page (Query Param) */
  public nextPageParam?: string;

  /**Pre-prepared request for the previous page (Query Param)*/
  public previousPageParam?: string;

  /**Pre-prepared request for the next page (Post Body) */
  public nextPageBody?: PagedRequest;

  /**Pre-prepared request for the previous page (Post Body)*/
  public previousPageBody?: PagedRequest;

  //--------------------------------------------------------//

  public static Empty<T>(): PagedResponse<T> {
    const pr = new PagedResponse<T>();
    pr.data = [];
    return pr;
  } 

  //--------------------------------------------------------//

  public static FromPagedRequest<T>(request: PagedRequest): PagedResponse<T> {
    const pr = new PagedResponse<T>()
    pr.pageNumber = request.pageNumber
    pr.pageSize = request.pageSize
    pr.data = []
    return pr;
  } 


  //--------------------------------------------------------//
  
  public static FromPagedResponse<T>(newPr: PagedResponse<T>): PagedResponse<T> {
    const pr = new PagedResponse<T>();
    pr.data = [...newPr.data]
    pr.pageNumber = newPr.pageNumber
    pr.pageSize = newPr.pageSize
    pr.totalPages = newPr.totalPages
    pr.totalItems = newPr.totalItems
    pr.nextPageParam = newPr.nextPageParam
    pr.previousPageParam = newPr.previousPageParam
    pr.nextPageBody = newPr.nextPageBody
    pr.previousPageBody = newPr.previousPageBody
    return pr;
  } 


  //--------------------------------------------------------//

  clone(): PagedResponse<T> {
    const pr = new PagedResponse<T>();
    pr.data = [...this.data]
    pr.pageNumber = this.pageNumber
    pr.pageSize = this.pageSize
    pr.totalPages = this.totalPages
    pr.totalItems = this.totalItems
    pr.nextPageParam = this.nextPageParam
    pr.previousPageParam = this.previousPageParam
    pr.nextPageBody = this.nextPageBody
    pr.previousPageBody = this.previousPageBody
    return pr;
  } 

  //--------------------------------------------------------//


} //Cls
