

const ToTitleCase = (originalString?: string): string => {
  if (!originalString) return originalString ?? '';

  const strArray = originalString.split(/(?=[A-Z])/).join(' ');
  return strArray[0].toUpperCase() + strArray.slice(1);
}

//#################################################################################################//


/// <summary>
/// Different ways to filter data
/// </summary>
export type FilterType =
  'equals'
  | 'starts_with'
  | 'ends_with'
  | 'contains'

  | 'greater_than'
  | 'less_than'

  | 'less_than_or_equal_to'
  | 'greater_than_or_equal_to'
  | 'not_equal_to'
  | 'between'
  | 'is_null'
  | 'is_empty'
  | 'in'
  | 'is_not_null'
  | 'is_not_empty'
  | 'is_null_or_white_space'
  | 'is_not_null_or_white_space'

  | 'none'

//#################################################################################################//
//#################################################################################################//

/// <summary>
/// Different ways to filter data
/// </summary>
export class FilterTypes {

  private static _stringToFilterTypeMap: Map<string, FilterType> = new Map([
    ['equals', 'equals'],
    ['starts_with', 'starts_with'],
    ['ends_with', 'ends_with'],
    ['contains', 'contains'],
    ['greater_than', 'greater_than'],
    ['less_than', 'less_than'],
    ['less_than_or_equal_to', 'less_than_or_equal_to'],
    ['greater_than_or_equal_to', 'greater_than_or_equal_to'],
    ['not_equal_to', 'not_equal_to'],
    ['between', 'between'],
    ['is_null', 'is_null'],
    ['is_empty', 'is_empty'],
    ['in', 'in'],
    ['is_not_empty', 'is_not_empty'],
    ['is_null_or_white_space', 'is_null_or_white_space'],
    ['is_empty', 'is_empty'],
    ['is_not_null_or_white_space', 'is_not_null_or_white_space'],
  ])

  //--------------------------------------------------------------------//

  static fromString = (filterType?: string): FilterType  =>
    (!filterType)
      ? 'equals'  //Equals should work on all types
      : this._stringToFilterTypeMap.get(filterType) ?? 'equals'


} //Cls

//#################################################################################################//
//#################################################################################################//

export interface FilterListDropDownItem {
  value: FilterType
  viewValue: string
}

//#################################################################################################//

export const FILTER_TYPES_STRING: FilterListDropDownItem[] = [
  { value: 'equals', viewValue: '=' },
  { value: 'not_equal_to', viewValue: '<>' },
  { value: 'contains', viewValue: 'contains' },
  { value: 'starts_with', viewValue: 'starts with' },
  { value: 'ends_with', viewValue: 'ends with' },
];

//#################################################################################################//

export const FILTER_TYPES_DATE: FilterListDropDownItem[] = [
  { value: 'equals', viewValue: '=' },
  { value: 'not_equal_to', viewValue: '<>' },
  { value: 'greater_than', viewValue: '>' },
  { value: 'greater_than_or_equal_to', viewValue: '≥' },
  { value: 'less_than', viewValue: '<' },
  { value: 'less_than_or_equal_to', viewValue: '≤' },
  { value: 'between', viewValue: 'between' },
];

//#################################################################################################//

export const FILTER_TYPES_NUMBER: FilterListDropDownItem[] = [
  { value: 'equals', viewValue: '=' },
  { value: 'not_equal_to', viewValue: '<>' },
  { value: 'greater_than', viewValue: '>' },
  { value: 'greater_than_or_equal_to', viewValue: '≥' },
  { value: 'less_than', viewValue: '<' },
  { value: 'less_than_or_equal_to', viewValue: '≤' },
];

//#################################################################################################//

export const FILTER_TYPES_BOOLEAN: FilterListDropDownItem[] = [
  { value: 'equals', viewValue: '=' },
  // { value: 'not_equal_to', viewValue: '<>' }
];

//#################################################################################################//

export const FILTER_TYPES_LIST: FilterListDropDownItem[] = [
  { value: 'equals', viewValue: '=' },
  { value: 'not_equal_to', viewValue: '<>' },
];

//#################################################################################################//

export const FILTER_TYPES_LIST_MULTI_SELECT: FilterListDropDownItem[] = [
  { value: 'in', viewValue: 'In Selection' },
];

//#################################################################################################//

export const FILTER_TYPES_ALL_MAP: Map<FilterType, string> = new Map([
  ['equals', '='],
  ['not_equal_to', '<>'],
  ['greater_than', '>'],
  ['greater_than_or_equal_to', '≥'],
  ['less_than', '<'],
  ['less_than_or_equal_to', '≤'],
  ['between', 'between'],
  ['contains', 'contains'],
  ['starts_with', 'starts with'],
  ['ends_with', 'ends with'],
]);

//#################################################################################################//

export const GetFilterTypeSymbol = (filterType: FilterType): string | null =>
  !FILTER_TYPES_ALL_MAP.has(filterType)
    ? '='
    : FILTER_TYPES_ALL_MAP.get(filterType) ?? null;

//#################################################################################################//

export class FilterListItem implements FilterListDropDownItem {
  value: any;
  viewValue: string;

  /**
   * @param value Underlying item
   * @param viewValue What to show in the drop down list
   */
  constructor(value: any, viewValue?: string | number) {
    this.value = value;
    this.viewValue = `${viewValue ?? ToTitleCase(value)}`
  } //ctor

} //Cls

//#################################################################################################//
