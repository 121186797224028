import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, TransferState, computed, inject, makeStateKey } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { PagedRequest } from '@inigo/pagination';
import { EMPTY, Observable, catchError, distinct, filter, ignoreElements, map, of, shareReplay, switchMap, tap, throwError } from 'rxjs';
import { AppRoutes } from '../../../../app-routes';
import { Product, getProductGallery, getProductVideoLinks } from '../../../dtos/product';
import { ProductCustomerService } from '../../http/prod/prod-customer.service';
import { AProdStoreService } from './a-prod-store.service';
@Injectable({
  providedIn: 'root',
})
export class ProdStoreCustomerService extends AProdStoreService {

  private _actRoute = inject(ActivatedRoute)
  private _transferState = inject(TransferState)
  private _platformId = inject(PLATFORM_ID)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private prodId$ = this._actRoute.params.pipe(
    map(params => params[AppRoutes.PublicArea.PRODUCT_DETAIL_PARAM]),
    filter(id => !!id),
    distinct()
  )

  private _prodWithErrors$ = this.prodId$.pipe(
    switchMap(id => this.getProd(id)),
    shareReplay(1)
  )

  prod$ = this._prodWithErrors$.pipe(
    catchError(() => EMPTY),
  )

  prodError$ = this._prodWithErrors$.pipe(
    ignoreElements(),
    catchError((err) => of(err)),
  )

  prod = toSignal(this.prod$)
  gallery = computed(() => getProductGallery(this.prod()))
  docUrls = computed(() => this.prod()?.documentUrls ?? [])
  videoLinks = computed(() => getProductVideoLinks(this.prod()))


  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  constructor(httpService: ProductCustomerService) {
    super(httpService)

  } //ctor

  //-------------------------------------------------------//

  override refreshDataIfNecessary(): void {

    if (!this.totalItems())
      this.getPagePackage(PagedRequest.Empty())

  }

  //-------------------------------------------------------//

  private getProd(id: number): Observable<Product | undefined> {

    const PROD_KEY = makeStateKey<Product>(`prod-${id}-key`)

    if (this._transferState.hasKey(PROD_KEY)) {
      const prod = this._transferState.get(PROD_KEY, undefined);
      console.log('resolve', PROD_KEY, prod)
      this._transferState.remove(PROD_KEY)
      return of(prod)
    }

    return this.get(id).pipe(
      catchError(err => isPlatformServer(this._platformId) ? EMPTY : throwError(() => err)),
      tap(prod => {
        if (isPlatformServer(this._platformId) && !!prod)
          this._transferState.set(PROD_KEY, prod)
      })
    )

  } //resolve

  //--------------------------------------------------------//

} //Cls
