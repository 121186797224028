import { Injectable, inject } from '@angular/core';
import { RoleNamesService } from '../role-names/role-names.service';
import { Role } from '@inigo/acc-n-auth-data/roles';

@Injectable({
  providedIn: 'root',
})
export class RolesService {

  private _roleNames = inject(RoleNamesService)

  //---------------------------------------------------------------------------------------------//

  //Use a map incase there's loads of roles.
  private _rolesMap: Map<string, Role> = new Map<string, Role>();

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- SUPER TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  //---------------------------------------------------------------------------------------------//

  /** User with access to everything    */
  SUPER_LEADER: Role = new Role(
    this._roleNames.superLeaderDisplay,
    this._roleNames.superLeaderFull,
    'Leader'
  );

  /** SuperUser's assistant. above everyone else   */
  SUPER_ADMIN: Role = new Role(
    this._roleNames.superAdminDisplay,
    this._roleNames.superAdminFull,
    'Admin'
  );

  /** User with access to everything    */
  SUPER_MGR: Role = new Role(
    this._roleNames.superMgrDisplay,
    this._roleNames.superMgrFull,
    'Manager'
  );

  /** User with access to everything    */
  SUPER_USER: Role = new Role(
    this._roleNames.superUserDisplay,
    this._roleNames.superUserFull,
    'User'
  );

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= MAINTENANCE TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-//
  //---------------------------------------------------------------------------------------------//

  /** User with access to everything    */
  MAINTENANCE_LEADER: Role = new Role(
    this._roleNames.maintenanceLeaderDisplay,
    this._roleNames.maintenanceLeaderFull,
    'Leader'
  );

  /** SuperUser's assistant. above everyone else   */
  MAINTENANCE_ADMIN: Role = new Role(
    this._roleNames.maintenanceAdminDisplay,
    this._roleNames.maintenanceAdminFull,
    'Admin'
  );

  /** User with access to everything    */
  MAINTENANCE_MGR: Role = new Role(
    this._roleNames.maintenanceMgrDisplay,
    this._roleNames.maintenanceMgrFull,
    'Manager'
  );

  /** User with access to everything    */
  MAINTENANCE_USER: Role = new Role(
    this._roleNames.maintenanceUserDisplay,
    this._roleNames.maintenanceUserFull,
    'User'
  );

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= CUSTOMER TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  //---------------------------------------------------------------------------------------------//

  /** User with access to everything    */
  CUSTOMER_LEADER: Role = new Role(
    this._roleNames.customerLeaderDisplay,
    this._roleNames.customerLeaderFull,
    'Leader'
  );

  /** SuperUser's assistant. above everyone else   */
  CUSTOMER_ADMIN: Role = new Role(
    this._roleNames.customerAdminDisplay,
    this._roleNames.customerAdminFull,
    'Admin'
  );

  /** User with access to everything    */
  CUSTOMER_MGR: Role = new Role(
    this._roleNames.customerMgrDisplay,
    this._roleNames.customerMgrFull,
    'Manager'
  );

  /** User with access to everything    */
  CUSTOMER_USER: Role = new Role(
    this._roleNames.customerUserDisplay,
    this._roleNames.customerUserFull,
    'User'
  );

  /** User with access to everything    */
  CUSTOMER_GUEST: Role = new Role(
    this._roleNames.customerUserDisplay,
    this._roleNames.customerUserFull,
    'Guest'
  );

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- POSITION IN TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  //---------------------------------------------------------------------------------------------//

  /** User with access to everything    */
  LEADER: Role = new Role(
    this._roleNames.leaderDisplay,
    this._roleNames.leaderFull,
    'Leader'
  );

  /**  Administrator of users of service. Creates new user, changes roles etc.    */
  ADMIN: Role = new Role(
    this._roleNames.adminDisplay,
    this._roleNames.adminFull,
    'Admin'
  );

  /* Manager of employee. */
  MGR: Role = new Role(
    this._roleNames.mgrDisplay,
    this._roleNames.mgrFull,
    'Manager'
  );

  /** Regular user    */
  USER: Role = new Role(
    this._roleNames.userDisplay,
    this._roleNames.userFull,
    'User'
  );

  /** Temporary limited access. */
  GUEST: Role = new Role(
    this._roleNames.guestDisplay,
    this._roleNames.guestFull,
    'Guest'
  );

  //---------------------------------------------------------------------------------------------//
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= TEAMS =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  //---------------------------------------------------------------------------------------------//

  /** Regular worker. */
  SUPER: Role = new Role(
    this._roleNames.superDisplay,
    this._roleNames.superFull
  );

  /** Regular worker. */
  MAINTENANCE: Role = new Role(
    this._roleNames.maintenanceDisplay,
    this._roleNames.maintenanceFull
  );

  /** Regular worker. */
  CUSTOMER: Role = new Role(
    this._roleNames.customerDisplay,
    this._roleNames.customerFull
  );

  //---------------------------------------------------------------------------------------------//

  constructor() {
    this.populateMap();
  }

  //---------------------------------------------------------------------------------------------//

  findRole(roleName: string): Role | null {

    if (!this._rolesMap.has(roleName))
      return null

    return this._rolesMap.get(roleName) ?? null

  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  findRoles(roleNames?: string[]): Role[] {

    const roles: Role[] = []
    if (!roleNames?.length)
      return roles

    roleNames.forEach((rn) => {
      const role = this.findRole(rn)
      if (role) roles.push(role);
    });

    return roles

  }

  //---------------------------------------------------------------------------------------------//

  /**
   * Calculates the Role with the highest position from roles array and return it
   */
  filterHighestPosition(roles: Role[]): Role | null {

    if (!roles?.length)
      return null

    const filteredRoles = roles
      .filter((n) => n)
      .sort((r1, r2) => r1.comparePosition(r2));

    return filteredRoles.pop() ?? null

  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Calculates the Role with the highest position from roles array and return it
   */
  filterLowestPosition(roles: Role[]): Role | null {

    if (!roles?.length) return null;

    const sortedRoles = roles
      .filter((n) => n)
      .sort((r1, r2) => r1.comparePosition(r2));

    if (!sortedRoles?.length) return null;

    return sortedRoles[0]

  }

  //---------------------------------------------------------------------------------------------//

  getHighestRole = (roles: Role[]): Role | null => this.filterHighestPosition(roles)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  getLowestRole = (roles: Role[]): Role | null => this.filterLowestPosition(roles)

  //---------------------------------------------------------------------------------------------//

  private populateMap() {

    //=-=-=-=-=-=-=-=-=-=-=-=-=-=- SUPER TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-=-=//

    this._rolesMap.set(this._roleNames.superLeaderFull, this.SUPER_LEADER);
    this._rolesMap.set(this._roleNames.superLeaderDisplay, this.SUPER_LEADER);

    this._rolesMap.set(this._roleNames.superAdminFull, this.SUPER_ADMIN);
    this._rolesMap.set(this._roleNames.superAdminDisplay, this.SUPER_ADMIN);

    this._rolesMap.set(this._roleNames.superMgrFull, this.SUPER_MGR);
    this._rolesMap.set(this._roleNames.superMgrDisplay, this.SUPER_MGR);

    this._rolesMap.set(this._roleNames.superUserFull, this.SUPER_USER);
    this._rolesMap.set(this._roleNames.superUserDisplay, this.SUPER_USER);

    //=-=-=-=-=-=-=-=-=-=-=-=-= MAINTENANCE TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-//

    this._rolesMap.set(this._roleNames.maintenanceLeaderFull, this.MAINTENANCE_LEADER);
    this._rolesMap.set(this._roleNames.maintenanceLeaderDisplay, this.MAINTENANCE_LEADER);

    this._rolesMap.set(this._roleNames.maintenanceAdminFull, this.MAINTENANCE_ADMIN);
    this._rolesMap.set(this._roleNames.maintenanceAdminDisplay, this.MAINTENANCE_ADMIN);

    this._rolesMap.set(this._roleNames.maintenanceMgrFull, this.MAINTENANCE_MGR);
    this._rolesMap.set(this._roleNames.maintenanceMgrDisplay, this.MAINTENANCE_MGR);

    this._rolesMap.set(this._roleNames.maintenanceUserFull, this.MAINTENANCE_USER);
    this._rolesMap.set(this._roleNames.maintenanceUserDisplay, this.MAINTENANCE_USER);

    //=-=-=-=-=-=-=-=-=-=-=-=-= CUSTOMER TEAM =-=-=-=-=-=-=-=-=-=-=-=-=-//

    this._rolesMap.set(this._roleNames.customerLeaderFull, this.CUSTOMER_LEADER);
    this._rolesMap.set(this._roleNames.customerLeaderDisplay, this.CUSTOMER_LEADER);

    this._rolesMap.set(this._roleNames.customerAdminFull, this.CUSTOMER_ADMIN);
    this._rolesMap.set(this._roleNames.customerAdminDisplay, this.CUSTOMER_ADMIN);

    this._rolesMap.set(this._roleNames.customerMgrFull, this.CUSTOMER_MGR);
    this._rolesMap.set(this._roleNames.customerMgrDisplay, this.CUSTOMER_MGR);

    this._rolesMap.set(this._roleNames.customerUserFull, this.CUSTOMER_USER);
    this._rolesMap.set(this._roleNames.customerUserDisplay, this.CUSTOMER_USER);

    //=-=-=-=-=-=-=-=-=-=-=-=-=- POSITION IN TEAM =-=-=-=-=-=-=-=-=-=-=-=-=//

    this._rolesMap.set(this._roleNames.leaderFull, this.LEADER);
    this._rolesMap.set(this._roleNames.leaderDisplay, this.LEADER);

    this._rolesMap.set(this._roleNames.adminFull, this.ADMIN);
    this._rolesMap.set(this._roleNames.adminDisplay, this.ADMIN);

    this._rolesMap.set(this._roleNames.mgrFull, this.MGR);
    this._rolesMap.set(this._roleNames.mgrDisplay, this.MGR);

    this._rolesMap.set(this._roleNames.userFull, this.USER);
    this._rolesMap.set(this._roleNames.userDisplay, this.USER);

    this._rolesMap.set(this._roleNames.guestFull, this.GUEST);
    this._rolesMap.set(this._roleNames.guestDisplay, this.GUEST);

    //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= TEAMS =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//

    this._rolesMap.set(this._roleNames.superDisplay, this.SUPER);
    this._rolesMap.set(this._roleNames.superFull, this.SUPER);

    this._rolesMap.set(this._roleNames.maintenanceDisplay, this.MAINTENANCE);
    this._rolesMap.set(this._roleNames.maintenanceFull, this.MAINTENANCE);

    this._rolesMap.set(this._roleNames.customerDisplay, this.CUSTOMER);
    this._rolesMap.set(this._roleNames.customerFull, this.CUSTOMER);
  } //populateMap

  
} //Cls
