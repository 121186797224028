import { InjectionToken } from '@angular/core'


export const AccountFeaturesConfigService = new InjectionToken<AccountFeaturesConfig>("AccountFeaturesConfig")


export class AccountFeaturesConfig {

    private _useLoadingInterceptor = true
    /** Whether to automatically use a spinner eveerytime we make a http request */
    public get useLoadingInterceptor(): boolean { return this._useLoadingInterceptor }

    private _afterLoginRoute = 'home'
    /** Where to go after login (relative to current url parent) - default: 'home' */
    public get afterLoginRoute(): string { return `${this._afterLoginRoute ?? ''}` }

    private _colorBgLeft?: string
    /** LHS background color - default '--colorPrimary' */
    public get colorBgLeft(): string { return `${this._colorBgLeft ?? ''}` }

    private _colorBgRight?: string
    /** RHS background color - default '--colorPrimaryLight' */
    public get colorBgRight(): string { return `${this._colorBgRight ?? ''}` }

    private _emailConfirmationCardTitle = 'Confirmed'
    /** Title on email confirmed screen (maybe company/app name) - default 'Confirmed' */
    public get emailConfirmationCardTitle(): string { return `${this._emailConfirmationCardTitle ?? ''}` }

    private _emailConfirmationCardMessage = 'You can now login to our Mobile App or the Web Version'
    /** Message on email confirmed screen default 'You can now login to our Mobile App or the Web Version' */
    public get emailConfirmationCardMessage(): string { return `${this._emailConfirmationCardMessage ?? ''}` }

    private _welcomeImgUrl = '/assets/images/welcome/welcome.jpg'
    /** The image to show on the Email Confirmed card */
    public get welcomeImgUrl(): string { return `${this._welcomeImgUrl ?? ''}` }
    
    private _includeCustomerAdmin = false
    /** Enable management of customer teams. (Multiple user per account) - default = false */
    public get includeCustomerAdmin(): boolean { return this._includeCustomerAdmin }

    private _includeCustomerTeams = false
    /** Enable management of customer teams. (Multiple user per account) - default = false */
    public get includeCustomerTeams(): boolean { return this._includeCustomerTeams }

    private _includeDeviceMgmt = false
    /** Enable management of user devices - default = false */
    public get includeDeviceMgmt(): boolean { return this._includeDeviceMgmt }

    private _defaultDeviceLimit = 0
    /** What will most device limits be. Default = 0 = unlimited */
    public get defaultDeviceLimit(): number { return this._defaultDeviceLimit }

    private _includeAppMgmt = false
    /** Enable management of user apps. Only necessary when user can subscribee to multiple apps. - default = false */
    public get includeAppMgmt(): boolean { return this._includeAppMgmt }

    private _includeSubMgmt = false
    /** Enable management of user subscriptions - default = false */
    public get includeSubMgmt(): boolean { return this._includeSubMgmt }

    private _include2FOptions = true
    /**Show 2FA mgmt info */
    public get include2FOptions(): boolean { return this._include2FOptions }

    private _canEditSelf2f = true
    /**Allow user to change own 2-Factor settings */
    public get canEditSelf2f(): boolean { return this._canEditSelf2f }


    /** Set bg to transparent and use the Bg of the parent component */
    public get transparentBg(): boolean { return this._colorBgLeft?.toLowerCase() === 'transparent' && this._colorBgRight?.toLowerCase() === 'transparent' }




    //---------------------------------------------------------------------//

    /**
     * Enter on paramaters to use default values on everything
     * @param emailConfirmationCardTitle Title on email confirmed screen (maybe company/app name) - default 'Confirmed'
     * @param emailConfirmationCardMessage Message on email confirmed screen default 'You can now login to our Mobile App or the Web Version'
     * @param colorBgLeft LHS background color - default colorPrimary
     * @param colorBgRight RHS background color - default colorPrimaryLight
     * @param afterLoginRoute Where to go after login if no redirect url has been provided - default: '/home'
     * @param useLoadingInterceptor Whether to automatically use a spinner eveerytime we make a http request - default true
     * @param welcomeImgUrl The image to show on the Email Confirmed card - default: '/assets/images/welcome/welcome.jpg'
     * @param includeDeviceMgmt Enable management of user devices - default = false
     */
    private constructor(
        emailConfirmationCardTitle = null,
        emailConfirmationCardMessage = null,
        colorBgLeft = null,
        colorBgRight = null,
        afterLoginRoute?: string,
        useLoadingInterceptor: boolean = true,
        welcomeImgUrl?: string,
        includeDeviceMgmt: boolean = false
    ) {

        if (emailConfirmationCardTitle)
            this._emailConfirmationCardTitle = emailConfirmationCardTitle
        
        if (emailConfirmationCardMessage)
            this._emailConfirmationCardMessage = emailConfirmationCardMessage

        if (afterLoginRoute)
            this._afterLoginRoute = afterLoginRoute

        if (colorBgLeft)
            this._colorBgLeft = colorBgLeft
        if (colorBgRight)
            this._colorBgRight = colorBgRight

        if (useLoadingInterceptor)
            this._useLoadingInterceptor = useLoadingInterceptor

        if (welcomeImgUrl)
            this._welcomeImgUrl = welcomeImgUrl

        if (includeDeviceMgmt)
            this._includeDeviceMgmt = includeDeviceMgmt

    }//ctor

    //---------------------------------------------------------------------//    

    /**
     * Create new instance of AccountFeaturesConfig with default settings
     */
    static Create(): AccountFeaturesConfig {

        return new AccountFeaturesConfig()

    }//create

    //---------------------------------------------------------------------//    

    /**
     * @param useLoadingInterceptor Whether to automatically use a spinner eveerytime we make a http request - default true
     * @returns Updated AccountFeaturesConfig
     */
    setUseLoadingInterceptor(useLoadingInterceptor: boolean): AccountFeaturesConfig {
        this._useLoadingInterceptor = useLoadingInterceptor
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//   

    /**
     * @param canEditSelf2f Allow user to change own 2-Factor settings. Default = true
     * @returns Updated AccountFeaturesConfig
     */
    setCanEditSelf2f(canEditSelf2f: boolean): AccountFeaturesConfig {
        this._canEditSelf2f = canEditSelf2f
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//   

    /**
     * @param includeAppMgmt  Enable management of user apps. Only necessary when user can subscribee to multiple apps. - default = false
     * @returns Updated AccountFeaturesConfig
     */
    setIncludeAppMgmt(includeAppMgmt: boolean): AccountFeaturesConfig {
        this._includeAppMgmt = includeAppMgmt
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//  

    /**
     * @param includeDeviceMgmt  Enable management of user devices - default = false
     * @param defaultDeviceLimit  The usual device limit for most people. 0 = unlimited.
     * @returns Updated AccountFeaturesConfig
     */
    setIncludeDeviceMgmt(includeDeviceMgmt: boolean, defaultDeviceLimit: number = 0): AccountFeaturesConfig {
        this._includeDeviceMgmt = includeDeviceMgmt
        this._defaultDeviceLimit = defaultDeviceLimit
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//    

    /**
     * @param includeSubMgmt  Enable management of user subscriptions. Only necessary when user can subscribee to multiple apps. - default = false
     * @returns Updated AccountFeaturesConfig
     */
    setIncludeSubscriptionMgmt(includeSubMgmt: boolean): AccountFeaturesConfig {
        this._includeSubMgmt = includeSubMgmt
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -// 

    /**
     * @param include2FOptions Show 2FA mgmt info. Default = true
     * @returns Updated AccountFeaturesConfig
     */
    setInclude2FOptions(include2FOptions: boolean): AccountFeaturesConfig {
        this._include2FOptions = include2FOptions
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//   

    /**
     * @param includeCustomerAdmin Can view customers
     * @returns Updated AccountFeaturesConfig
     */
    setIncludeCustomerAdmin(includeCustomerAdmin: boolean): AccountFeaturesConfig {
        this._includeCustomerAdmin = includeCustomerAdmin
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//   

    /**
     * @param includeCustomerTeams Customers will all be in some team
     * @returns Updated AccountFeaturesConfig
     */
    setIncludeCustomerTeams(includeCustomerTeams: boolean): AccountFeaturesConfig {
        this._includeCustomerTeams = includeCustomerTeams
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//   

    /**
     * @param afterLoginRoute Where to go after login if no redirect url has been provided - default: '/home'
     * @returns Updated AccountFeaturesConfig
     */
    setAfterLoginRoute(afterLoginRoute: string): AccountFeaturesConfig {
        this._afterLoginRoute = afterLoginRoute
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//  

    /**
     * @param colorBgLeft LHS background color - default colorPrimary
     * @returns Updated AccountFeaturesConfig
     */
    setColorBgLeft(colorBgLeft: string): AccountFeaturesConfig {
        this._colorBgLeft = colorBgLeft
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -// 

    /**
     * @param colorBgRight RHS background color - default colorPrimaryLight
     * @returns Updated AccountFeaturesConfig
     */
    setColorBgRight(colorBgRight: string): AccountFeaturesConfig {
        this._colorBgRight = colorBgRight
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//   

    /**
     * @param emailConfirmationCardTitle Title on email confirmed screen (maybe company/app name) - default 'Confirmed'
     * @returns Updated AccountFeaturesConfig
     */
    setEmailConfirmationCardTitle(emailConfirmationCardTitle: string): AccountFeaturesConfig {
        this._emailConfirmationCardTitle = emailConfirmationCardTitle
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//  

    /**
     * @param emailConfirmationCardMessage Message on email confirmed screen default 'You can now login to our Mobile App or the Web Version'
     * @returns Updated AccountFeaturesConfig
     */
    setEmailConfirmationCardMessage(emailConfirmationCardMessage: string): AccountFeaturesConfig {
        this._emailConfirmationCardMessage = emailConfirmationCardMessage
        return this
    }

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//  

    /**
     * @param welcomeImgUrl The image to show on the Email Confirmed card - default: '/assets/images/welcome/welcome.jpg'
     * @returns Updated AccountFeaturesConfig
     */
    setWelcomeImgUrl(welcomeImgUrl: string): AccountFeaturesConfig {
        this._welcomeImgUrl = welcomeImgUrl
        return this
    }

    //---------------------------------------------------------------------//    


}//Cls