import { Observable, combineLatest, fromEvent, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { FilterDataType } from '../models/filter-data-types';
import { FilterRequest } from '../models/filter-request';
import { FilterType, FilterTypes } from '../models/filter-types';

//====================================================================//

/**Prefix for the id of the text-input - set html element to INPUT_PREFIX + columnName */
export const CF_INPUT_FILTER_PREFIX = 'input-filter-'
/**Prefix for the id of the text-input - set html element to INPUT_PREFIX + columnName */
export const CF_FILTER_TYPE_PREFIX = 'filter-type-'
/**Prefix for the id of the clear button  - set html element to BUTTON_CLEAR_PREFIX + columnName */
// export const CF_BUTTON_CLEAR_PREFIX = 'button-clear-filter-'

const DEFAULT_MIN_TEXT_LENGTH = 2

//====================================================================//

/**
 * Class for encapsulating the filtering of a table column.
 * Html id for filter type should be CF_FILTER_TYPE_PREFIX + columnName.
 *
 * How it works:
 * Send in the column/field/property name and the class will find the
 * corresponding input and advanced filter dropdown in the DOM.
 * Alternatively use the static method, generateColumnFilterArray to send in an array of column names
 * and get an array of Column filters returned.
 *
 * Then call call asObservale on the object to get an observable that will send out an FilterRequest when
 * a text or button event occurs.
 */
export class ColumnFilter {

  private _input: HTMLInputElement
  private _selectFilterType: HTMLSelectElement | HTMLInputElement
  private _columnName: string
  private _filterDataType: FilterDataType
  /**True when it's a dropdown/select */
  private _isListFilter: boolean = false

  //Used when _selectFilterType is NOT being used
  private _defaultFilterType?: FilterType// = 'equals'

  private _minTxtLength = DEFAULT_MIN_TEXT_LENGTH

  //------------------------------------------------------------------//

  /**
   * @param columnName The name of the column/property getting filtered
   * @param filterDataType What type of data are we filtering ('string' | 'number' | 'date' | 'boolean' ). Default = 'string'
   * @param isListFilter Is it a list filter (selectable options).  (This will affect the FilterType and min text length to trigger events).
   *
   * True when it's a dropdown/select.
   * Default = false
   * @param uniqueSuffix Something to distinguish columns if multiple tables are on the same page
   */
  constructor(
    columnName: string,
    filterDataType: FilterDataType = 'string',
    isListFilter = false,
    uniqueSuffix = ''
  ) {

    this._input = document.getElementById(CF_INPUT_FILTER_PREFIX + columnName + uniqueSuffix) as HTMLInputElement
    this._selectFilterType = document.getElementById(CF_FILTER_TYPE_PREFIX + columnName + uniqueSuffix) as HTMLSelectElement

    this._columnName = columnName
    this._filterDataType = filterDataType
    this._isListFilter = isListFilter

  } //ctor

  //------------------------------------------------------------------//

  getColumnName = (): string => `${this._columnName}`//Send a copy
  getFilterText = (): string => `${this._input}` //Send a copy

  //----------------------------------//

  /**
   * Create an Observable of FilterRequest that reacts to changes in the ColumnFilter (button & text)
   * @param onChange What do do when the filter changes
   * @param minTextLength how many characters should we have before notifying observers
   */
  asObservable(minTextLength: number = -1): Observable<FilterRequest> {

    if (minTextLength < 0)
      minTextLength = this.calculateMinTextLength()

    //This will not be available if the user leaves the page before it's set up.
    //This should be caught in Development and ignored in Production
    if (!this._input)
      return of(new FilterRequest('', ''))

    const inputChange$ = this.getInputChange$(this._input)
    const inputTypeChange$ = this.getInputFilterType$(this._selectFilterType)

    return combineLatest([inputChange$, inputTypeChange$]).pipe(
      map(([filterValue, filterType]) => this.toFilterRequest(filterValue, filterType)),
      filter(request => this.isValidRequest(request, minTextLength)),
      catchError(e => {
        console.log(e)
        return of(new FilterRequest('', '')) //Send out a blank one
      }),
      debounceTime(250),
    )

  }

  //------------------------------------------------------------------//

  /**
   * Set the type of filter that will be used on this value  (EQUALS , STARTS_WITH, CONTAINS, etc.)
   * Use this when NOT using a dropdown to choose the filterType
   * @param filterType
   */
  setFilterType(filterType?: FilterType): ColumnFilter {

    this._defaultFilterType = filterType ?? 'equals'
    if (this._selectFilterType)
      this._selectFilterType.value = this._defaultFilterType
    return this

  }

  //------------------------------------------------------------------//

  /**
   * How many characters should be typed to trigger observable.
   * If number or list 0 will be used.
   * Default = 2
   * @param filterType
   */
  setTriggerMinTextLength(minTextLength?: number): ColumnFilter {

    this._minTxtLength = minTextLength ?? DEFAULT_MIN_TEXT_LENGTH
    return this

  }

  //------------------------------------------------------------------//

  private calculateMinTextLength = (): number =>
    this._filterDataType === 'number' || this._isListFilter
      ? 0
      : this._minTxtLength

  //------------------------------------------------------------------//

  /**
   * Generate an observable that reacts to text change
   * @param minTextLength how many characters should we have before notifying observers
   */
  private getInputChange$(input: HTMLInputElement): Observable<string> {

    return fromEvent(input, 'input')
      .pipe(
        startWith(''), //start combine latest
        map(ev => input?.value ?? ''), //Most current value
        map(value => value.trim()),
        distinctUntilChanged()
      )
  }

  //------------------------------------------------------------------//

  /**
   * Generate an observable that reacts to text change
   */
  private getInputFilterType$(selectFilterType: HTMLSelectElement | HTMLInputElement): Observable<FilterType> {

    if (!selectFilterType)
      return of(FilterTypes.fromString(this._defaultFilterType)) //Will default to equals

    //'input' applies to HtmlSelect and HtmlInput
    return fromEvent(selectFilterType, 'input')
      .pipe(
        startWith(undefined), //start combine latest
        map(ev => selectFilterType.value),//Most current value
        map(val => FilterTypes.fromString(val)),
        distinctUntilChanged(),
      )

  }

  //------------------------------------------------------------------//

  private isValidRequest = (request: FilterRequest, minTextLength: number) =>
    request.filterValue.length >= minTextLength
    ||
    request.filterValue.length === 0 // === 0 to handle clear filter
    ||
    this.isNumber(request.filterValue)
    ||
    this.isBoolean(request.filterValue)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  private isNumber = (value: any) => typeof value === 'number' && isFinite(value);

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  private isBoolean = (value: any) => typeof value === 'boolean'

  //------------------------------------------------------------------//

  /**
   * Get a new FilterRequest that represents the current state
   */
  private toFilterRequest(filterValue: string, filterType: string): FilterRequest {

    return new FilterRequest(
      this._columnName,
      this.getFilterValue(filterValue),
      FilterTypes.fromString(filterType ?? this._defaultFilterType),
      this._filterDataType
    )

  }

  //------------------------------------------------------------------//

  private getFilterValue(value: string) {

    if (!value)
      return value

    if (this.isNumberInput())
      return +(this._input.value)

    if (this.isBooleanInput())
      return this.getBooleanValue()

    return this._input.value?.trim()

  }

  //------------------------------------------------------------------//

  private isNumberInput = () => this._input.type === 'number'

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //


  private isBooleanInput = () =>
    this._filterDataType === 'boolean' || this._input.type === 'checkbox'

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  private getBooleanValue(): any {

    const value = this._input.value
    if (typeof value == "boolean")
      return value
    if (typeof value == "number")   //Server might expect 1 or 0
      return value

    const lwrValue = `${this._input.value.toLowerCase()}`
    if (lwrValue === 'true')
      return true
    if (lwrValue === 'false')
      return false

    return value
  }

  //------------------------------------------------------------------//

} //Cls
