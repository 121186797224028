import { InjectionToken, Type } from '@angular/core';

export const CustomSpinnerConfigService = new InjectionToken<PopUpCustomSpinnerConfig>('PopUpCustomSpinnerConfig');

export class PopUpCustomSpinnerConfig {


  /**Custom Spinner */
  public get spinnerComponent(): Type<any> {
    return this._spinnerComponent
  }

//--------------------------------------------//

  private constructor(private _spinnerComponent: Type<any>) { }

  //--------------------------------------------//

  /**
   * Create new instance of PopUpCustomSpinnerConfig
   * @param spinnerComponent Customer Spinner
   */
  static Create(spinnerComponent: Type<any>): PopUpCustomSpinnerConfig {
    return new PopUpCustomSpinnerConfig(spinnerComponent)
  } //create

  //--------------------------------------------//


  /**
   * @param spinnerComponent Customer Spinner
   * @returns Updated PopUpCustomSpinnerConfig
   */
  setSpinnerComponent(spinnerComponent: Type<any>): PopUpCustomSpinnerConfig {
    this._spinnerComponent = spinnerComponent;
    return this;
  }

  //--------------------------------------------//

} //Cls
