import { Injectable } from '@angular/core';
import { MyBS } from '@inigo/gen-helpers/classes';
import { Observable } from 'rxjs';
import { DimensionDefinition } from '../../../dtos/dimension-definition';
import { MeasurementDef } from '../../../dtos/measurementDef';
import { DimensionHttpService } from '../../http/dmn/dimens.service';
import { APagePkgStoreService } from '@inigo/my-store';
import { DimensionDefPagePackage } from '../../../dtos/dimen-def-page-package';
import { PagedRequest } from '@inigo/pagination';

@Injectable({
  providedIn: 'root',
})
export class DimensionStoreService extends APagePkgStoreService<DimensionDefinition, DimensionDefinition, DimensionDefPagePackage> {

  private _measurementDefsBs: MyBS<MeasurementDef[]> = new MyBS<MeasurementDef[]>()
  measurementDefs$: Observable<MeasurementDef[]> = this._measurementDefsBs.obs$;

  //-------------------------------------------------------//

  constructor(_httpService: DimensionHttpService) {
    super(_httpService)

    this.setItemTypeName('Dimension')
    this.setItemNameLamda((p) => p.name ?? `Dimension ${p.id}`);

  } //ctor

  //-------------------------------------------------------//

  protected override handlePagePackageResponse(pkg: DimensionDefPagePackage): void {
    super.handlePagePackageResponse(pkg);

    this._measurementDefsBs.next(pkg.measurements);
  } //handlePagePackageResponse

  //-------------------------------------------------------//

  override refreshDataIfNecessary(): void {

    if (!this.totalItems())
      this.getPagePackage(PagedRequest.Empty())

  }

  //-------------------------------------------------------//
  
} //Cls
