import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Identifier } from '@inigo/data/misc';
import { MyBS } from '@inigo/gen-helpers/classes';
import { ArrayHelpers } from '@inigo/helpers/collections';
import { DefaultTemplateStoreService, SuccessInfo } from '@inigo/my-store';
import { PagedRequest } from '@inigo/pagination';
import { NotFoundError, Observable, ReplaySubject, Subscription, combineLatest, finalize, map } from 'rxjs';
import { Brand } from '../../../dtos/brand';
import { BulkUploadDto } from '../../../dtos/bulk-upload';
import { Product } from '../../../dtos/product';
import { ProductDocumentDeleteDto } from '../../../dtos/product-document-delete-dto';
import { ProductMntcService } from '../../http/prod/prod-mntc.service';
import { BrandStoreService } from '../brnd/brand-store.service';
import { AProdStoreService } from './a-prod-store.service';

@Injectable({
  providedIn: 'root'
})
export class ProdStoreMntcService extends AProdStoreService {

  private _brandStore = inject(BrandStoreService)
  private _templateStore: DefaultTemplateStoreService

  //-------------------------------------------------------//

  protected _currentItemMntcBs = new MyBS<Product | undefined>()
  private _getSubMntc?: Subscription
  currentItemMntc$: Observable<Product | undefined> = this._currentItemMntcBs.obs$

  private _pkgBrands$ = this.package$
    .pipe(map(pkg => pkg.brands))

  private _httpService: ProductMntcService

  protected _templateBs = new ReplaySubject<Blob>(1)
  /**Associated File (example: Excel) */
  template$: Observable<Blob> = this._templateBs.asObservable()

  //-------------------------------------------------------//

  constructor(httpService: ProductMntcService) {
    super(httpService)
    this._httpService = httpService
    this._templateStore = DefaultTemplateStoreService.Create(
      httpService.url,
      this._templateBs,
      this._isLoadingBs,
      this._errorSb,
      this._successSb
    )



    // merge(this._pkgBrands$, this._brandStore.filteredData$)
    //   .pipe(takeUntilDestroyed())
    combineLatest([this._pkgBrands$, this._brandStore.filteredData$]).pipe(
      takeUntilDestroyed(),
      map(([bs1, bs2]) => ArrayHelpers.mergeUnique(bs1, bs2, (b1, b2) => b1.id === b2.id)),
    )
      .subscribe(brands =>
        this._brandsBs.next(brands)
      )


  } //ctor

  //-------------------------------------------------------//

  /**
   * Add this Brand to the arrays and emit them to any observers
   * @param brnd The new Brand
   */
  addBrand(brnd?: Brand): void {

    if (!brnd)
      return

    const currentBrands = this._brandsBs.getValue() ?? []
    // console.log('addBrand', currentBrands, brnd,);

    const newBrands = ArrayHelpers.insertInOrder<Brand>(
      currentBrands,
      brnd,
      (c1, c2) => (c1.name ?? '').localeCompare(c2.name ?? '') > 0
    )
    this._brandsBs.next(newBrands)

  }//addBrand

  //-------------------------------------------------------//

  appendGallery(gallery: File[]) {

    if (!gallery?.length) return;

    const prodId = this._currentItemMntcBs.getValue()?.id;

    this._productService.appendGallery(prodId, gallery).subscribe({
      next: (prod) => {
        this._prodGalleryUpdatedBs.next(prod);
        this._successSb.next(
          SuccessInfo.Other(`${gallery.length} images added to gallery!`)
        );
      },
      error: (error) => this._errorSb.next(error),
    });
  } //addBulk

  //-------------------------------------------------------//

  addBulk(dto: BulkUploadDto, filename: string = 'upload_report.txt') {

    this._productService.addBulk(dto).subscribe({
      next: (response) => {
        this._blobHandler.download(response, filename);
        this._successSb.next(
          SuccessInfo.Other(
            `Upload complete. Extra info wil be in downloaded file, "${filename}"`
          )
        );
      },
      error: (error) => this._errorSb.next(error),
    })

  } //addBulk

  //-------------------------------------------------------//

  deleteDocs(dto: ProductDocumentDeleteDto) {

    this._productService.deleteDocs(dto).subscribe({
      next: (prod) => {
        this._successSb.next(SuccessInfo.Other('Documents Deleted!'));
      },
      error: (error) => this._errorSb.next(error),
    }); //subscribe

  } //deleteDocs

  //-------------------------------------------------------//

  deleteGalleryImages(ids: Identifier[]) {

    if (!ids?.length) return;

    const dto = new ProductDocumentDeleteDto();
    dto.documentIds = ids;
    dto.ProductId = this._currentItemMntcBs.getValue()?.id;

    this._productService.deleteGalleryImages(dto).subscribe({
      next: (prod) => {
        this._prodGalleryUpdatedBs.next(prod);
        this._successSb.next(
          SuccessInfo.Other(this.deletedMessage(dto?.documentIds?.length ?? 0))
        );
      },
      error: (error) => this._errorSb.next(error),
    }); //subscribe

  } //deleteDocs

  //-------------------------------------------------------//

  private deletedMessage(deletedCount: number, type: string = 'image'): string {

    let prefix: string;
    if (deletedCount === 1) prefix = `1 ${type}`;
    else prefix = `${deletedCount} ${type}s `;

    return `${prefix} deleted.`

  } //deletedMessage

  //-------------------------------------------------------//

  getMntc(id: Identifier): Subscription {

    this._isLoadingBs.next(true)

    this._getSubMntc?.unsubscribe() //switchMap
    return (this._getSub = this._httpService
      .getMntc(id)
      .pipe(finalize(() => this._isLoadingBs.next(false)))
      .subscribe({
        next: (item: Product) => this._currentItemMntcBs.next(item),
        error: (error) => {
          if (error instanceof NotFoundError)
            this._currentItemMntcBs.next(undefined)
          this._errorSb.next(error)
        },
      })) //subscribe

  } //get

  //-------------------------------------------------------//

  getTemplate = (fileName: string = 'template.tmp', id?: Identifier): Observable<Blob> =>
    this._templateStore.getTemplate(fileName, id)

  //-------------------------------------------------------//

  override refreshDataIfNecessary(): void {

    if (!this.totalItems())
      this.getPagePackage(PagedRequest.Empty())

  }

  //-------------------------------------------------------//

  refreshDetails(id: Identifier) {
    this.getMntc(id)
    this._deptStore.filterData()
    this._brandStore.filterData()
    this._msrDefsStore.filterData()
  }

  //-------------------------------------------------------//

} //Cls
