import { InjectionToken } from '@angular/core';

//===================================================================//

export const HttpServicesConfigService =  new InjectionToken<HttpServicesConfig>('HttpServicesConfig');

//===================================================================//

export class HttpServicesConfig {

  /** Where to send error reports - default: '/api/errors' */
  private _errorsUrl = '/api/errors'
  public get errorsUrl(): string { return `${this._errorsUrl}` }

  /** Which action on the errors endpoint to send website error reports - default: 'weberror' */
  private _errorsAction = 'weberror'
  public get errorsAction(): string { return `${this._errorsAction}` }

  /** Where to send error reports - default: '/api/logging' */
  private _loggingUrl = '/api/logging'
  public get loggingUrl(): string { return `${this._loggingUrl}` }

  /** Which action on the logging endpoint to send website error reports - default: 'weberror' */
  private _loggingAction = 'weblog'
  public get loggingAction(): string { return `${this._loggingAction}` }

  /** Base url for ssr and prerendering */
  private _ssrUrl = ''
  public get ssrUrl(): string { return `${this._ssrUrl}` }

  /** Base url for ssr and prerendering */
  private _customUrl? = ''
  public get customUrl(): string | undefined { return this._customUrl ?? undefined }

  //----------------------------------//

  /**
   * Set paramater to null to use default values.
   * Enter no paramaters to use default values on everything
   * @param errorsUrl Where to send error reports - default: '/api/errors'
   * @param errorsAction Which action on the errors endpoint to send website error reports - default: 'weberror'
   * @param loggingUrl Where to send error reports - default: '/api/logging'
   * @param loggingAction Which action on the logging endpoint to send website error reports - default: 'weblog'
   */
  private constructor(
    errorsUrl: string = '',
    errorsAction: string = '',
    loggingUrl: string = '',
    loggingAction: string = ''
  ) {
    if (errorsUrl) this._errorsUrl = errorsUrl

    if (errorsAction) this._errorsAction = errorsAction

    if (loggingUrl) this._loggingUrl = loggingUrl

    if (loggingAction) this._loggingAction = loggingAction
  } //ctor

  //----------------------------------//

  static Create(
    errorsUrl: string = '',
    errorsAction: string = '',
    loggingUrl: string = '',
    loggingAction: string = ''
  ) {
    
    return new HttpServicesConfig(errorsUrl, errorsAction, loggingUrl, loggingAction)
  }

  //----------------------------------//

  setSsrUrl(ssrUrl: string): HttpServicesConfig {
    this._ssrUrl = ssrUrl.replace(/\/+$/, '') //Remove last slash
    return this
  }

  //----------------------------------//

  setCustomUrl(customUrl: string | undefined): HttpServicesConfig {
    this._customUrl = customUrl?.replace(/\/+$/, '') //Remove last slash
    return this
  }

  //----------------------------------//

  setErrorsAction(errorsAction: string): HttpServicesConfig {
    this._errorsAction = errorsAction
    return this
  }

  //----------------------------------//

  setErrorsUrl(errorsUrl: string): HttpServicesConfig {
    this._errorsUrl = errorsUrl
    return this
  }

  //----------------------------------//

  setLoggingAction(loggingAction: string): HttpServicesConfig {
    this._loggingAction = loggingAction
    return this
  }

  //----------------------------------//

  setLoggingUrl(loggingUrl: string): HttpServicesConfig {
    this._loggingUrl = loggingUrl
    return this
  }

  //----------------------------------//



} //Cls
