import { InjectionToken } from '@angular/core'
import { StringHelpers } from '@inigo/helpers/text'

export const BgAngledSplitConfigService = new InjectionToken<BgAngledSplitConfig>("BgAngledSplitConfig")


export class BgAngledSplitConfig {

    private _colorLhs?: string
    /** Color for Left Hand Side of background - default colorPrimary*/
    public get colorLhs(): string { return  StringHelpers.Clone(this._colorLhs) }

    private _colorRhs?: string
    /** Color for Right Hand Side of background - default colorPrimaryLight */
    public get colorRhs(): string { return  StringHelpers.Clone(this._colorRhs) }

    //---------------------------------------------------------------------//

    /**
     * @param colorLhs Color for Left Hand Side of background - default colorPrimary
     * @param colorRhs Color for Right Hand Side of background - default colorPrimaryLight
     */
    private constructor(
        colorLhs?: string,
        colorRhs?: string
    ) {

        if (colorLhs)
            this._colorLhs = colorLhs
        if (colorRhs)
            this._colorRhs = colorRhs

    }//ctor

    //---------------------------------------------------------------------//      

    /**
     * Create new instance of BgAngledSplitConfig
     * @param colorLhs Color for Left Hand Side of background - default colorPrimary
     * @param colorRhs Color for Right Hand Side of background - default colorPrimaryLight
     */
    static Create(colorLhs?: string, colorRhs?: string): BgAngledSplitConfig {

        return new BgAngledSplitConfig(colorLhs, colorRhs)

    }//create

    //---------------------------------------------------------------------//    

}//Cls