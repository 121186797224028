import { EnvironmentProviders, Provider, Type } from "@angular/core";
import { RolesAuthConfig, RolesAuthConfigService } from "@inigo/authentication/config";
import { AuthGuardSetup, CannotActivateHandler } from "@inigo/authentication/guards";

export class AuthSetup {

    private providers: (Provider | EnvironmentProviders)[] = []

    //---------------------------------------------------------//

    static Create(config: RolesAuthConfig): AuthSetup {

        const authSetup = new AuthSetup()

        authSetup.providers.push(
            {
                provide: RolesAuthConfigService,
                useValue: config,
            }
        )

        return authSetup

    }
    //---------------------------------------------------------//

    setAuthGuardHandlerProviders(cannotActivateHandler: Type<CannotActivateHandler>) {

        this.providers.push(AuthGuardSetup.getProviders(cannotActivateHandler))
        return this

    }

    //---------------------------------------------------------//

    getProviders = (): (Provider | EnvironmentProviders)[] => this.providers

    //---------------------------------------------------------//

} //Cls