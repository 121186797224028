import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CannotActivateHandler, DefaultCannotActivateHandler } from './cannot-activate-handler/cannot-activate-handler.service';

/**
 * Guard that sends user to Login Page if canActivate = false
 */
export abstract class BaseAuthGuard {


  private _cannotActivateHandler = inject(CannotActivateHandler, { optional: true })
  private _defaultActivateHandler = inject(DefaultCannotActivateHandler)

  //---------------------------------------------//

  canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> =>
    this._canActivate(route, state)

  //---------------------------------------------//

  canActivateChild = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> =>
    this._canActivate(childRoute, state)

  //---------------------------------------------//

  private _canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (this.isValid())
      return true

    return (this._cannotActivateHandler ?? this._defaultActivateHandler).handle(childRoute, state)

  }

  //---------------------------------------------//

  abstract isValid(): boolean

} //Cls
