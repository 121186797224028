import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { debounceTime, filter, map } from 'rxjs';

@Component({
  selector: 'inigo-lazy-loading-indicator',
  templateUrl: './lazy-loading-indicator.component.html',
  styleUrls: ['./lazy-loading-indicator.component.scss'],
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyLoadingIndicatorComponent {

  private _router = inject(Router)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  _colorBg = input<string | undefined>(undefined, { alias: 'colorBg' })
  _colorTxt = input<string | undefined>(undefined, { alias: 'colorTxt' })
  _lazyRoutesToIgnore = input<string[] >([], { alias: 'lazyRoutesToIgnore' })

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  private _showIndicator$ = this._router.events
    .pipe(
      filter(event => event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof NavigationCancel),
      debounceTime(500),
      map((event) => event instanceof NavigationStart && !this.skipEvent(event)
      )
    )

  protected _showLazyLoadingIndicator = toSignal(this._showIndicator$)
  
  private _routePathsToIgnore =computed(() => {
    const ignoreSet =  new Set<string>()
    this._lazyRoutesToIgnore().forEach(uti => {
      ignoreSet.add(uti.replace(/^\/+/g, '').trim().toLowerCase())
    })
    return ignoreSet
  })
  

  //--------------------------------------------------------//

  /**
   * Should we skip the loader for this event
   */
  private skipEvent(routeEvent: NavigationStart): boolean {

    const url = routeEvent.url.replace(/^\/+/g, '').trim()?.toLowerCase()

    if (url && this._routePathsToIgnore().has(url))
      return true

    return false

  } //skipUrl

  //--------------------------------------------------------//
} //Cls
