import { Injectable, computed, effect, inject } from '@angular/core';
import { Claim, ClaimNames } from '@inigo/acc-n-auth-data/claims';
import { App, Device } from '@inigo/acc-n-auth-data/dtos';
import { Role, RoleNames } from '@inigo/acc-n-auth-data/roles';
import { BaseAuthSignalService } from '@inigo/auth-base';
import { RolesAuthConfig, RolesAuthConfigService } from '@inigo/authentication/config';
import { TimeInMillis } from '@inigo/helpers/date-time';
import { StringHelpers } from '@inigo/helpers/text';
import { JwtStorageService } from '@inigo/jwt';
import { TeamClaimNames } from './claim-names';
import { RolesService } from './roles/roles.service';
import { TeamTypes } from './teams';

@Injectable({
  providedIn: 'root',
})
export class AuthRolesService extends BaseAuthSignalService {


  private _config: RolesAuthConfig = inject(RolesAuthConfigService)
  private _roleService = inject(RolesService)
  private _jwtStore = inject(JwtStorageService)

  //------------------------------------------//

  isLoggedIn$ = computed(() => !!this.decodedToken$())
  isNotLoggedIn$ = computed(() => !this.isLoggedIn$())

  //------------------------------------------//

  roles$ = computed(() => this.extractRoles(this.allClaimsRecord$()))

  connectedApp$ = computed(() => this.parseAppClaim(this.allClaimsRecord$()))
  connectedApps$ = computed(() => this.parseAppListClaims(this.allClaimsRecord$()))

  devices$ = computed(() => this.parseDeviceListClaims(this.allClaimsRecord$()))
  deviceLimit$ = computed(() => this.getClaimValue(TeamClaimNames.DEVICE_LIMIT))


  //------------------------------TEAMS ------------------------------//

  isSpr$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.SUPER)
      ||
      this.roles$().has(this._roleService.SUPER_LEADER)
      ||
      this.roles$().has(this._roleService.SUPER_ADMIN)
      ||
      this.roles$().has(this._roleService.SUPER_MGR)
      ||
      this.roles$().has(this._roleService.SUPER_USER)
    ))

  isMntc$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.MAINTENANCE)
      ||
      this.roles$().has(this._roleService.MAINTENANCE_LEADER)
      ||
      this.roles$().has(this._roleService.MAINTENANCE_ADMIN)
      ||
      this.roles$().has(this._roleService.MAINTENANCE_MGR)
      ||
      this.roles$().has(this._roleService.MAINTENANCE_USER)
    ))

  isCus$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.CUSTOMER)
      ||
      this.roles$().has(this._roleService.CUSTOMER_LEADER)
      ||
      this.roles$().has(this._roleService.CUSTOMER_ADMIN)
      ||
      this.roles$().has(this._roleService.CUSTOMER_MGR)
      ||
      this.roles$().has(this._roleService.CUSTOMER_USER)
      ||
      this.roles$().has(this._roleService.CUSTOMER_GUEST)
    ))


  //------------------------ POSITION IN TEAM ------------------------//

  isLdr$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.LEADER)
      ||
      this.roles$().has(this._roleService.CUSTOMER_LEADER)
      ||
      this.roles$().has(this._roleService.MAINTENANCE_LEADER)
      ||
      this.roles$().has(this._roleService.SUPER_LEADER)
    ))

  isAdmin$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.ADMIN)
      ||
      this.roles$().has(this._roleService.CUSTOMER_ADMIN)
      ||
      this.roles$().has(this._roleService.MAINTENANCE_ADMIN)
      ||
      this.roles$().has(this._roleService.SUPER_ADMIN)
    ))

  isMgr$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.MGR)
      ||
      this.roles$().has(this._roleService.CUSTOMER_MGR)
      ||
      this.roles$().has(this._roleService.MAINTENANCE_MGR)
      ||
      this.roles$().has(this._roleService.SUPER_MGR)
    ))

  isUser$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.USER)
      ||
      this.roles$().has(this._roleService.CUSTOMER_USER)
      ||
      this.roles$().has(this._roleService.MAINTENANCE_USER)
      ||
      this.roles$().has(this._roleService.SUPER_USER)
    ))

  isGuest$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.GUEST)
      ||
      this.roles$().has(this._roleService.CUSTOMER_GUEST)
    ))

  //--------------------------- SUPER TEAM ---------------------------//

  isSprLdr$ = computed(() => !!this.roles$().size
    && (this.roles$().has(this._roleService.SUPER_LEADER)
      ||
      (this.roles$().has(this._roleService.SUPER) && this.roles$().has(this._roleService.LEADER))
    ))

  isSprAdmin$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.SUPER_ADMIN)
      ||
      (this.roles$().has(this._roleService.SUPER) && this.roles$().has(this._roleService.ADMIN))
    ))

  isSprMgr$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.SUPER_MGR)
      ||
      (this.roles$().has(this._roleService.SUPER) && this.roles$().has(this._roleService.MGR))
    ))

  isSprUser$ = computed(() =>
    this.roles$().has(this._roleService.SUPER_USER)
    ||
    (this.roles$().has(this._roleService.SUPER) && this.roles$().has(this._roleService.USER))
  )

  //----------------------- MAINTENANCE TEAM -------------------------//

  isMntcLdr$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.MAINTENANCE_LEADER)
      ||
      (this.roles$().has(this._roleService.MAINTENANCE) && this.roles$().has(this._roleService.LEADER))
    ))

  isMntcAdmin$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.MAINTENANCE_ADMIN)
      ||
      (this.roles$().has(this._roleService.MAINTENANCE) && this.roles$().has(this._roleService.ADMIN))
    ))

  isMntcMgr$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.MAINTENANCE_MGR)
      ||
      (this.roles$().has(this._roleService.MAINTENANCE) && this.roles$().has(this._roleService.MGR))
    ))

  isMntcUser$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.MAINTENANCE_USER)
      ||
      (this.roles$().has(this._roleService.MAINTENANCE) && this.roles$().has(this._roleService.USER))
    ))

  isMntcGst$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.MAINTENANCE_USER)
      ||
      (this.roles$().has(this._roleService.MAINTENANCE) && this.roles$().has(this._roleService.USER))
    ))

  //------------------------- CUSTOMER TEAM --------------------------//

  isCusLdr$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.CUSTOMER_LEADER)
      ||
      (this.roles$().has(this._roleService.CUSTOMER) && this.roles$().has(this._roleService.LEADER))
    ))

  isCusAdmin$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.CUSTOMER_ADMIN)
      ||
      (this.roles$().has(this._roleService.CUSTOMER) && this.roles$().has(this._roleService.ADMIN))
    ))

  isCusMgr$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.CUSTOMER_MGR)
      ||
      (this.roles$().has(this._roleService.CUSTOMER) && this.roles$().has(this._roleService.MGR))
    ))

  isCusUser$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.CUSTOMER_USER)
      ||
      (this.roles$().has(this._roleService.CUSTOMER) && this.roles$().has(this._roleService.USER))
    ))

  isCusGst$ = computed(() => !!this.roles$().size
    && (
      this.roles$().has(this._roleService.CUSTOMER_USER)
      ||
      (this.roles$().has(this._roleService.CUSTOMER) && this.roles$().has(this._roleService.USER))
    ))

  //------------------------------------------//

  team$ = computed(() => {

    if (this.isSpr$()) return TeamTypes.Super;
    else if (this.isMntc$()) return TeamTypes.Maintenance;
    else if (this.isCus$()) return TeamTypes.Customer;
    else return null

  })

  position$ = computed(() => {

    if (this.isLdr$()) return 'Leader';
    else if (this.isAdmin$()) return 'Admin';
    else if (this.isMgr$()) return 'Manager';
    else if (this.isUser$()) return 'User';
    else if (this.isGuest$()) return 'Guest';
    else return null

  })

  //------------------------------------------//

  isInDevOrIsMntc$ = computed(() => this.isInDevMode$() || this.isMntc$())
  isInDevOrIsSpr$ = computed(() => this.isInDevMode$() || this.isSpr$())

  //------------------------------------------//
  //---------------- Minimums ----------------//
  //------------------------------------------//

  isSprLdrMinimum$ = computed(() => this.isLoggedIn$() && this.isSprLdr$())
  isSprAdminMinimum$ = computed(() => (this.isLoggedIn$() && this.isSprAdmin$()) || this.isSprLdrMinimum$())
  isSprMgrMinimum$ = computed(() => (this.isLoggedIn$() && this.isSprMgr$()) || this.isSprAdminMinimum$())
  isSprUserMinimum$ = computed(() => this.isLoggedIn$() && this.isSprUser$() || this.isSprMgrMinimum$())
  isSprMinimum$ = computed(() => this.isSprUserMinimum$())

  //- - - - - - - - - - - - - - - - - - - - - //

  isMntcLdrMinimum$ = computed(() => (this.isLoggedIn$() && this.isMntcLdr$()) || this.isSprMinimum$())
  isMntcAdminMinimum$ = computed(() => (this.isLoggedIn$() && this.isMntcAdmin$()) || this.isMntcLdrMinimum$())
  isMntcMgrMinimum$ = computed(() => (this.isLoggedIn$() && this.isMntcMgr$()) || this.isMntcAdminMinimum$())
  isMntcUserMinimum$ = computed(() => (this.isLoggedIn$() && this.isMntcUser$()) || this.isMntcMgrMinimum$())
  isMntcGstMinimum$ = computed(() => (this.isLoggedIn$() && this.isMntcGst$()) || this.isMntcUserMinimum$())
  isMntcMinimum$ = computed(() => this.isMntcGstMinimum$())

  //- - - - - - - - - - - - - - - - - - - - - //

  isCusLdrMinimum$ = computed(() => (this.isLoggedIn$() && this.isCusLdr$()) || this.isMntcMinimum$())
  isCusAdminMinimum$ = computed(() => (this.isLoggedIn$() && this.isCusAdmin$()) || this.isCusLdrMinimum$())
  isCusMgrMinimum$ = computed(() => (this.isLoggedIn$() && this.isCusMgr$()) || this.isCusAdminMinimum$())
  isCusUserMinimum$ = computed(() => this.isLoggedIn$() && this.isCusUser$() || this.isCusMgrMinimum$())
  isCusGstMinimum$ = computed(() => this.isLoggedIn$() && this.isCusGst$() || this.isCusUserMinimum$())
  isCusMinimum$ = computed(() => this.isCusGstMinimum$())

  //- - - - - - - - - - - - - - - - - - - - - //

  isLdrMinimum$ = computed(() =>
    (this._config.isCustomerFacingApp && this.isMntcLdrMinimum$()) ||
    (!this._config.isCustomerFacingApp && this.isCusLdrMinimum$()) ||
    this.isSprMinimum$()
  )

  isAdminMinimum$ = computed(() =>
    (this._config.isCustomerFacingApp && this.isMntcAdminMinimum$()) ||
    (!this._config.isCustomerFacingApp && this.isCusAdminMinimum$()) ||
    this.isSprMinimum$()
  )

  isMgrMinimum$ = computed(() =>
    (this._config.isCustomerFacingApp && this.isMntcMgrMinimum$()) ||
    (!this._config.isCustomerFacingApp && this.isCusMgrMinimum$()) ||
    this.isSprMinimum$()
  )

  isUserMinimum$ = computed(() =>
    (this._config.isCustomerFacingApp && this.isMntcUserMinimum$()) ||
    (!this._config.isCustomerFacingApp && this.isCusUserMinimum$()) ||
    this.isSprMinimum$()
  )

  isGstMinimum$ = computed(() =>
    (this._config.isCustomerFacingApp && this.isMntcGstMinimum$()) ||
    (!this._config.isCustomerFacingApp && this.isCusGstMinimum$()) ||
    this.isSprMinimum$()
  )

  //------------------------------------------//

constructor() {
  super(inject(JwtStorageService).getStoredToken())
  this.init()
}

  //------------------------------------------//

  protected init() {

    effect(() => {
      const accessToken = this._accessToken()
      !accessToken
        ? this._jwtStore.removeJwt()
        : this._jwtStore.storeJwt(accessToken)
    })


    effect(() => {
      console.log('AuthRolesService_checking Expiry', this.isTokenStillValid());
      if (!this.isTokenStillValid())
        this.onExpiry()
    }, { allowSignalWrites: true })

    
    // setInterval(() => {
    //   if (!this.isTokenStillValid())
    //     this.onExpiry()
    // }, TimeInMillis.Hour * 6)

  }

  //------------------------------------------//

  getHighestRole = (): Role | null | undefined =>
    this._roleService.filterHighestPosition(Array.from(this.roles$()))

  //------------------------------------------//

  /**Read in the roles stored in the JWT */
  private extractRoles(allClaimsRecord: Record<string, Claim>): Set<Role> {
    
    try {
      //TODO get roles from const in class
      if (!allClaimsRecord)
        return new Set<Role>()
      
      // const roleValues = parsedToken[RoleNames.KEY];
      const roleValues = allClaimsRecord?.[RoleNames.KEY]?.value
      
      console.log('AuthRolesService', allClaimsRecord, roleValues);
      if (!roleValues)
        return new Set<Role>()

        console.log('AuthRolesService', roleValues);
      

      return this.convertValuesToRoles(roleValues)

    } catch (error) {

      console.log(error)
      this.logError(error)
      this.logOut()

      return new Set<Role>()

    } //catch

  } //extractRoles

  //- - - - - - - - - - - - - - - - - - - - - //

  private convertValuesToRoles(roleValues: any): Set<Role> {

    const rolesSet = new Set<Role>()
    try {

      if (!roleValues?.length) return rolesSet

      if (!Array.isArray(roleValues)) {
        const role = this._roleService.findRole(roleValues)
        if (!!role)
          rolesSet.add(role)
        return rolesSet
      } //if

      roleValues.forEach((rv) => {
        const role = this._roleService.findRole(rv);
        if (role) rolesSet.add(role);
      })

      return rolesSet
    } catch (error: any) {

      error.roleValues = roleValues;
      this.logError(error);
      return rolesSet

    } //catch

  } //convertValuesToRoles

  //------------------------------------------//

  private parseAppClaim(allClaimsRecord: Record<string, Claim>): App | null {

    let appClaimValue: any

    try {

      appClaimValue = allClaimsRecord?.[ClaimNames.CONNECTED_APP]?.value
      if (!appClaimValue)
        return null
      else
        return JSON.parse(appClaimValue)

    } catch (error: any) {

      error.claims = allClaimsRecord;
      error.appClaimsStr = appClaimValue ?? 'unknown'
      this.logError(error)

      return null

    } //catch

  } //parseAppClaim

  //------------------------------------------//

  private parseAppListClaims(allClaimsRecord: Record<string, Claim>): App[] {

    try {

      const apps: App[] = []
      const appsListClaimValue = allClaimsRecord[ClaimNames.CONNECTED_APPS]?.value


      //It might not appear as an array if there is only one app.
      if (StringHelpers.IsString(appsListClaimValue))
        apps.push(JSON.parse(appsListClaimValue.toString()))
      else if (appsListClaimValue instanceof Array)
        appsListClaimValue.forEach((cs: string) =>
          apps.push(JSON.parse(cs)))
      console.log(apps);


      return apps


    } catch (error: any) {

      error.claims = allClaimsRecord;
      this.logError(error)
      return []

    } //catch

  } 

  //------------------------------------------//

  private parseDeviceListClaims(allClaimsRecord: Record<string, Claim>): Device[] {

    try {

      const devices: Device[] = [];
      const dvcListClaimsValue = allClaimsRecord[TeamClaimNames.DEVICES]?.value

      //It might not appear as an array if there is only one app.
      if (StringHelpers.IsString(dvcListClaimsValue))
        devices.push(JSON.parse(dvcListClaimsValue.toString()))
      else if (dvcListClaimsValue instanceof Array)
        dvcListClaimsValue.forEach((cs: string) =>
          devices.push(JSON.parse(cs)))

      console.log(devices);

      return devices;
    } catch (error: any) {

      error.claims = allClaimsRecord;
      this.logError(error);
      return []

    } //catch

  } //parseDeviceListClaims

  //------------------------------------------//  

  protected onExpiry() {
    this.logOut()
  }

  //------------------------------------------//  

  protected override storeJwt(accessToken?: string): void {
    this._jwtStore.storeJwt(accessToken)
  }

  //------------------------------------------//  

  protected override logError(logData: any): void {
    console.log('logError:  Method not implemented.');
  }

  //------------------------------------------//  

} //Cls
