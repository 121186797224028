import { isPlatformServer } from '@angular/common';
import { ElementRef, Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivationEnd, ActivationStart, ChildActivationEnd, ChildActivationStart, GuardsCheckEnd, GuardsCheckStart, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent, RoutesRecognized, Scroll } from '@angular/router';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, map, pairwise, startWith, takeUntil, tap } from 'rxjs/operators';
import { SmoothScrollService } from '../smooth-scroll/smooth-scroll.service';

//###################################################################//

export declare type InigoRouterEvent = RouterEvent | NavigationStart | NavigationEnd | NavigationCancel | NavigationError | RoutesRecognized | GuardsCheckStart | GuardsCheckEnd | RouteConfigLoadStart | RouteConfigLoadEnd | ChildActivationStart | ChildActivationEnd | ActivationStart | ActivationEnd | Scroll | ResolveStart | ResolveEnd;

//###################################################################//
@Injectable({
  providedIn: 'root'
})
export class NavigationService implements OnDestroy {

  private _platformId = inject(PLATFORM_ID)
  private _router = inject(Router)
  private _scroller = inject(SmoothScrollService)

  //-------------------------------------------------------------------//


  private _navEndEventPair$ = new ReplaySubject<EventPair>(1)

  private navEndEventCurrent$ = this._navEndEventPair$
    .pipe(
      filter(evPair => !!evPair.current),
      map(evPair => evPair.current as NavigationEnd)
    )


  private navEndEventPrevious$ = this._navEndEventPair$
    .pipe(
      filter(evPair => !!evPair.previous),
      map(evPair => evPair.previous as NavigationEnd))

  currentRoute$ = this.navEndEventCurrent$.pipe(
    map(ev => ev?.url))


  previousRoute$ = this.navEndEventPrevious$.pipe(
    map(ev => ev?.url))

  currentRoute = toSignal(this.currentRoute$)
  previousRoute = toSignal(this.previousRoute$)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private _destroy$ = new Subject()

  //-------------------------------------------------------------------//

  private _scrollSub?: Subscription
  scrollToTopOfWindowOnNavigationChange(before: boolean = false) {

    this._scrollSub?.unsubscribe()
    this._scrollSub = this.scrollToTopOfWindowOnNavChange(before)
  }

  //-------------------------------------------------------------------//

  private _scrollSectionSub?: Subscription
  scrollToTopOfSectionOnNavigationChange(element: ElementRef, durationMillis: number = 0, before: boolean = false) {

    if (isPlatformServer(this._platformId))
      return

    this._scrollSectionSub?.unsubscribe()
    this._scrollSectionSub = this._router.events
      .pipe(
        takeUntil(this._destroy$),
        filter(event => before ? (event instanceof NavigationStart) : (event instanceof NavigationEnd)),
      )
      .subscribe(() =>
        this._scroller.smoothContainerScrollTo(0, durationMillis, element))
  }

  //-------------------------------------------------------------------//

  /**
    * An event stream for routing events in this NgModule.
    */
  get events(): Observable<InigoRouterEvent> {
    return this._router.events
  }

  //-------------------------------------------------------------------//

  /**
    * An event stream for NavigationEnd
    */
  get navigationEndEvent(): Observable<NavigationEnd> {

    return this._router.events
      .pipe(
        filter((event: InigoRouterEvent) => event instanceof NavigationEnd),
        map((e: InigoRouterEvent) => (e as NavigationEnd))
      )

  }

  //-------------------------------------------------------------------//

  constructor() {

    this.observeRouteUrls()
  }

  //-------------------------------------------------------------------//

  ngOnDestroy(): void {

    this._destroy$.next({})
    this._destroy$.complete()

  }//ngOnDestroy

  //-------------------------------------------------------------------//

  /**
   * Keep track of previous and current route urls
   */
  private observeRouteUrls() {

    this._router.events
      .pipe(
        takeUntil(this._destroy$),
        filter(event => event instanceof NavigationEnd),
        startWith(undefined),
        pairwise(),
        map(([previous, current]) => new EventPair(current as NavigationEnd, previous as NavigationEnd)),
      )
      .subscribe((pair: any) => this._navEndEventPair$.next(pair))

  }//observeRouteUrls

  //-------------------------------------------------------------------//

  /**
   * Listens for navigation change and crolls to to when Navigation ends
   */
  private scrollToTopOfWindowOnNavChange(before: boolean = false): Subscription {

    if (isPlatformServer(this._platformId))
      return new Subscription()

    return this._router.events
      .pipe(
        takeUntil(this._destroy$),
        filter(event => before ? (event instanceof NavigationStart) : (event instanceof NavigationEnd)),
      )
      .subscribe(() => window.scroll(0, 0))

  }//scrollToTopOnNavChange

}//Cls

//=====================================================================//

class EventPair {
  constructor(
    public current?: NavigationEnd,
    public previous?: NavigationEnd
  ) { }
}

//=====================================================================//