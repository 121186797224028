import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {

  override parse(url: string): UrlTree {
    // console.log(url);

    const splitter = '?';
    let urlParts = url.split(splitter)
    
    //Don't change the paramaters because they ARE case sensitive.
    if (urlParts?.length) urlParts[0] = urlParts[0].toLowerCase();

    if (urlParts.length > 1) urlParts[1] = this.handleQueryString(urlParts[1]);

    return super.parse(urlParts.join(splitter));
  } //parse

  //-------------------------------------//

  private handleQueryString(queryString: string): string {
    if (!queryString?.length) return queryString;

    const splitter = '&';
    const params = queryString.split(splitter);

    if (!params?.length) return queryString;

    for (let i = 0; i < params.length; i++) {
      params[i] = this.handleParam(params[i]);
    }

    return params.join(splitter);
  } 

  //-------------------------------------//

  private handleParam(param: string): string {
    if (!param?.length) return param;

    const splitter = '=';
    const paramParts = param.split(splitter);

    if (!paramParts?.length) return param;

    paramParts[0] = paramParts[0].toLowerCase();

    return paramParts.join(splitter);
  } 

  //-------------------------------------//
  
} //Cls
