import { Injectable, inject, isDevMode } from '@angular/core';
import { AuthRolesService } from '@inigo/authentication/roles';
import { BaseAuthGuard } from '../../a-base.guard';

/**
 * Maintenance Mgr Minimum or Development Mode
 */
@Injectable({
  providedIn: 'root',
})
export class MntcOrDevGuard extends BaseAuthGuard {

  private _authService = inject(AuthRolesService)


  isValid = (): boolean =>
    this._authService.isMntcMgrMinimum$() || isDevMode()


} //Cls
