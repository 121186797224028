import { HttpErrorResponse, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { inject } from "@angular/core";
import { BaseUnauthorizedHandlerInterceptor } from "@inigo/authentication/interceptors";
import { AuthRolesService } from "@inigo/authentication/roles";
import { AuthUtility } from "./auth-utility";
import { RouteHelper } from "./route-helper";
import { UnauthorizedAlertService } from "./alert/unauthorized-alert.service";

// @Injectable({ providedIn: 'root' })
export class CwUnauthorizedInterceptor extends BaseUnauthorizedHandlerInterceptor {

    private _routeHelper = inject(RouteHelper)
    private _auth = inject(AuthRolesService)
    private _utility = inject(AuthUtility)
    private _alert = inject(UnauthorizedAlertService)

    //-------------------------------------------------//

    handleUnauthorized(req: HttpRequest<any>, errorResponse: HttpErrorResponse) {

        console.log('CwUnauthorizedInterceptor???');

        this._alert.sendAlert(errorResponse.status)
        if (errorResponse.status === HttpStatusCode.Unauthorized) 
            this._auth.logOut()
       

        const queryParams = this._routeHelper.getReturnToAcountsQueryParams()

        this._utility.goToLogin(queryParams)

    }

    //-------------------------------------------------//

} //Cls
