import { Injectable, inject } from '@angular/core';
import { AuthRolesService } from '@inigo/authentication/roles';
import { BaseAuthGuard } from '../../../a-base.guard';

/**
 * Only members of super team or higher can access.
 */
@Injectable({
  providedIn: 'root',
})
export class SuperMinimumGuard extends BaseAuthGuard {

private _authService = inject(AuthRolesService)


  isValid = (): boolean => this._authService.isSprMinimum$()

} //Cls
