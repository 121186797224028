import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, TransferState, inject, makeStateKey } from '@angular/core';
import { APageStoreService } from '@inigo/my-store';
import { Observable, catchError, of, take, tap } from 'rxjs';
import { Department } from '../../../dtos/dept';
import { DepartmentUpload } from '../../../dtos/dept-upload';
import { DeptService } from '../../http/dept/dept.service';

/**
 * Initial PagePackage (No filter) and allData will be retreived automatically
 */
@Injectable({
  providedIn: 'root',
})
export class DeptStoreService extends APageStoreService<Department, DepartmentUpload> {

  private _platformId = inject(PLATFORM_ID)
  private _transferState = inject(TransferState)

  //-------------------------------------------------------//

  constructor(_httpService: DeptService) {
    super(_httpService)

    this.setItemTypeName('Industry')
    this.setItemNameLamda((p) => p.name ?? `Industry ${p.name}`)

  } //ctor

  //-------------------------------------------------------//

  setupDeptList(): void {

    const DEPT_KEY = makeStateKey<Department[]>("departmentsKey")

    if (this._transferState.hasKey(DEPT_KEY)) {
      const depts = this._transferState.get(DEPT_KEY, [])
      console.log('resolve', DEPT_KEY, depts)
      this._transferState.remove(DEPT_KEY)
      this._filteredDataBsArray.next(depts)
      return
    }

    this.filterData().pipe(
      take(1),
      tap(dept => {
        if (isPlatformServer(this._platformId))
          this._transferState.set(DEPT_KEY, dept)
      }),
      catchError(() => of([]))
    )
      .subscribe(depts => this._filteredDataBsArray.next(depts))

  } //resolve

  //--------------------------------------------------------//

  override refreshDataIfNecessary(): void {

    if (!this.totalItems()) {
      this.filterData()
    }

  }

  //-------------------------------------------------------//

  override refreshData(): void {

    this.filterData()
  }

  //-------------------------------------------------------//

  getDeptList$(): Observable<Department[]> {

    if (!this._filteredDataBsArray.getValue()?.length) {
      console.log('getDeptList$')
      return this.filterData()
    }

    return this.filteredData$

  }

  //-------------------------------------------------------//

} //Cls
