import { HttpInterceptorFn } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { HttpServicesConfig } from '@inigo/http-services/config';

//===================================================================//

export const CoreConfigService = new InjectionToken<CoreConfig>('CoreConfig')

//===================================================================//

export class CoreConfig {

  private _httpServicesConfig: HttpServicesConfig = HttpServicesConfig.Create()
  /** Configuration for base data service and error reporting service: -  Default = new HttpServicesConfig() */
  public get httpServicesConfig(): HttpServicesConfig {
    return this._httpServicesConfig
  }

  private _lazyRoutesToIgnore: string[] = [
    'home',
    'NotFound',
  ]

  /** Collection of Component Names that the LAZY LOADING INDICATOR interceptor should ignore -  Default = ['HomeComponent', 'NotFoundComponent']*/
  public get lazyRoutesToIgnore(): string[] {
    return this._lazyRoutesToIgnore
  }

  private _skipEmailErrors = false
  /** Don't email the error report. Default = false*/
  public get skipEmailErrors(): boolean {
    return this._skipEmailErrors
  }

  private _interceptorFns: HttpInterceptorFn[] = []
  /** Any extra http interceptors. Default = []*/
  public get httpInterceptorFns(): HttpInterceptorFn[] {
    return this._interceptorFns
  }


  


  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * Enter no paramaters to use default values on everything
   * @param httpServicesConfig Configuration for base data service and error reporting service: - default new HttpServicesConfig()
   * @param lazyComponentNamesToIgnore Collection of Component Names that the LAZY LOADING INDICATOR interceptor should ignore - default ['HomeComponent', 'NotFoundComponent']
   */
  constructor(
    httpServicesConfig?: HttpServicesConfig,
    lazyComponentNamesToIgnore?: string[]
  ) {

    if (httpServicesConfig)
      this._httpServicesConfig = httpServicesConfig

    if (lazyComponentNamesToIgnore)
      this._lazyRoutesToIgnore = lazyComponentNamesToIgnore

  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of CoreConfig
   * @param contactConfig Configuration contact details/form/mapo etc: -  Default = none 
   */
  static Create = (): CoreConfig => new CoreConfig()

  //---------------------------------------------------------------------//
  /**
   * @param httpServicesConfig Configuration for base data service and error reporting service. Default = new HttpServicesConfig()
   * @returns Updated CoreConfig
   */
  setHttpServicesConfig(httpServicesConfig: HttpServicesConfig): CoreConfig {

    this._httpServicesConfig = httpServicesConfig
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param lazyRoutesToIgnore Collection of Component Names that the LAZY LOADING INDICATOR interceptor should ignore. Default = ['HomeComponent', 'NotFoundComponent']
   * @returns Updated CoreConfig
   */
  setLazyComponentNamesToIgnore(lazyRoutesToIgnore: string[]): CoreConfig {
    this._lazyRoutesToIgnore = lazyRoutesToIgnore
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param skipEmailErrors  Don't email the error report. Default = false
   * @returns Updated CoreConfig
   */
  setSkipEmailErrors(skipEmailErrors: boolean): CoreConfig {
    this._skipEmailErrors = skipEmailErrors
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param interceptorFns  D Any extra http interceptors. Default = []
   * @returns Updated CoreConfig
   */
  setInterceptorFns(interceptorFns: HttpInterceptorFn[] ): CoreConfig {
    this._interceptorFns = interceptorFns
    return this
  }


} //Cls
