import { inject } from '@angular/core';
import { Identifier } from '@inigo/data/misc';
import { BlobDownloadService } from '@inigo/gen-helpers/services';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { SuccessInfo } from '../models/result-info';
import { DefaultTemplateHttpService } from './default-template-http.service';
import { ITemplateHttpService } from './i-template-http.service';
import { ITemplateStoreService } from './i-template-store.service';
import { toSignal } from '@angular/core/rxjs-interop';

//################################################################################//

/**
 * If not using PagePackages just override this.getPagePackage()  with: this.getPage()
 */
export class DefaultTemplateStoreService implements ITemplateStoreService {

  protected _blobHandler = inject(BlobDownloadService)

  //-------------------------------------------------------//

  protected _isLoadingBs = new BehaviorSubject<boolean>(false)
  isLoading$ = this._isLoadingBs.asObservable()
  isLoading = toSignal(this.isLoading$, { initialValue: false })

  private _templateBs = new ReplaySubject<Blob>(1)
  /**Associated File (example: Excel) */
  template$ = this._templateBs.asObservable()

  //Use Subjects here because we don't want a user to be sent the previous error.
  //All relevant Success/Error info will come AFTER subscription
  protected _errorSb: Subject<any> = new Subject<any>()
  error$ = this._errorSb.asObservable()
  error = toSignal(this.error$)

  protected _successSb: Subject<SuccessInfo<Blob>> = new Subject<SuccessInfo<Blob>>()
  success$ = this._successSb.asObservable()
  success = toSignal(this.success$)

  //-------------------------------------------------------//

  constructor(private httpService: ITemplateHttpService) {
    this.httpService = httpService
  }

  //-------------------------------------------------------//

  static Create(
    url: string,
    templateBs: ReplaySubject<Blob>,
    isLoadingBs: BehaviorSubject<boolean>,
    errorSb: Subject<boolean>,
    successSb: Subject<SuccessInfo<any>>
  ): DefaultTemplateStoreService {

    const store = new DefaultTemplateStoreService(new DefaultTemplateHttpService(url))
    store._isLoadingBs = isLoadingBs
    store.isLoading$ = store._isLoadingBs.asObservable()
    store._templateBs = templateBs
    store.template$ = store._templateBs.asObservable()
    store._errorSb = errorSb
    store.error$ = store._errorSb.asObservable()
    store._successSb = successSb
    store.success$ = store._successSb.asObservable()

    return store

  }//Create

  //-------------------------------------------------------//

  protected _templateSub?: Subscription
  getTemplate(fileName: string = 'template.tmp', id?: Identifier, action?: string): Observable<Blob> {

    this._isLoadingBs.next(true)

    this._templateSub?.unsubscribe()

    const template$ = this.httpService.getTemplate(id, action)
      .pipe(
        finalize(() => this._isLoadingBs.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      )

    this._templateSub = template$.subscribe({
      next: (blob: Blob) => {
        this._blobHandler.download(blob, fileName)
        this._successSb.next(SuccessInfo.Template(fileName))
        this._templateBs.next(blob)
      },
      error: (error) => this._errorSb.next(error),
    }) //subscribe

    return template$

  } //requestTemplate

  //-------------------------------------------------------//

} //Cls

