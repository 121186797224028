import { InsertedDto, InsertingDto } from '@inigo/data/insert';
import { Identifier } from '@inigo/data/misc';
import { ToFormData } from '@inigo/gen-helpers/objects';
import { DataService } from '@inigo/http-services';
import { Observable } from 'rxjs';
import { ICrudIoService } from './i-crud-http.service';

/**
 * Interface for basic HttpCalls that an implentation of BaseStore will user
 *
 * @template T The main type that we're performing CRUD on.
 * @template TUpload The type that will be used for uploading (add/edit). Can be the same as T but may contain some extra fields.
 */
export abstract class ACrudIoService<T, TUpload extends T> extends DataService implements ICrudIoService<T, TUpload> {
  
  //---------------------------------------------------------------------//

  addItem = (item: TUpload, opts?: any): Observable<T> =>
    this.add(ToFormData(item), opts ?? {})

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  addItemWithCheck?(item: TUpload, skipDuplicateCheck: boolean, opts?: any): Observable<InsertedDto<T>> {
    const dto = new InsertingDto<T>(item, skipDuplicateCheck)
    return this.add(ToFormData(dto), opts ?? {})
  } 

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  deleteItem = (id: Identifier, opts?: any): Observable<any> =>
    this.deleteDelete(id, opts ?? {})

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  editItem = (item: TUpload, opts?: any): Observable<T> =>
    this.edit(ToFormData(item), opts ?? {})


  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  getItem = (id: Identifier, opts?: any): Observable<T> =>
    this.getActionName('get', id, opts ?? {})


  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  getAllItems = (opts?: any): Observable<T[]> =>
    this.getAllAction('getAll', opts ?? {})


  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  
  getAllItemsFiltered = (filter: any, id?: Identifier, opts?: any): Observable<T[]> =>
    this.getAllAction(`getAllFiltered/${filter}`, opts ?? {})



  //---------------------------------------------------------------------//
} //Cls
