import { Injectable, inject } from "@angular/core";
import { Params, Router } from "@angular/router";
import { AuthRolesService } from "@inigo/authentication/roles";
import { RouteHelper } from "./route-helper";
import { MntcAreaRoutesFullPath } from "../../admin/admin-routes-defs";
import { PublicAreaRoutesFullPath } from "../../public/public-routes-defs";
import { AccRoutes } from "@inigo/acc-n-auth-data/routes";

@Injectable({
    providedIn: 'root'
})
export class AuthUtility {

    private _router = inject(Router)
    private _authService = inject(AuthRolesService)
    private _helper = inject(RouteHelper)

    //- - - - - - - - - - - - - - - - - - - - - //   

    goToChangePwd = () => this._router.navigate([MntcAreaRoutesFullPath.Accounts.CHANGE_PWD])

    //- - - - - - - - - - - - - - - - - - - - - //

    logOutAndGoToLogin(queryParams?: Params | null) {
        this._authService.logOut()
        this.goToLogin(queryParams)
    }

    //------------------------------------------//

    goToLogin = (queryParams?: Params | null) =>
        this._router.navigate(this.getLoginRouteCommands(), { queryParams: queryParams })


    //------------------------------------------//

    createteLoginUrlTree = (queryParams?: Params | null) =>
        this._router.createUrlTree(this.getLoginRouteCommands(), { queryParams: queryParams })


    //------------------------------------------//

    getLoginRouteCommands = () =>
        [...this.getAccountsBaseRouteCommands(), AccRoutes.LOGIN]

    //------------------------------------------//

    getChangePwdRouteCommands = () =>
        [...this.getAccountsBaseRouteCommands(), AccRoutes.CHANGE_PWD]


    //------------------------------------------//


    getMyInfoRouteCommands = () =>
        [...this.getAccountsBaseRouteCommands(), AccRoutes.MY_INFO]


    //------------------------------------------//


    getAccountsBaseRouteCommands() {

        if (this._helper.isInMntcSection())
            return [MntcAreaRoutesFullPath.Accounts.BASE]
        else
            return [PublicAreaRoutesFullPath.Accounts.BASE]
    }


    //------------------------------------------//



}
