import { Injectable, inject } from '@angular/core';
import { AuthRolesService } from '@inigo/authentication/roles';
import { BaseAuthGuard } from './a-base.guard';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInGuard extends BaseAuthGuard {

  private _authService = inject(AuthRolesService)

  isValid = (): boolean => !this._authService.isLoggedIn$()

  
} //Cls
