/**
 * Names or Keys of claims in JWT
 */
export class ClaimNames {
  
  //Registered
  public static NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
  public static EMAIL_CLAIM = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';
  public static EMAIL = 'email';
  public static ISSUER = 'iss';
  public static ISSUED_AT = 'iat';
  public static USER_ID = 'sub';
  public static NOT_VALID_BEFORE = 'nbf';
  public static EXPIRY = 'exp';
  public static AUDIENCE = 'aud';
  public static AUTH_TIME = 'auth_time';
  public static EMAIL_VERIFIED = 'email_verified';

  //Apps
  public static CONNECTED_APPS = 'shanie.moonlight.myidentity.claims.apps.AppList';
  public static CONNECTED_APP = 'shanie.moonlight.myidentity.claims.apps.App';

  public static CLAIM_TYPE_APPLICATION = 'SM_Claim_Application';
  public static CLAIM_TYPE_COMPANY = 'SM_Claim_Company';

  public static ROLES_MS = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
  // public static ROLE = 'shanie.moonlight.myidentity.claims.Role';

} //Cls
