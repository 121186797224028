import { Injectable, inject, isDevMode } from '@angular/core';
import { AuthRolesService } from '@inigo/authentication/roles';
import { BaseAuthGuard } from '../../a-base.guard';

@Injectable({
  providedIn: 'root',
})
export class SuperOrDevGuard extends BaseAuthGuard {

  private _authService = inject(AuthRolesService)


  isValid = (): boolean =>
    this._authService.isSprMinimum$() || isDevMode()

} //Cls
