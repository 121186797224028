import { Route } from '@angular/router';
import { DelayPreloader } from '@inigo/lazy-load-helpers/preload';
import { AppRoutes } from './app-routes';


export const appRoutes: Route[] = [    
    //Admin goes first because  if AppRoutes.PUBLIC_AREA is '' then MntcAreaRoutes.BASE will send us to NOtFound in Pulic Area
    {
        path: AppRoutes.MntcArea.BASE,
        loadChildren: () => import('./admin/admin-routes').then((m) => m.MNTC_ROUTES),
        // data: DelayPreloader(300000),
    },
    {
        path: AppRoutes.PUBLIC_AREA,
        loadChildren: () => import('./public/public-routes').then((m) => m.PUBLIC_ROUTES),
        data: DelayPreloader(1),
    },
    {
        path: '',
        redirectTo: `${AppRoutes.PUBLIC_AREA}`,
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: AppRoutes.PublicArea.NOT_FOUND,
        pathMatch: 'full',
    }
];
