import { Injectable } from '@angular/core';
import { ToFormData } from '@inigo/gen-helpers/objects';
import { APageIoService } from '@inigo/my-store';;
import { Observable, timeout } from 'rxjs';
import { AppActions } from '../../../../app-actions';
import { AppControllers } from '../../../../app-controllers';
import { Brand } from '../../../dtos/brand';
import { BulkUploadDto } from '../../../dtos/bulk-upload';

@Injectable({
  providedIn: 'root',
})
export class BrandHttpService extends APageIoService<Brand, Brand>{

  constructor() {
    super();
    this.url = AppControllers.FullPath.BRANDS
  } //ctor

  //---------------------------------------------------------------------//

  addBulk = (dto: BulkUploadDto): Observable<Blob> =>
    this.postAction(AppActions.Brands.ADD_BULK, ToFormData(dto), { responseType: 'blob' })
      .pipe(
        timeout(600000)
      );
    
  //---------------------------------------------------------------------//

} //service
