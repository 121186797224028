import { Routes } from '@angular/router';
import { DelayPreloader } from '@inigo/lazy-load-helpers/preload';
import { MntcAreaRoutes, MntcAreaRoutesFullPath } from './admin/admin-routes-defs';
import { PublicAreaRoutes, PublicAreaRoutesFullPath } from './public/public-routes-defs';

export class AppRoutes {

    public static readonly ID_PARAM = '/:id'
    public static readonly NOT_FOUND = 'notfound'
    public static readonly PUBLIC_AREA = '' //'pa'
    public static readonly MNTC_AREA = 'admin'
    public static readonly HOME = 'home'

    //---------------------------------------------------------//

    static PublicArea = PublicAreaRoutes


    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

    static PublicAreaFullPath = PublicAreaRoutesFullPath

    //---------------------------------------------------------//

    static MntcArea = MntcAreaRoutes

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

    static MntcAreaFullPath = MntcAreaRoutesFullPath
 
    //---------------------------------------------------------//


}//Cls


//###########################################################################################################//

export const APP_ROUTES: Routes = [
    //Admin goes first because  if AppRoutes.PUBLIC_AREA is '' then MntcAreaRoutes.BASE will send us to NOtFound in Pulic Area
    {
        path: AppRoutes.MntcArea.BASE,
        loadChildren: () => import('./admin/admin-routes').then((m) => m.MNTC_ROUTES),
        data: DelayPreloader(300000),
        // canActivate: [MaintenanceMgrMinimumGuard],

    },
    {
        path: AppRoutes.PUBLIC_AREA,
        loadChildren: () => import('./public/public-routes').then((m) => m.PUBLIC_ROUTES),
        data: DelayPreloader(1),
    },
    {
        path: '',
        redirectTo: `${AppRoutes.PUBLIC_AREA}`,
        pathMatch: 'full',
    },
    {
      path: '**',
      redirectTo: AppRoutes.PublicArea.NOT_FOUND,
      pathMatch: 'full',
    },
];