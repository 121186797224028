import { isDevMode } from "@angular/core"

const _stripeKeyProd = 'pk_live_51N4M9qDRZrCLCLq8Vmjc5sTY1PUTw0xBocVmvPSPy2j8wXRIOQ9EpyqFi56tIxly2TqJKuZzoj3DtvIxojZid6UD00rNwk9U9x'
const _stripeKeyDev = 'pk_test_51N4M9qDRZrCLCLq81UbT6Lpe2z7cWzksE3mDAmIXfZLHOLOI0pHzreRRvkzloYTxnWMhJkD3Tt6uEazPo41sXTr1006teWLrRy'
const agmApiKey = 'AIzaSyBzaeO2SuMV3yVt4LoyK6bDUC12m722ZOc'
const agmMapId = '7bb39d9a5b49bf65'

export class Secrets {

    //----------------------------------//

    public static get stripeKey(): string { return isDevMode() ? _stripeKeyDev : _stripeKeyProd }

    //----------------------------------//


    public static get agmApiKey(): string { return agmApiKey }

    //----------------------------------//

    public static get agmMapId(): string { return agmMapId }
    
    //----------------------------------//
    
    public static readonly fromAppHeaderValue = 'Cc8SeknOr82EL7vsWx8n3EO2yuOhGkINEvAiYPUw24LZYMfVhY'

    //----------------------------------//

}//Cls