import { InjectionToken } from '@angular/core';
import { AccRoutes } from '@inigo/acc-n-auth-data/routes';
import { RoleNames } from '@inigo/acc-n-auth-data/roles';
import { CaseInsensitiveSet } from '@inigo/helpers/collections';
import { StringHelpers } from '@inigo/helpers/text';
import { DefaultRoutes, DefaultUrls } from './default-urls-and-paths';

//===================================================================//

export const RolesAuthConfigService = new InjectionToken<RolesAuthConfig>('RolesAuthConfig');

/** Auth Header name */
export const AUTH_HEADER = 'Authorization'

export const FROM_APP_HEADER = 'From-App'

/** Type of token */
export const TOKEN_PREFIX = 'Bearer ' //the Space at the end is important
export const JWT_AUTH_KEY = 'inigo_auth_key'

//===================================================================//

export class RolesAuthConfig {

  private _roleFullNamePrefix = RoleNames.INIGO_PREFIX;
  /** The prefix that all role claims will have - Default =  'shanie.moonlight.myidentity.claims.roles.'   */
  public get roleFullNamePrefix(): string {
    return StringHelpers.Clone(this._roleFullNamePrefix);
  }

  private _fromAppHeaderValue = 'From-App-123456';
  /** Url for accounts controller */
  public get fromAppHeaderValue(): string {
    return `${this._fromAppHeaderValue}`;
  }

  private _loginRelativePath = DefaultRoutes.LOGIN_RELATIVE;
  /** Where to go after access is denied. Relative to one of the {@link baseRoutes} - Default =  {@link AccRoutes.ACCOUNT_FEATURES}/{@link AccRoutes.LOGIN} */
  public get loginRelativePath(): string {
    return StringHelpers.Clone(this._loginRelativePath);
  }

  private _twoFactorRelativePath = DefaultRoutes.TWO_FACTOR_RELATIVE;
  /** Where to go when two factor is required. Relative to the parent route {@link baseRoutes} - Default =  {@link AccRoutes.ACCOUNT_FEATURES}/{@link AccRoutes.TWO_FACTOR_VERIFICATION} */
  public get twoFactorRelativePath(): string {
    return StringHelpers.Clone(this._twoFactorRelativePath);
  }

  private _urlParent = DefaultUrls.PARENT_PATH;
  /** Url for accounts controller */
  public get urlParent(): string {
    return StringHelpers.Clone(this._urlParent);
  }

  private _urlAccounts = `${DefaultUrls.ACCOUNT}`;
  /** Url for accounts controller */
  public get urlAccounts(): string {
    return `${this._urlParent}/${this._urlAccounts}`;
  }

  private _urlAccessPointMgmt = `${DefaultUrls.ACCESS_POINT_MGMT}`;
  /** Url for Access Point Mgmt controller */
  public get urlAccessPointMgmt(): string {
    return `${this._urlParent}/${this._urlAccessPointMgmt}`;
  }

  private _urlDevices = `${DefaultUrls.DEVICE_MGMT}`;
  /** Url for Devices controller */
  public get urlDevices(): string {
    return `${this._urlParent}/${this._urlDevices}`;
  }

  private _urlSubsMgmt = `${DefaultUrls.SUBS_MGMT}`;
  /** Url for Subscription Mgmt controller */
  public get urlSubsMgmt(): string {
    return `${this._urlParent}/${this._urlSubsMgmt}`;
  }

  private _urlSubDefsMgmt = `${DefaultUrls.SUB_DEFS_MGMT}`;
  /** Url for SubDefMgmt controller */
  public get urlSubDefsMgmt(): string {
    return `${this._urlParent}/${this._urlSubDefsMgmt}`;
  }

  private _urlUserMgmt = `${DefaultUrls.USER_MGMT}`;
  /** Url for User Mgmt controller */
  public get urlUserMgmt(): string {
    return `${this._urlParent}/${this._urlUserMgmt}`;
  }

  private _urlTwoFactor = `${DefaultUrls.TWO_FACTOR}`;
  /** Url for 2-Factor controller */
  public get urlTwoFactor(): string {
    return `${this._urlParent}/${this._urlTwoFactor}`;
  }

  private _isCustomerFacingApp = false;
  /** Will the app have customers or not. */
  public get isCustomerFacingApp(): boolean {
    return this._isCustomerFacingApp;
  }

  private _baseRoutes: CaseInsensitiveSet = new CaseInsensitiveSet();
  /**
   * Collection of base routes. Sections/Areas of website that will have their own Accounts section.
   * Maybe admin and public areas. Helps with navigating on login, 403, etc.
   */
  public get baseRoutes() {
    if (!this._baseRoutes) this._baseRoutes = new CaseInsensitiveSet();
    return this._baseRoutes;
  }

  //---------------------------------------------------------------------//

  /**
   * Enter on paramaters to use default values on everything
   * @param isCustomerFacingApp Will the app have customers or just an internal team - default false
   * @param roleFullNamePrefix How to identify my own claims - default 'shanie.moonlight.myidentity.claims.roles.'
   * @param loginRelativePath Used to redirect user to login when Unauthorized - default 'accounts/login'
   */
  private constructor(
    isCustomerFacingApp = false,
    roleFullNamePrefix = null,
    loginRelativePath = null
  ) {
    // if (parentRoute)
    //     this._parentRoute = parentRoute

    this._isCustomerFacingApp = isCustomerFacingApp;

    if (loginRelativePath) this._loginRelativePath = loginRelativePath;

    if (roleFullNamePrefix) this._roleFullNamePrefix = roleFullNamePrefix;

    // this._isDev = isDev
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of AuthConfig
   * @param parentRoute Route that precedes Accounts Routes such as {@link loginRelativePath} will be realtive to.
   * @param isCustomerFacingApp Will the app have customers or not.
   */
  static Create(isCustomerFacingApp = false): RolesAuthConfig {

    var config = new RolesAuthConfig(isCustomerFacingApp);

    return config;
  } //create

  //---------------------------------------------------------------------//

  /**
   * @param accountUrl Url for accounts controller
   * @returns Updated AuthConfig
   */
  setAccountUrl(accountUrl: string): RolesAuthConfig {
    this._urlAccounts = accountUrl;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param appMgmtUrl Url for App Mgmt controller
   * @returns Updated AuthConfig
   */
  setAppMgmtUrl(appMgmtUrl: string): RolesAuthConfig {
    this._urlAccessPointMgmt = appMgmtUrl;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Set the Collection of base routes. Sections/Areas of website that will have their own Accounts section.
   * Maybe admin and public areas. Helps with navigating on login, 403, etc.
   * If a route is just whitespoace it can be ommited as this is the default
   * @param baseRoutes List of base routes.
   * @returns Updated AuthConfig
   */
  setBaseRoutes(baseRoutes: string[] | Set<string>): RolesAuthConfig {
    this._baseRoutes = new Set(baseRoutes);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param deviceUrl Url for Devices controller
   * @returns Updated AuthConfig
   */
  setDeviceUrl(deviceUrl: string): RolesAuthConfig {
    this._urlDevices = deviceUrl;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param fromAppHeaderValue app identifier
   * @returns Updated AuthConfig
   */
  setFromAppHeaderValue(fromAppHeaderValue: string): RolesAuthConfig {
    this._fromAppHeaderValue = fromAppHeaderValue;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param isCustomerFacingApp
   * @returns Updated AuthConfig
   */
  setIsCustomerFacingApp(isCustomerFacingApp: boolean): RolesAuthConfig {
    this._isCustomerFacingApp = isCustomerFacingApp;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param loginRelativePath  Where to go after access is denied. Relative to the parent route {@link parentRoute}
   * @returns Updated AuthConfig
   */
  setLoginRelativePath(loginRelativePath: string): RolesAuthConfig {
    this._loginRelativePath = loginRelativePath;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param roleFullNamePrefix The prefix that all role claims will have
   * @returns Updated AuthConfig
   */
  setRoleNamePrefix(roleFullNamePrefix: string): RolesAuthConfig {
    this._roleFullNamePrefix = roleFullNamePrefix;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param userMgmtUrl Url for User Mgmt controller
   * @returns Updated AuthConfig
   */
  setUserMgmtUrl(userMgmtUrl: string): RolesAuthConfig {
    this._urlUserMgmt = userMgmtUrl;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param subsMgmtUrl Url for Subscription Mgmt controller
   * @returns Updated AuthConfig
   */
  setSubsMgmtUrl(subsMgmtUrl: string): RolesAuthConfig {
    this._urlSubsMgmt = subsMgmtUrl;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param subsMgmtUrl Url for SubDef Mgmt controller
   * @returns Updated AuthConfig
   */
  setSubDefsMgmtUrl(subsMgmtUrl: string): RolesAuthConfig {
    this._urlSubDefsMgmt = subsMgmtUrl;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//


  /**
   * @param twoFactorRelativePath Where to go when two factor is required. Relative to the parent route {@link parentRoute}
   * @returns Updated AuthConfig
   */
  setTwoFactorRelativePath(twoFactorRelativePath: string): RolesAuthConfig {
    this._twoFactorRelativePath = twoFactorRelativePath;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param twoFactorUrl Url for 2-Factor controller
   * @returns Updated AuthConfig
   */
  setTwoFactorUrl(twoFactorUrl: string): RolesAuthConfig {
    this._urlTwoFactor = twoFactorUrl;
    return this;
  }



  //---------------------------------------------------------------------//
} //Cls
