import { Injectable } from '@angular/core';
import { ToFormData } from '@inigo/gen-helpers/objects';
import { APageIoService } from '@inigo/my-store';;
import { Observable, timeout } from 'rxjs';
import { AppActions } from '../../../../app-actions';
import { AppControllers } from '../../../../app-controllers';
import { BulkUploadDto } from '../../../dtos/bulk-upload';
import { Style } from '../../../dtos/style';

@Injectable({
  providedIn: 'root',
})
export class StyleHttpService extends APageIoService<Style, Style> {

  constructor() {
    super()
    this.url = AppControllers.FullPath.STYLES
  } //ctor
  //---------------------------------------------------------------------//

  addBulk = (dto: BulkUploadDto): Observable<Blob> =>
    this.postAction(AppActions.Styles.ADD_BULK, ToFormData(dto), { responseType: 'blob' })
      .pipe(
        timeout(600000)
      );

  //---------------------------------------------------------------------//

} //Cls
