import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EnvironmentProviders, InjectionToken, Provider } from '@angular/core';
import { Observable } from 'rxjs';

// @Injectable()

interface HeaderData {
  headerName: string
  headerValue: string
}

//======================================================================//

class FromAppHeaderInterceptor implements HttpInterceptor {

  // constructor(private _headerName: string, private _headerValue: string) { }
  constructor(private _headerData: HeaderData) {

    // console.log(_headerData);

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    const fromAppRequest = req.clone({
      headers: req.headers.set(
        this._headerData.headerName,
        this._headerData.headerValue
      ),
    })

    return next.handle(fromAppRequest)

  } //intercept

} //Cls

//======================================================================//

export function provideFromAppHeaderInterceptor(headerValue: string, headerName: string = 'From_App'): (Provider | EnvironmentProviders)[] {

  const FromAppHeaderHeaaderData = new InjectionToken<HeaderData>('FromAppHeaderData');

  return [
    {
      provide: FromAppHeaderHeaaderData,
      useValue: {
        headerName: headerName,
        headerValue: headerValue,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (headerValue: HeaderData) => new FromAppHeaderInterceptor(headerValue),
      deps: [FromAppHeaderHeaaderData], //this has to be pre provided above
      multi: true,
    },

  ]
}

//======================================================================//