import { AccRoutes } from '@inigo/acc-n-auth-data/routes';

export class DefaultRoutes {

  public static readonly LOGIN_RELATIVE = `${AccRoutes.ACCOUNT_FEATURES}/${AccRoutes.LOGIN}`;
  public static readonly CONFIRM_EMAIL_CUSTOMER_LOGIN_RELATIVE = `${AccRoutes.ACCOUNT_FEATURES}/${AccRoutes.CONFIRM_EMAIL}`;
  public static readonly CONFIRM_EMAIL_MNTC_RELATIVE = `${AccRoutes.ACCOUNT_FEATURES}/${AccRoutes.CONFIRM_EMAIL_MNTC}`;
  public static readonly EMAIL_CONFIRMED_CUSTOMER_RELATIVE = `${AccRoutes.ACCOUNT_FEATURES}/${AccRoutes.EMAIL_CONFIRMED}`;
  public static readonly EMAIL_CONFIRMED_MNTC_RELATIVE = `${AccRoutes.ACCOUNT_FEATURES}/${AccRoutes.EMAIL_CONFIRMED_MNTC}`;
  public static readonly EMAIL_NOT_CONFIRMED_CUSTOMER_RELATIVE = `${AccRoutes.ACCOUNT_FEATURES}/${AccRoutes.EMAIL_NOT_CONFIRMED_CUSTOMER}`;
  public static readonly EMAIL_NOT_CONFIRMED_MNTC_RELATIVE = `${AccRoutes.ACCOUNT_FEATURES}/${AccRoutes.EMAIL_NOT_CONFIRMED_MNTC}`;
  public static readonly TWO_FACTOR_RELATIVE = `${AccRoutes.ACCOUNT_FEATURES}/${AccRoutes.EMAIL_NOT_CONFIRMED_MNTC}`;
  public static readonly PARENT: string = '';

} //Cls

//===============================================================//

export class DefaultUrls {
  public static readonly PARENT_PATH = '/api';

  public static readonly ACCOUNT = 'account';
  public static readonly ACCESS_POINT_MGMT = 'accessPoints';
  public static readonly DEVICE_MGMT = 'devices';
  public static readonly SUBS_MGMT = 'subscriptions';
  public static readonly SUB_DEFS_MGMT = 'subscriptionDefinitions';
  public static readonly TWO_FACTOR = 'twoFactor';
  public static readonly USER_MGMT = 'usermanagement';
} //Cls

//===============================================================//

export const DefaultAllowAnonymousRoutes = [
  DefaultRoutes.LOGIN_RELATIVE,
  DefaultRoutes.CONFIRM_EMAIL_CUSTOMER_LOGIN_RELATIVE,
  DefaultRoutes.CONFIRM_EMAIL_MNTC_RELATIVE,
  DefaultRoutes.EMAIL_CONFIRMED_CUSTOMER_RELATIVE,
  DefaultRoutes.EMAIL_CONFIRMED_MNTC_RELATIVE,
  DefaultRoutes.EMAIL_NOT_CONFIRMED_CUSTOMER_RELATIVE,
  DefaultRoutes.EMAIL_NOT_CONFIRMED_MNTC_RELATIVE,
  DefaultRoutes.TWO_FACTOR_RELATIVE,
]

//===============================================================//
