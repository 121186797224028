import { EnvironmentProviders, Provider } from "@angular/core";
import { ContactConfig, ContactConfigService } from "./contact-config";
import { ContactDetailsConfig, ContactDetailsConfigService } from "./details-config";
import { ContactFormConfig, ContactFormConfigService } from "./form-config";
import { ContactMapConfig, ContactMapConfigService } from "./map-config";
import { ContactMapFormConfig, ContactMapFormConfigService } from "./map-form-config";
import { ContactPanelsConfig, ContactPanelsConfigService } from "./panels-config";


export class ContactSetup {

    //----------------------------------------------//

    static getDetailsProviders(config: ContactDetailsConfig = ContactDetailsConfig.Create()): (Provider)[] {

        return [
            {
                provide: ContactDetailsConfigService,
                useValue: config,
            },
        ]

    } //getDetailsProviders

    //----------------------------------------------//

    static getFormProviders(config: ContactFormConfig = ContactFormConfig.Create()): (Provider)[] {

        return [
            {
                provide: ContactFormConfigService,
                useValue: config,
            },
        ]

    } //getFormProviders

    //----------------------------------------------//

    static getMapProviders(config: ContactMapConfig): (Provider)[] {

        return [
            {
                provide: ContactMapConfigService,
                useValue: config,
            },
        ]

    } //getMapProviders

    //----------------------------------------------//

    static createMapProviders(apiKey: string, mapId: string, detailsConfig: ContactDetailsConfig): (Provider)[] {

        return [
            {
                provide: ContactMapConfigService,
                useValue: ContactMapConfig.Create(apiKey, mapId, detailsConfig)
            },
        ]

    } 

    //----------------------------------------------//

    static getMapFormProviders(config: ContactMapFormConfig): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: ContactMapFormConfigService,
                useValue: config,
            },
        ]

    } //getFormProviders

    //----------------------------------------------//

    static getPanelsProviders(config: ContactPanelsConfig): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: ContactPanelsConfigService,
                useValue: config,
            },
        ]

    } //getPanelsProviders

    //----------------------------------------------//

    static getAllProviders(config: ContactConfig): (Provider)[] {


        const mapFormConfig: ContactMapFormConfig = ContactMapFormConfig.Create(config.mapConfig, config.formConfig)

        return [
            {
                provide: ContactConfigService,
                useValue: config,
            },
            ContactSetup.getDetailsProviders(config.detailsConfig),
            ContactSetup.getFormProviders(config.formConfig),
            ContactSetup.getPanelsProviders(config.panelsConfig),
            ContactSetup.getMapProviders(config.mapConfig),
            ContactSetup.getMapFormProviders(mapFormConfig),
        ]

    } //getAllProviders

    //----------------------------------------------//




} //Cls