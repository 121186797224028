import { EnvironmentProviders, Provider, Type } from "@angular/core";
import { CannotActivateHandler, DefaultCannotActivateHandler } from "./cannot-activate-handler/cannot-activate-handler.service";

export class AuthGuardSetup {

    static getProviders(cannotActivateHandler: Type<CannotActivateHandler> = DefaultCannotActivateHandler): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: CannotActivateHandler,
                useClass: cannotActivateHandler,
            }
        ]

    } //configure


} //Cls