import { EnvironmentProviders, Provider } from "@angular/core"
import { NotFoundConfig, NotFoundConfigService } from "./config"

export class NotFoundSetup {

    static getProviders = (config: NotFoundConfig = new NotFoundConfig()): (Provider | EnvironmentProviders)[] =>
        [
            {
                provide: NotFoundConfigService,
                useValue: config,
            }
        ]

} //Cls