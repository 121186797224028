import { MyBS } from './my-bs';

/**
 * Class for creating observables that is readonly for consumers of it.
 */
export class BsArrayHelper<T>{


    private _source: MyBS<T[]> //= new BehaviorSubject<T[]>(null)

    //----------------------------------------------------------------//

    /**
     * 
     * @param source 
     */
    constructor(source: MyBS<T[]>) {
        this._source = source
    }//ctor

    //----------------------------------------------------------------//

    /**Pass in a new value */
    next(nextVal: T[]): void {
        this._source.next(nextVal)
    }//next

    //----------------------------------------------------------------//

    /**Pass in a new value */
    push(t: T): void {
        const arr = this._source.getValue() ?? []
        this._source.next(arr.concat([t]))
    }

    //----------------------------------------------------------------//

    /**
     * Replace the value at index, idx
     * @param t  New Item
     * @param idx LOcation of item to be replaced 
     */
    replace(t: T, idx: number): void {

        if (idx === null || idx === undefined || idx < 0)
            return

        const clone = this.cloneSourceArray()
        if (idx >= clone.length) //Nothing to replace
            return

        clone[idx] = t
        this._source.next(clone)
        console.log('edit',clone)     

    }//replace

    //----------------------------------------------------------------//

    /**
     * Remove the value at index, idx
     * @param idx Location of item to be replaced 
     */
    remove(idx: number): void {        


        if (idx === null || idx === undefined || idx < 0)
            return
           

        const clone = this.cloneSourceArray()
        if (idx >= clone.length) //Nothing to replace
            return

        clone.splice(idx, 1)
        this._source.next(clone)

    }//remove

    //----------------------------------------------------------------//

    private cloneSourceArray() {

        const original = this._source.getValue() ?? []

        if (!original?.length)
            return []
        else
            return [...original]

    }//cloneSourceArray

    //----------------------------------------------------------------//

}//Cls