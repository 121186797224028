import { Injectable } from '@angular/core';
import { MyBS, MyBSArray } from '@inigo/gen-helpers/classes';
import { APagePkgStoreService } from '@inigo/my-store';
import { PagedRequest } from '@inigo/pagination';
import { Observable, Subscription } from 'rxjs';
import { MeasurementDef } from '../../../dtos/measurementDef';
import { Unit } from '../../../dtos/unit';
import { UnitPagePackage } from '../../../dtos/unit-page-package';
import { UnitHttpService } from '../../http/units/unit.service';

@Injectable({
  providedIn: 'root',
})
export class UnitStoreService extends APagePkgStoreService<Unit, Unit, UnitPagePackage> {
  
  protected _presetsBs: MyBSArray<Unit> = new MyBSArray<Unit>()
  _presets$: Observable<Unit[]> = this._presetsBs.obs$
  protected _presetsSub?: Subscription

  private _measurementDefsBs: MyBS<MeasurementDef[]> = new MyBS<MeasurementDef[]>()
  measurementDefs$: Observable<MeasurementDef[]> = this._measurementDefsBs.obs$;

  //-------------------------------------------------------//

  constructor(_httpService: UnitHttpService) {
    super(_httpService);

    this.setItemTypeName('Unit')
    this.setItemNameLamda((p) => p.name ?? `Unit ${p.id}`);

  } //ctor

  //-------------------------------------------------------//

  protected override handlePagePackageResponse(pkg: UnitPagePackage): void {

    super.handlePagePackageResponse(pkg)
    this._measurementDefsBs.next(pkg.measurements)

  } //handlePagePackageResponse

  //-------------------------------------------------------//

  refreshPresets(): void {

    // this._isLoadingBs.next(true)

    this._presetsSub?.unsubscribe()
    this._presetsSub = (this.httpService as UnitHttpService)
      .getAllPresets()
      // .pipe(finalize(() => this._isLoadingBs.next(false)))
      .subscribe({
        next: (data: Unit[]) => {
          this._presetsBs.next(data)
        },
        error: (error) => this._errorSb.next(error),
      }) //subscribe

  } //filter

  //-------------------------------------------------------//

  override refreshDataIfNecessary(): void {

    if (!this.totalItems())
      this.getPagePackage(PagedRequest.Empty())

  }
  //-------------------------------------------------------//

} //Cls


