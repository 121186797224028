import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { retry, timer } from 'rxjs';

//--------------------------------------------------------//

export const getServiceUnavailableInterceptorFn = (retryCount = 3, retryWaitMilliSeconds = 3000): HttpInterceptorFn =>
  (req, next) => {
    return next(req).pipe(
      retry({
        count: retryCount,
        delay: (error) => shouldRetry(error, retryWaitMilliSeconds)
      })
    )
  }

//--------------------------------------------------------//

function shouldRetry(error: HttpErrorResponse, retryWaitMilliSeconds: number) {

  if (error.status === 503) 
    return timer(retryWaitMilliSeconds)

  throw error

}

//--------------------------------------------------------//