import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { StatusCodes } from '@inigo/gen-helpers/classes';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// @Injectable()
export abstract class BaseUnauthorizedHandlerInterceptor implements HttpInterceptor {

  //----------------------------------------//

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          console.log('unauthorized.....', req.url, req.urlWithParams, errorResponse);

          if (this.notAuthError(errorResponse.status))
            return throwError(() => errorResponse)

          return this.handleUnauthorized(req, errorResponse) ?? new Observable<HttpEvent<any>>()

        })
      )

  } //intercept

  //----------------------------------------//

  abstract handleUnauthorized(req: HttpRequest<any>, errorResponse: HttpErrorResponse): Observable<HttpEvent<any>> | void

  //----------------------------------------//

  private notAuthError = (status: number): boolean =>
    !this.isAuthError(status)

  //----------------------------------------//

  private isAuthError = (status: number): boolean =>
    status === StatusCodes.UNAUTHORIZED || status === StatusCodes.FORBIDDEN;

  //----------------------------------------//

} //Cls
