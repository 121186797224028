import { TemplateRef } from '@angular/core';
import { ToastConstants } from './toast-constants';
import { ToastConfig } from '@inigo/toast/setup';

//===================================================================//

export type ToastType = 'warn' | 'info' | 'success' | 'error';

//===================================================================//

export class ToastData {

  constructor(
    public type: ToastType,
    public text?: string,
    public template?: TemplateRef<unknown>,
    public templateContext?: unknown
  ) {}

  //---------------------------------------//

  getIconName(): string {
    
    switch (this.type) {
      case 'success':
        return ToastConstants.IconNameSuccess;

      case 'info':
        return ToastConstants.IconNameInfo;

      case 'warn':
        return ToastConstants.IconNameWarn;

      case 'error':
        return ToastConstants.IconNameError;

      default:
        return ToastConstants.IconNameDefault;
    } //switch

  } //getIconName

  //---------------------------------------//

  getBackgroundColor(config: ToastConfig): string {

    switch (this.type) {
      case 'success':
        return config.colorBgSuccess;

      case 'info':
        return config.colorBgInfo;

      case 'warn':
        return config.colorBgWarn;

      case 'error':
        return config.colorBgError;

      default:
        return config.colorBgDefault;
    } //switch

  } //getBackgroundColor

  //---------------------------------------//
} //Cls
