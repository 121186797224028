import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { MntcAreaRoutes, MntcAreaRoutesFullPath } from "../../admin/admin-routes-defs";
import { REDIRECT_URL_KEY } from "@inigo/data/misc";
import { PublicAreaRoutesFullPath } from "../../public/public-routes-defs";

@Injectable({ providedIn: 'root' })
export class RouteHelper {

    private _router = inject(Router)

    //-------------------------------------------------//

    isMntcUrl = (url: string) =>
        this.getFirstSegment(url) === MntcAreaRoutes.BASE

    //-------------------------------------------------//

    isInMntcSection = () =>
        this.getFirstSegment(this._router.url) === MntcAreaRoutes.BASE

    //-------------------------------------------------//

    getFirstSegment = (url: string) =>
        !url?.length ? '' : this.getSegments(url)[0]

    //-------------------------------------------------//

    getLastSegment(url: string): string {

        if (!url?.length)
            return ''

        const segments = this.getSegments(url)
        return segments[segments.length - 1]

    }

    //-------------------------------------------------//

    private getSegments = (url: string) =>
        this._router.parseUrl(url).root.children['primary'].segments
            .map(it => it.path)

    //-------------------------------------------------//

    getReturnToAcountsQueryParams() {

        const url = this._router.url

        const queryParams: any = {}
        if (!this.isAlreadyOnAllowAnonymousPage(url))
            queryParams[REDIRECT_URL_KEY] = url

        return queryParams
    }

    //-------------------------------------------------//

    isAlreadyOnAllowAnonymousPage = (url: string) => {

        const routesToIgnore: string[] = [
            ...MntcAreaRoutesFullPath.Accounts.AllowAnonymousRoutes,
            ...PublicAreaRoutesFullPath.Accounts.AllowAnonymousRoutes
        ]
        if (!routesToIgnore?.length)
            return false

        for (const route of routesToIgnore) {

            if (url.includes(route))
                return true
        }

        return false

    }

    //-------------------------------------------------//

}//Cl