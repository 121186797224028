import { Injectable } from '@angular/core';
import { Color } from '@inigo/data/dtos';
import { Identifier } from '@inigo/data/misc';
import { MyBS } from '@inigo/gen-helpers/classes';
import { ArrayHelpers } from '@inigo/helpers/collections';
import { APagePkgStoreService, DefaultTemplateStoreService, SuccessInfo } from '@inigo/my-store';
import { FilterListItem, PagedRequest } from '@inigo/pagination';
import { Observable, ReplaySubject, map, startWith } from 'rxjs';
import { AppActions } from '../../../../app-actions';
import { Brand } from '../../../dtos/brand';
import { BulkUploadDto } from '../../../dtos/bulk-upload';
import { VariantColor } from '../../../dtos/color';
import { Product } from '../../../dtos/product';
import { Style } from '../../../dtos/style';
import { Unit } from '../../../dtos/unit';
import { ProductVariant } from '../../../dtos/variant';
import { VariantByProductPagePackage } from '../../../dtos/vars-by-prod-page-package';
import { FilterListHelpers } from '../../../utility/filter-list-heplers';
import { AdminVariantHttpService } from '../../http/vars/vars-admin.service';

@Injectable({
  providedIn: 'root',
})
export class VariantStoreAdminService extends APagePkgStoreService<ProductVariant, ProductVariant, VariantByProductPagePackage> {

  private _adminVariantService: AdminVariantHttpService;

  //-------------------------------------------------------//

  private _underlyingProductBs: MyBS<Product> = new MyBS<Product>()
  underlyingProduct$: Observable<Product> = this._underlyingProductBs.obs$;

  brands$: Observable<Brand[]> = this.underlyingProduct$.pipe(
    map(pkg => pkg?.brands ?? []),
    startWith([])
  )

  colors$: Observable<VariantColor[]> = this.underlyingProduct$.pipe(
    map(pkg => pkg?.colors ?? []),
    startWith([])
  )

  pckSzs$: Observable<number[]> = this.package$.pipe(
    map(pkg => pkg?.packSizes ?? []),
    startWith([])
  )

  styles$: Observable<Style[]> = this.underlyingProduct$.pipe(
    map(pkg => pkg?.styles ?? []),
    startWith([])
  )

  units$: Observable<Unit[]> = this.package$.pipe(
    map(pkg => pkg?.units ?? []),
    startWith([])
  )

  brandsFilterList$ = this.brands$.pipe(
    map(brnds => this.toBrandFilters(brnds))
  )

  colorsFilterList$ = this.colors$.pipe(
    map(stls => this.toColorFilterList(stls))
  )

  colorCodesFilterList$ = this.colors$.pipe(
    map(stls => this.toColorCodeFilterList(stls))
  )

  pckSzsFilterList$ = this.pckSzs$.pipe(
    map(pkSzs => this.toPackSizeFilters(pkSzs))
  )

  stylesFilterList$ = this.styles$.pipe(
    map(stls => this.toStyleFilterList(stls))
  )

  unitsFilterList$ = this.units$.pipe(
    map(unts => this.toUnitFilters(unts))
  )
  private _templateStore: DefaultTemplateStoreService
  
  protected _templateBs = new ReplaySubject<Blob>(1)
  /**Associated File (example: Excel) */
  template$: Observable<Blob> = this._templateBs.asObservable()

  //-------------------------------------------------------//

  public get underlyingProduct(): Product | undefined {
    return this._underlyingProductBs.getValue()
  }

  //-------------------------------------------------------//

  constructor(httpService: AdminVariantHttpService) {
    super(httpService)
    
    this._templateStore = DefaultTemplateStoreService.Create(
      httpService.url,
      this._templateBs,
      this._isLoadingBs,
      this._errorSb,
      this._successSb
    )


    this.setItemTypeName('Variant')
    this.setItemNameLamda(p => p.name ?? `${p.id}`)
    this._adminVariantService = httpService

  } //ctor

  //-------------------------------------------------------//

  protected override handlePagePackageResponse(pkg: VariantByProductPagePackage): void {

    super.handlePagePackageResponse(pkg);

    this._underlyingProductBs.next(pkg.product)

  } //handlePagePackageResponse

  //-------------------------------------------------------//

  addBulk(dto: BulkUploadDto, filename: string = 'upload_report.txt') {

    console.log('addBulk', dto, filename);

    this._adminVariantService.addBulk(dto).subscribe({
      next: (response) => {
        this._blobHandler.download(response, filename);
        this._successSb.next(
          SuccessInfo.Other(
            `Upload complete. Extra info wil be in downloaded file, "${filename}"`
          )
        );
      },
      error: (error) => this._errorSb.next(error),
    })

  } //addBulk

  //-------------------------------------------------------//

  addBulkMulti(dto: BulkUploadDto, filename: string = 'upload_report.txt') {

    console.log('addBulk', dto, filename);

    this._adminVariantService.addBulkMulti(dto).subscribe({
      next: (response) => {
        this._blobHandler.download(response, filename);
        this._successSb.next(
          SuccessInfo.Other(
            `Upload complete. Extra info wil be in downloaded file, "${filename}"`
          )
        );
      },
      error: (error) => this._errorSb.next(error),
    })

  } //addBulk

  //-------------------------------------------------------//

  refreshPagePackage = (prodId: Identifier) => this.getPagePackage(PagedRequest.Empty(), prodId)

  //-------------------------------------------------------//

  /**
   * Add this Brand to the arrays and emit them to any observers
   * @param brnd The new Brand
   */
  addBrand(brnd?: Brand): void {

    if (!brnd)
      return

    const underlyingProduct = this.underlyingProduct
    const currentUnderlyingBrands = underlyingProduct?.brands ?? []

    const newUnderlyingBrands = ArrayHelpers.insertInOrder<Brand>(
      currentUnderlyingBrands,
      brnd,
      (c1, c2) => (c1.name ?? '').localeCompare(c2.name ?? '') > 0
    )

    if (underlyingProduct) {
      underlyingProduct.brands = newUnderlyingBrands
      this._underlyingProductBs.next(underlyingProduct)
    }

  }//addBrand

  //-------------------------------------------------------//

  /**
   * Add this Color to the arrays and emit them to any observers
   * @param clr The new Color
   */
  addColor(clr?: VariantColor): void {


    if (!clr)
      return

    const underlyingProduct = this.underlyingProduct
    const currentColors = underlyingProduct?.colors ?? []

    const newColors = ArrayHelpers.insertInOrder<VariantColor>(
      currentColors,
      clr,
      (c1, c2) => (c1.name ?? '').localeCompare(c2.name ?? '') > 0
    )

    if (underlyingProduct) {
      underlyingProduct.colors = newColors
      this._underlyingProductBs.next(underlyingProduct)
    }

  }//addColor

  //-------------------------------------------------------//

  /**
   * Add this Style to the arrays and emit them to any observers
   * @param stl The new Style
   */
  addStyle(stl?: Style) {

    if (!stl)
      return

    const underlyingProduct = this.underlyingProduct
    const currentStyles = underlyingProduct?.styles ?? []

    const newStyles = ArrayHelpers.insertInOrder<Style>(
      currentStyles,
      stl,
      (c1, c2) => (c1.name ?? '').localeCompare(c2.name ?? '') > 0
    )

    if (underlyingProduct) {
      underlyingProduct.styles = newStyles
      this._underlyingProductBs.next(underlyingProduct)
    }

  }//addStyle

  //-------------------------------------------------------//

  getTemplate = (fileName: string = 'template.tmp', id?: Identifier): Observable<Blob> =>
    this._templateStore.getTemplate(fileName, id)

  //-------------------------------------------------------//

  getMultiTemplate = (fileName: string = 'template.tmp', id?: Identifier): Observable<Blob> =>
    this._templateStore.getTemplate(fileName, id, AppActions.Variants.GET_MULTI_TEMPLATE)

  //-------------------------------------------------------//


  private toBrandFilters(brnds: Brand[]): FilterListItem[] {

    const brandsFilterList: FilterListItem[] = []
    brnds.forEach((stl) => {
      FilterListHelpers.pushIfUnique(brandsFilterList, stl)
    })

    return brandsFilterList

  }//brandsFilterList

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private toColorFilterList(clrs: Color[]): FilterListItem[] {

    const colorsFilterList: FilterListItem[] = []
    clrs?.forEach((clr) => {
      FilterListHelpers.pushIfUnique(colorsFilterList, clr)
    })

    return colorsFilterList

  }//toColorFilterList

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private toColorCodeFilterList(clrs: Color[]): FilterListItem[] {

    const colorCodesFilterList: FilterListItem[] = []
    clrs?.forEach((clr) => {
      FilterListHelpers.pushIfUnique(
        colorCodesFilterList,
        clr,
        (c) => c.id,
        (c) => `${c.name} (${c.code})`
      )
    })
    return colorCodesFilterList

  }//toColorCodeFilterList

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private toPackSizeFilters(sizes: number[]): FilterListItem[] {

    const pckSzsFilterList: FilterListItem[] = []

    const sizesSet = new Set<number>()

    sizes.forEach((sz) => sizesSet.add(sz))
    sizesSet.forEach(sz => pckSzsFilterList.push(new FilterListItem(sz, sz)))

    return pckSzsFilterList

  }//toPackSizeFilters

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private toStyleFilterList(stls: Style[]): FilterListItem[] {

    const stylesFilterList: FilterListItem[] = []
    stls.forEach((stl) => {
      FilterListHelpers.pushIfUnique(stylesFilterList, stl)
    })

    return stylesFilterList

  }//setStyleFilters

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private toUnitFilters(units: Unit[]): FilterListItem[] {

    const unitsFilterList: FilterListItem[] = []
    units?.forEach((unt) =>
      unitsFilterList.push(new FilterListItem(unt.id, unt.name))
    )

    return unitsFilterList

  }//toUnitFilters

  //-------------------------------------------------------//

  override refreshDataIfNecessary(): void {

    if (!this.totalItems()) 
      this.getPagePackage(PagedRequest.Empty())

  }

  //-------------------------------------------------------//

} //Cls

