

import { isDevMode } from '@angular/core';
import { AccountFeaturesConfig } from '@inigo/acc-features/config';
import { RolesAuthConfig } from '@inigo/authentication/config';
import { BgAngledSplitConfig } from '@inigo/background/angled-split';
import { ContactConfig, ContactDetailsConfig, ContactFormConfig, ContactMapConfig } from '@inigo/contact/config';
import { CoreConfig } from '@inigo/core';
import { HangfireConfig } from '@inigo/hangfire-helpers/config';
import { HttpServicesConfig } from '@inigo/http-services/config';
import { NotFoundConfig } from '@inigo/not-found/config';
import { PopUpBouncerConfig, PopUpConfig, PopUpDotSpinnerConfig, PopUpTrouncerConfig } from '@inigo/pop-up/config';
import { ThemeConfig, ThemeOption } from '@inigo/theme-and-mode/config';
import { ToastConfig } from '@inigo/toast/setup';
import { Secrets } from './secrets';

let today = new Date();
let thisYear = today.getFullYear();
let xmasTime = new Date(thisYear, 11, 1);
//  xmasTime = new Date(`${thisYear}-October-01`)
export const IS_XMAS = today >= xmasTime;
// export const SSR_URL = 'https://conecto.ie'
// export const SSR_URL = 'https://localhost:495/'
export const SSR_URL = 'http://localhost:37999'

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

const colorPrimary = '#f1760f';
const colorSecondary = '#5f5554';

export const COLOR_PRIMARY = 'var(--colorPrimary)';
export const COLOR_PRIMARY_LIGHT = 'var(--colorPrimaryLight)';
export const COLOR_PRIMARY_LIGHTER = 'var(--colorPrimaryLighter)';
export const COLOR_PRIMARY_DARK = 'var(--colorPrimaryDark)';
export const COLOR_PRIMARY_DARKER = 'var(--colorPrimaryDarker)';
export const COLOR_SECONDARY = 'var(--colorSecondary)';
export const COLOR_SECONDARY_LIGHT = 'var(--colorSecondaryLight)';
export const COLOR_SECONDARY_LIGHTER = 'var(--colorSecondaryLighter)';
export const COLOR_SECONDARY_DARK = 'var(--colorSecondaryDark)';
export const COLOR_SECONDARY_DARKER = 'var(--colorSecondaryDarker)';
export const COLOR_MAIN_BG = 'var(--colorBg)';

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

const COLOR_BNC_1 = COLOR_PRIMARY;
const COLOR_BNC_2 = COLOR_SECONDARY; //COLOR_PRIMARY_LIGHT

const COLOR_TRNC_1 = COLOR_PRIMARY;
const COLOR_TRNC_2 = COLOR_PRIMARY_LIGHT;
const COLOR_TRNC_3 = COLOR_SECONDARY;

const DOT_COLOR1: string = COLOR_PRIMARY;
const DOT_COLOR2: string = COLOR_SECONDARY;
const DOT_COLOR3: string = COLOR_PRIMARY;
const DOT_COLOR4: string = COLOR_SECONDARY;
const DOT_COLOR5: string = COLOR_PRIMARY;
const DOT_COLOR6: string = COLOR_SECONDARY;
const DOT_COLOR7: string = COLOR_PRIMARY;
const DOT_COLOR8: string = COLOR_SECONDARY;

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const HANGFIRE_URL = '/conwebadminhangfire';

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const HF_CONFIG = new HangfireConfig(HANGFIRE_URL)

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const BOUNCER_CONFIG = PopUpBouncerConfig.Create(
  COLOR_BNC_1,
  COLOR_BNC_2
);

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const TROUNCER_CONFIG = PopUpTrouncerConfig.Create(false, true)
  .setColor1(COLOR_TRNC_1)
  .setColor2(COLOR_TRNC_2)
  .setColor3(COLOR_TRNC_3)
  .setImgPath1('/assets/images/trouncer/trouncer1.svg')
  .setImgPath2('/assets/images/trouncer/trouncer2.svg')
  .setImgPath2('/assets/images/trouncer/trouncer3.svg');

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const DOT_SPINNER_CONFIG = PopUpDotSpinnerConfig.Create(
  DOT_COLOR1,
  DOT_COLOR2,
  DOT_COLOR3,
  DOT_COLOR4,
  DOT_COLOR5,
  DOT_COLOR6,
  DOT_COLOR7,
  DOT_COLOR8
);

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const POP_UP_CONFIG = PopUpConfig.Create()
  .setColorSuccessHeader(COLOR_PRIMARY_LIGHT)
  .setBouncerConfig(BOUNCER_CONFIG)
  .setTrouncerConfig(TROUNCER_CONFIG)
  .setDotSpinnerConfig(DOT_SPINNER_CONFIG);

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const TOAST_CONFIG = ToastConfig.Create();

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const AUTH_CONFIG = RolesAuthConfig.Create(true)
  .setBaseRoutes([
    'admin',
  ])
  .setFromAppHeaderValue(Secrets.fromAppHeaderValue)

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const CONTACT_DETAILS_CONFIG = ContactDetailsConfig.Create(
  'Conecto Ltd.'
)
  .setLatitude(53.30041925744362)
  .setLongitude(-6.479475685238004)
  .setPhone('01 902 7737')
  .setPhone2('01 903 5556')
  .setEmail('sales@conecto.ie')
  .setFBook('https://www.facebook.com/Conecto-1170157216456282/')
  .setAddress([ 'Unit L 10', 'Grants Road', 'Greenogue Business Park', 'Rathcoole', 'Dublin 24.']);

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

/** Google Maps API version. */
const AGM_API_KEY = Secrets.agmApiKey
const AGM_MAP_ID = Secrets.agmMapId

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const CONTACT_FORM_CONFIG = ContactFormConfig.Create()

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const CONTACT_MAP_CONFIG = ContactMapConfig.Create(
  AGM_API_KEY,
  AGM_MAP_ID,
  CONTACT_DETAILS_CONFIG
);

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const CONTACT_CONFIG = ContactConfig.Create(
  CONTACT_MAP_CONFIG,
  CONTACT_FORM_CONFIG
);

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

const _themeOptions: ThemeOption[] = [
  {
    isDarkMode: false,
    label: 'Default',
    value: 'default',
    primaryColor: colorPrimary,
    secondaryColor: colorSecondary,
    classIdx: 1,
  },
  {
    isDarkMode: false,
    label: 'Green & Gold',
    value: 'green-gold',
    primaryColor: '#548c2f',
    secondaryColor: '#f9a620',
    classIdx: 2,
  },
  {
    isDarkMode: false,
    label: 'Blood Orange',
    value: 'purple-oj',
    primaryColor: '#720026',
    secondaryColor: '#ff7f51',
    classIdx: 3,
  },
  // {
  //   isDarkMode: false,
  //   label: 'Orange & Grey',
  //   value: 'orange_and-grey',
  //   primaryColor: '#f1760f',
  //   secondaryColor: '#5f5554',
  //   classIdx: 4,
  // },
];

const xmasTheme = {
  isDarkMode: false,
  label: 'XMAS',
  value: 'xmas',
  primaryColor: '#0f8a5f',
  secondaryColor: '#f5624d',
  classIdx: 5,
};

if (IS_XMAS) _themeOptions.push(xmasTheme);

export const THEME_CONFIG = ThemeConfig.Create(_themeOptions);

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

const HTTP_SERVICES_CONFIG: HttpServicesConfig = HttpServicesConfig.Create()
  .setSsrUrl(SSR_URL)


//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const CORE_CONFIG = CoreConfig.Create()
  // .setPopUpConfig(POP_UP_CONFIG)
  // .setHangfireConfig(HF_CONFIG)
  // .setToastConfig(TOAST_CONFIG)
  // .setThemeConfig(THEME_CONFIG)
  // .setLoadingSpinnerType('trouncer')
  .setHttpServicesConfig(HTTP_SERVICES_CONFIG)

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const AF_CONFIG = AccountFeaturesConfig.Create()
  .setEmailConfirmationCardTitle('Conecto')
  .setEmailConfirmationCardMessage('Go to login to start using the application')
  .setColorBgLeft(COLOR_PRIMARY)
  .setColorBgRight(COLOR_SECONDARY)
  // .setAfterLoginRoute(AppRoutes.PublicArea.MY_ACCOUNT)
  .setIncludeCustomerAdmin(true)
  .setIncludeCustomerTeams(false)
  .setColorBgLeft('transparent')
  .setColorBgRight('transparent')
  ;

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const ASB_CONFIG = BgAngledSplitConfig.Create()

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

/**Dept to icon */
export const DeptIconMap = new Map([
  ['Construction', 'building-construction'],
  ['Engineering', 'products'],
  ['PPE', 'ppe'],
  ['Joinery', 'hammer_and_saw'],
  ['Windows', 'window-open'],
  ['', 'products'],
  [null, 'products'],
  [undefined, 'products'],
]);

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const NOT_FOUND_CONFIG = new NotFoundConfig()

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

//ng g m  public --route home --module app.module

export const PROPERTY_NONE = 'None'
export const PLACEHOLDER_DIR = 'placeholder'

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

export const PAYMENT_COMPLETE_ROUTE = `payment-complete`
export const STRIPE_AFTER_PAYMENT_RETURN_URL = isDevMode()
  ? `https://localhost:44356/${PAYMENT_COMPLETE_ROUTE}`
  : `www.conecto.ie/${PAYMENT_COMPLETE_ROUTE}`


export const META_DESCRIPTION = `"
Conecto is one of Ireland’s leading fastener, fixings & consumable distributors. Based in Kilcock Co.
Kildare just off the M4, we supply the engineering and construction industries with their requirements
throughout the country. We specialise in sourcing products and solutions for our customers that can save
time or money, and sometimes both in their production processes.
We carry an extensive range of standard fasteners in 8.8, 10.9 and 12.9 grade zinc plated and self-colour,
also a large stock of stainless. We also supply of hand tools and consumables such as drill bits for steel,
concrete & wood, cutting discs, & tapping tools."`