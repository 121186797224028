import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UnauthorizedAlertService {


  private _alertS = new Subject<number>()
  unathorised$ = this._alertS.asObservable()

  sendAlert = (statusCode: number) => {

    console.log(statusCode);
    this._alertS.next(statusCode)

  }


}